/* External dependencies */
import { filter, switchMap } from 'rxjs/operators';

/* Local dependencies */
import { getClient } from '../../../clients/bts';
import {
  CreateCargoAction,
  CreateCargoActionTypes,
  createCargoFailed,
  createCargoSucceeded,
  Cargo,
  CreateCargoInput,
} from './actions';
import { createCargoMutation } from './mutations';

export default function createCargoEpic(action$) {
  return action$.pipe(
    filter(
      (action: CreateCargoAction) =>
        action.type === CreateCargoActionTypes.CREATE_CARGO_REQUEST,
    ),
    switchMap((action: CreateCargoAction) =>
      createCargo(action)
        .then(createCargoSucceeded)
        .catch((error) => createCargoFailed(error)),
    ),
  );
}

export async function createCargo(action): Promise<Cargo> {
  const graphQLClient = await getClient();
  const { cargo } = action;
  const { consignees } = cargo;

  consignees.map((item) => {
    if (item.transit === 'on') {
      return item.transit === true;
    }
  });

  const input: CreateCargoInput = Object.keys(cargo).reduce((input, key) => {
    if (cargo.hasOwnProperty(key) && typeof cargo[key] !== 'undefined') {
      input[key] = cargo[key];
    }

    return input;
  }, {} as any);

  const { arrivalDate, id, vehiclePlateNumber } = input.vehicle;

  input.vehicle = {
    arrivalDate,
    id,
    vehiclePlateNumber,
  };

  const {
    data: { createCargo },
  } = await graphQLClient.mutate({
    mutation: createCargoMutation,
    variables: {
      input,
    },
  });

  return createCargo as Cargo;
}
