/* Local dependencies */
import { SessionUser } from '../login/redux/actions';
import * as roles from './roles';
import UserRoles = roles.UserRoles;

export function getUserRole(currentUser: SessionUser) {
  if (!currentUser?.cargoRole) {
    if (currentUser?.hasOwnProperty('cognito:preferred_role')) {
      currentUser.cargoRole = roles.extractRoleFromARN(
        currentUser['cognito:preferred_role'],
      );
    }
  }

  return currentUser?.cargoRole;
}

export function isAdmin(currentUser: SessionUser): boolean {
  return getUserRole(currentUser) === UserRoles.BTS_ADMIN;
}

export function isCashier(currentUser: SessionUser): boolean {
  return getUserRole(currentUser) === UserRoles.BTS_CASHIER;
}

export function isOperator(currentUser: SessionUser): boolean {
  return getUserRole(currentUser) === UserRoles.BTS_OPERATOR;
}

export function isOperatorWeighman(currentUser: SessionUser): boolean {
  return getUserRole(currentUser) === UserRoles.BTS_OPERATOR_WEIGHMAN;
}

export function isManagerOperator(currentUser: SessionUser): boolean {
  return getUserRole(currentUser) === UserRoles.BTS_MANAGER_OPERATOR;
}

export function isWeighman(currentUser: SessionUser): boolean {
  return getUserRole(currentUser) === UserRoles.BTS_WEIGHMAN;
}

/*
 * Cleans up the payload before sending to the server because
 * GraphQL does not accept undefined attributes.
 */
export function cleanPayload(payload: any) {
  Object.keys(payload).forEach((key: string) => {
    if (
      ['__typename'].includes(key) ||
      payload[key] === null ||
      payload[key] === ''
    ) {
      payload[key] = undefined;
    }

    if (typeof payload[key] === 'object') {
      payload[key] = cleanPayload(payload[key]);
    }
  });

  return payload;
}

export function removeTypename(obj) {
  Object.entries(obj).forEach(([key, value]) => {
    if (key === '__typename') {
      delete obj[key];
    } else if (typeof value === 'object' && value !== null) {
      removeTypename(value);
    }
  });

  return obj;
}

export function isMobile(): boolean {
  return typeof window !== 'undefined' ? window.innerWidth < 650 : false;
}

export function setLocalStorage(key: string, value: any) {
  isBrowser && localStorage.setItem(key, value);
}

export const isBrowser = typeof window !== 'undefined';

export const DEFAULT_PAGE_SIZE = 20;
