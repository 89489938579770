// External dependencies
import update from 'immutability-helper';

// Local dependencies
import { DEFAULT_PAGE_SIZE } from '../../common/helpers';
import { VehicleProfile } from '../../vehicles/vehicle-types';
import {
  ListVehicleProfilesActions,
  ListVehicleProfilesActionTypes,
  ListVehicleProfilesFilter,
} from './actions';

export interface ListVehicleProfilesState {
  currentPage: number;
  error?: Error;
  filter: ListVehicleProfilesFilter;
  loading: boolean;
  searchString: string;
  size: number;
  selectedVehicleProfile?: VehicleProfile;
  total: number;
  vehicleProfiles: VehicleProfile[];
}

export const listVehicleProfilesInitialState: ListVehicleProfilesState = {
  loading: false,
  searchString: '',
  currentPage: 1,
  vehicleProfiles: [],
  total: 0,
  filter: {
    vehiclePlateNumber: '',
  },
  size: DEFAULT_PAGE_SIZE,
};

export default function listVehicleProfilesReducer(
  state: ListVehicleProfilesState = listVehicleProfilesInitialState,
  action: ListVehicleProfilesActions,
) {
  switch (action.type) {
    case ListVehicleProfilesActionTypes.LIST_VEHICLE_PROFILES_REQUEST: {
      return update(state, {
        loading: { $set: true },
        filter: { $set: action?.filter ? action.filter : state.filter },
        searchString: {
          $set: action?.searchString ? action.searchString : state.searchString,
        },
        currentPage: {
          $set: action?.currentPage ? action.currentPage : state.currentPage,
        },
        size: {
          $set: action?.size ? action.size : state.size,
        },
      });
    }

    case ListVehicleProfilesActionTypes.LIST_VEHICLE_PROFILES_SUCCESS: {
      return update(state, {
        loading: { $set: false },
        total: { $set: action.total },
        vehicleProfiles: { $set: action.vehicleProfiles },
      });
    }

    case ListVehicleProfilesActionTypes.LIST_VEHICLE_PROFILES_ERROR: {
      return update(state, {
        loading: { $set: false },
        error: { $set: action.error },
      });
    }

    case ListVehicleProfilesActionTypes.SELECT_VEHICLE_PROFILE: {
      const selectedVehicleProfile = state.vehicleProfiles?.filter(
        (item) => item.id === action.id,
      );

      return update(state, {
        selectedVehicleProfile: { $set: selectedVehicleProfile[0] },
      });
    }

    case ListVehicleProfilesActionTypes.RESET_SELECT_VEHICLE_PROFILE: {
      return update(state, {
        selectedVehicleProfile: { $set: null },
      });
    }

    default:
      return state;
  }
}
