/* External dependencies */
import { filter } from 'rxjs';
import { switchMap } from 'rxjs/operators';

/* Local dependencies */
import { getClient } from '../../../clients/bts';
import { User } from '../userTypes';
import {
  GetUser,
  getUserFailed,
  getUserSuccessed,
  UserActions,
  UserActionTypes,
  UpdateUser,
  updateUserSuccess,
  updateUserFailure,
} from './actions';
import { updateUserMutation } from './mutations';
import { getUserQuery } from './queries';
import { cleanPayload } from '../../common/helpers';

export function getUserEpic(action$) {
  return action$.pipe(
    filter(
      (action: UserActions) => action.type === UserActionTypes.GET_USER_REQUEST,
    ),
    switchMap((action: GetUser) =>
      getUser(action).then(getUserSuccessed).catch(getUserFailed),
    ),
  );
}

export async function getUser({ id }: GetUser): Promise<User> {
  const graphqlQLClient = await getClient();

  const {
    data: { getUser },
  } = await graphqlQLClient.query({
    query: getUserQuery,
    variables: {
      input: {
        id,
      },
    },
  });

  return getUser as User;
}

export function updateUserEpic(action$) {
  return action$.pipe(
    filter(
      (action: UserActions) =>
        action.type === UserActionTypes.UPDATE_USER_REQUEST,
    ),
    switchMap((action: UpdateUser) =>
      updateUserDetails(action)
        .then(updateUserSuccess)
        .catch((error) => {
          if (error.graphQLErrors && error.graphQLErrors.length) {
            const [{ message }] = error.graphQLErrors;

            return updateUserFailure(new Error(message));
          }

          return updateUserFailure(error);
        }),
    ),
  );
}

export async function updateUserDetails(action): Promise<User> {
  const graphQLClient = await getClient();

  const input = cleanPayload(action.user);

  delete input.organization;
  delete input.phone;

  const {
    data: { updateUser },
  } = await graphQLClient.mutate({
    mutation: updateUserMutation,
    variables: { input },
  });

  return updateUser as User;
}
