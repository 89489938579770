/* External dependencies */
import { debounceTime, filter, switchMap } from 'rxjs/operators';

/* Local dependencies */
import { getClient } from '../../../clients/bts';
import {
  ListConsignees,
  ListConsigneesAction,
  ListConsigneesActionTypes,
  listConsigneesFailed,
  listConsigneesSucceeded,
  ListConsigneesSuccess,
} from './actions';
import { listConsigneesQuery } from './queries';

export default function listConsigneesEpic(action$, state$) {
  return action$.pipe(
    filter(
      (action: ListConsigneesAction) =>
        action.type === ListConsigneesActionTypes.LIST_CONSIGNEES_REQUEST,
    ),
    debounceTime(500),
    switchMap((action: ListConsignees) =>
      listConsignees(action, state$.value.consignees.size).catch((error) =>
        listConsigneesFailed(error),
      ),
    ),
  );
}

export async function listConsignees(
  { currentPage, filter, searchString }: ListConsignees,
  size: number,
): Promise<ListConsigneesSuccess> {
  const graphQLClient = await getClient();

  const {
    data: {
      listConsignees: { consignees, total },
    },
  } = await graphQLClient.query({
    query: listConsigneesQuery,
    variables: {
      filter,
      from: (currentPage - 1) * size,
      query: searchString,
      size,
    },
  });

  return listConsigneesSucceeded(total, consignees);
}
