// Local dependnencies
import { WorldUnion } from '../types';
import { ListCountriesActionTypes, ListCountriesActions } from './actions';

export interface ListCountriesState {
  countries: WorldUnion[];  
  currentPage: number;
  error: Error | null;
  loading: boolean;
  pageSize: number;
  searchString: string;
  total: number;
}

const ListCountriesInitialState: ListCountriesState = {
  countries: [],
  currentPage: 1,
  error: null,
  loading: false,
  pageSize: 10,
  searchString: '',
  total: 0,
};

const listCountriesReducer = (
  state = ListCountriesInitialState,
  action: ListCountriesActions,
): ListCountriesState => {
  switch (action.type) {
    case ListCountriesActionTypes.LIST_COUNTRIES_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
        searchString: action.searchString || '',
        currentPage: action.currentPage || 1,
        pageSize: action.size || state.pageSize,
      };
    case ListCountriesActionTypes.LIST_COUNTRIES_SUCCESS:
      return {
        ...state,
        loading: false,
        total: action.total,
        countries: action.countries,
      };
    case ListCountriesActionTypes.LIST_COUNTRIES_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export default listCountriesReducer;
