/* External dependencies */
import update from 'immutability-helper';

/* Local dependencies */
import {
  CreateVehicleAction,
  CreateVehicleActionTypes,
  CreateVehicleInput,
} from './actions';
import {
  VehicleArrivalPurpose,
  VehicleStatus,
  WeightType,
} from '../vehicles_types';
import { validateVehicle } from './validateVehicle';

export interface VehiclesState {
  actFormNumberError?: Error;
  arrivalPurposeError?: Error;
  borderCheckpointError?: Error;
  driverNameError?: Error;
  driverPhoneError?: Error;
  driverRoleError?: Error;
  error?: Error;
  grossWeightError?: Error;
  loading?: boolean;
  netWeightError?: Error;
  representativeName?: Error;
  representativePhoneError?: Error;
  representativeRoleError?: Error;
  shouldShowConfirmationPopup?: boolean;
  tareWeightError?: Error;
  trailerPlateNumberError?: Error;
  takeSnapshots: boolean;
  vehicle?: CreateVehicleInput;
  vehiclePlateNumberError?: Error;
  weightTypeError?: Error;
}

const initialVehiclesState: VehiclesState = {
  vehicle: {
    arrivalPurpose: VehicleArrivalPurpose.CARGO_IMPORT,
    borderCheckpoint: '',
    driver: {
      name: '',
    },
    grossWeight: 0,
    netWeight: 0,
    status: VehicleStatus.ARRIVED,
    tareWeight: 0,
    vehiclePlateNumber: '',
    weightType: WeightType.GROSS,
  },
  shouldShowConfirmationPopup: false,
  takeSnapshots: false,
};

export default function newVehicle(
  state = initialVehiclesState,
  action: CreateVehicleAction,
) {
  switch (action.type) {
    case CreateVehicleActionTypes.CREATE_VEHICLE_REQUEST:
      return update(state, {
        $unset: ['error'],
        loading: { $set: true },
      });

    case CreateVehicleActionTypes.CREATE_VEHICLE_SUCCESS:
      return update(state, {
        loading: { $set: false },
        vehicle: { $set: action.vehicle },
        shouldShowConfirmationPopup: { $set: true },
      });

    case CreateVehicleActionTypes.CREATE_VEHICLE_ERROR:
      return update(state, {
        loading: { $set: false },
        error: { $set: action.error },
        shouldShowConfirmationPopup: { $set: true },
      });

    case CreateVehicleActionTypes.CREATE_VEHICLE_RESET_ERROR:
      return update(state, { $set: initialVehiclesState });

    case CreateVehicleActionTypes.CREATE_VEHICLE_RESET:
      return update(state, {
        loading: { $set: false },
        vehicle: { $set: initialVehiclesState.vehicle },
        $unset: ['shouldShowConfirmationPopup'],
      });

    case CreateVehicleActionTypes.UPDATE_VEHICLE_FIELDS:
      return validateVehicle(state, action.updates);

    case CreateVehicleActionTypes.SHOW_CONFIRMATION_POPUP:
      return update(state, {
        shouldShowConfirmationPopup: { $set: true },
      });

    case CreateVehicleActionTypes.HIDE_CONFIRMATION_POPUP:
      return update(state, {
        $unset: ['shouldShowConfirmationPopup'],
      });

    case CreateVehicleActionTypes.TAKE_SNAPSHOT:
      return update(state, {
        takeSnapshots: { $set: action.takeSnapshots },
      });

    default:
      return state;
  }
}
