/* External dependencies */
import update from 'immutability-helper';

/* Local dependencies */
import { DEFAULT_PAGE_SIZE } from '../../common/helpers';
import {
  ListConsigneeProfilesAction,
  ListConsigneeProfilesActionTypes,
} from './actions';

export interface ConsigneeProfile {
  id: string;
  name: string;
}

export interface ConsigneeProfileInput {
  id?: string;
  name: string;
}

export interface СonsigneeProfilesState {
  consigneeProfiles: ConsigneeProfile[];
  currentPage: number;
  searchString: string | undefined;
  error?: Error;
  loading?: boolean;
  size?: number;
}

const initialCargoState: СonsigneeProfilesState = {
  consigneeProfiles: [],
  currentPage: 1,
  searchString: '',
  size: DEFAULT_PAGE_SIZE,
};

export default function consigneeProfiles(
  state = initialCargoState,
  action: ListConsigneeProfilesAction,
) {
  switch (action.type) {
    case ListConsigneeProfilesActionTypes.LIST_CONSIGNEE_PROFILES_REQUEST:
      const { searchString } = action;
      return update(state, {
        $unset: ['error'],
        loading: { $set: true },
        searchString: { $set: searchString },
      });

    case ListConsigneeProfilesActionTypes.LIST_CONSIGNEE_PROFILES_SUCCESS:
      return update(state, {
        loading: { $set: false },
        consigneeProfiles: { $set: action.consigneeProfiles },
      });

    case ListConsigneeProfilesActionTypes.LIST_CONSIGNEE_PROFILES_ERROR:
      return update(state, {
        loading: { $set: false },
        error: { $set: action.error },
      });

    default:
      return state;
  }
}
