/* External dependencies */
import * as AWSCognito from 'amazon-cognito-identity-js';
import AWSAppSyncClient from 'aws-appsync';
import { NormalizedCacheObject } from 'apollo-cache-inmemory';
import { navigate } from 'gatsby-plugin-react-intl';
import update from 'immutability-helper';

/* Local dependencies */
import { LoginAction, LoginActionTypes, SessionUser } from './actions';
import { getSessionUser, Session } from '../../../clients/cognito';
import { setupClient } from '../../../clients/bts';

export type LoginState = {
  // `client` is used by the ApolloWrapper to pass down to the `ApolloProvider`.
  client?: AWSAppSyncClient<NormalizedCacheObject>;
  currentUser?: any;
  error?: Error;
  initializing?: boolean;
  loading?: boolean;
  newPassword?: string;
  newPasswordRequired?: boolean;
  password?: string;
  sessionKey?: string;
  userAttributes?: Session;
  username?: string;
  verificationCode?: number;
};

const initialLoginState: LoginState = {
  initializing: true,
};

export default function loginReducer(
  state = initialLoginState,
  action: LoginAction,
) {
  switch (action.type) {
    case LoginActionTypes.LOGOUT_REQUEST:
      return update(state, {
        $unset: ['error'],
        loading: { $set: true },
      });

    case LoginActionTypes.LOGOUT_SUCCESS:
      window.location.reload();

      return update(state, {
        loading: { $set: false },
        $unset: ['client'],
      });

    case LoginActionTypes.LOGOUT_ERROR:
      const { error } = action;

      return update(state, {
        error: { $set: error },
        loading: { $set: false },
      });

    case LoginActionTypes.INIT_CLIENT_FAILED:
      return update(state, {
        initializing: { $set: false },
      });

    case LoginActionTypes.INIT_CLIENT_SUCCEEDED:
      const { session } = action;

      const idToken = session.getIdToken().getJwtToken();
      const idTokenObj = new AWSCognito.CognitoIdToken({ IdToken: idToken });
      const currentUser = getSessionUser(idTokenObj) as SessionUser;
      const client = setupClient(session);

      return update(state, {
        client: { $set: client },
        currentUser: { $set: currentUser },
        initializing: { $set: false },
        loading: { $set: false },
      });

    case LoginActionTypes.FORGOT_PASSWORD:
      navigate('/login/forgot-password');

      return update(state, {
        $unset: ['error'],
        loading: { $set: false },
        username: { $set: action.username },
      });

    case LoginActionTypes.LOGIN_REQUEST:
      const { password, username } = action;

      return update(state, {
        $unset: ['error'],
        loading: { $set: true },
        password: { $set: password },
        username: { $set: username },
      });

    case LoginActionTypes.LOGIN_ERROR:
      return update(state, {
        error: { $set: action.error },
        loading: { $set: false },
      });

    case LoginActionTypes.LOGIN_NEW_PASSWORD_REQUIRED:
      const { sessionKey, userAttributes } = action;

      return update(state, {
        $unset: ['error'],
        loading: { $set: false },
        newPasswordRequired: { $set: true },
        sessionKey: { $set: sessionKey },
        userAttributes: { $set: userAttributes },
      });

    case LoginActionTypes.REMEMBERED_PASSWORD:
      navigate('/login');

      return update(state, {
        $unset: ['error'],
        loading: { $set: false },
        username: { $set: action.username },
      });

    case LoginActionTypes.REQUEST_VERIFICATION_CODE:
      navigate('/login/verify-code');

      return update(state, {
        $unset: ['error'],
        loading: { $set: true },
        username: { $set: action.username },
      });

    case LoginActionTypes.INPUT_VERIFICATION_CODE:
      return update(state, {
        loading: { $set: false },
      });

    case LoginActionTypes.VERIFY_CODE:
      navigate('/login/change-password');

      return update(state, {
        verificationCode: { $set: action.verificationCode },
      });

    case LoginActionTypes.CONFIRM_PASSWORD:
      return update(state, {
        loading: { $set: true },
      });

    case LoginActionTypes.SET_NEW_PASSWORD:
      return update(state, {
        $unset: ['error'],
        loading: { $set: true },
      });

    default:
      return state;
  }
}
