import { Consignee } from '../../../create-cargo/redux/actions';

export enum GetConsigneeContractActionTypes {
  GET_CONSIGNEE_CONTRACT_REQUEST = 'GET_CONSIGNEE_CONTRACT_REQUEST',
  GET_CONSIGNEE_CONTRACT_SUCCESS = 'GET_CONSIGNEE_CONTRACT_SUCCESS',
  GET_CONSIGNEE_CONTRACT_ERROR = 'GET_CONSIGNEE_CONTRACT_ERROR',
  MAKE_PAYMENT_REQUEST = 'MAKE_PAYMENT_REQUEST',
  MAKE_PAYMENT_SUCCESS = 'MAKE_PAYMENT_SUCCESS',
  MAKE_PAYMENT_ERROR = 'MAKE_PAYMENT_ERROR',
}

export enum PriceUnit {
  SOM = 'SOM',
  SOM_DAY = 'SOM_DAY',
  SOM_FIRST_OPERATION = 'SOM_FIRST_OPERATION',
  SOM_HOUR = 'SOM_HOUR',
  SOM_KG = 'SOM_KG',
  SOM_KG_DAY = 'SOM_KG_DAY',
  SOM_MONTH = 'SOM_MONTH',
  SOM_NEXT_OPERATION = 'SOM_NEXT_OPERATION',
  SOM_PROCEDURE = 'SOM_PROCEDURE',
  SOM_SET = 'SOM_SET',
  SOM_THREE_DAYS = 'SOM_THREE_DAYS',
  SOM_UNIT = 'SOM_UNIT',
  SOM_WEEK = 'SOM_WEEK',
  SOM_YEAR = 'SOM_YEAR',
}

export declare enum ContractType {
  CUSTOMS_DECLARATION = 'CUSTOMS_DECLARATION',
  CUSTOMS_WAREHOUSE = 'CUSTOMS_WAREHOUSE',
  TEMPORARY_STORAGE = 'TEMPORARY_STORAGE',
}

export interface ContractField {
  id: string;
  name: string;
  order?: number;
  // `sum` is used only on the client side.
  sum?: number;
  unit?: PriceUnit;
  unitCount?: number;
  value: string;
}

export interface Contract {
  id: string;
  consignee?: Consignee;
  contractEndReason?: String;
  departureCountry?: String;
  endDate?: number;
  fields: ContractField[];
  parentContractId?: string;
  startDate: number;
  title?: String;
  type: ContractType;
}

export enum PaymentDocumentType {
  APPLICATION = 'APPLICATION',
  DECLARATION = 'DECLARATION',
}

export interface Payment extends MakePaymentInput {
  id?: string;
}

export interface GetConsigneeContractRequest {
  type: GetConsigneeContractActionTypes.GET_CONSIGNEE_CONTRACT_REQUEST;
  consignee: Consignee;
}

export interface GetConsigneeContractSuccess {
  type: GetConsigneeContractActionTypes.GET_CONSIGNEE_CONTRACT_SUCCESS;
  contract: Contract;
}

export interface GetConsigneeContractFailure {
  type: GetConsigneeContractActionTypes.GET_CONSIGNEE_CONTRACT_ERROR;
  error: Error;
}

export interface MakePaymentInput {
  arrivalDate: number;
  cargoWeight: number;
  consignee: Consignee;
  contract: Contract;
  documentId: string;
  documentType: PaymentDocumentType;
  fields: ContractField[];
  sum: number;
}

export interface MakePaymentRequest {
  type: GetConsigneeContractActionTypes.MAKE_PAYMENT_REQUEST;
  payment: MakePaymentInput;
}

export interface MakePaymentSuccess {
  type: GetConsigneeContractActionTypes.MAKE_PAYMENT_SUCCESS;
  payment: Payment;
}

export interface MakePaymentError {
  type: GetConsigneeContractActionTypes.MAKE_PAYMENT_ERROR;
  error: Error;
}

export type ConsigneeContractAction =
  | GetConsigneeContractRequest
  | GetConsigneeContractSuccess
  | GetConsigneeContractFailure
  | MakePaymentRequest
  | MakePaymentSuccess
  | MakePaymentError;

export function getConsigneeContract(consignee: Consignee): GetConsigneeContractRequest {
  return {
    type: GetConsigneeContractActionTypes.GET_CONSIGNEE_CONTRACT_REQUEST,
    consignee,
  };
}

export function getConsigneeContractSucceeded(contract: Contract): GetConsigneeContractSuccess {
  return {
    type: GetConsigneeContractActionTypes.GET_CONSIGNEE_CONTRACT_SUCCESS,
    contract,
  };
}

export function getConsigneeContractFailed(error: Error): GetConsigneeContractFailure {
  return {
    type: GetConsigneeContractActionTypes.GET_CONSIGNEE_CONTRACT_ERROR,
    error,
  };
}

export function makePaymentRequest(
  payment: MakePaymentInput,
): MakePaymentRequest {
  return {
    type: GetConsigneeContractActionTypes.MAKE_PAYMENT_REQUEST,
    payment,
  };
}

export function makePaymentSucceeded(payment: Payment): MakePaymentSuccess {
  return {
    type: GetConsigneeContractActionTypes.MAKE_PAYMENT_SUCCESS,
    payment,
  };
}

export function makePaymentFailed(error: Error): MakePaymentError {
  return {
    type: GetConsigneeContractActionTypes.MAKE_PAYMENT_ERROR,
    error,
  };
}
