/* External dependencies */
import gql from 'graphql-tag';

export const listVehiclesQuery = gql`
  query listVehicles(
    $from: Int
    $filter: ListVehiclesFilter
    $query: String
    $size: Int
    $sort: Sort
  ) {
    listVehicles(
      from: $from
      filter: $filter
      query: $query
      size: $size
      sort: $sort
    ) {
      total
      vehicles {
        arrivalDate
        arrivalPurpose
        grossWeight
        id
        vehiclePlateNumber
        trailerPlateNumber
        vehicleProfile {
          id
          tareWeight
        }
      }
    }
  }
`;
