/* External dependencies */
import { debounceTime, filter, switchMap } from 'rxjs/operators';

/* Local dependencies */
import { getClient } from '../../../clients/bts';
import {
  ListConsigneeProfilesAction,
  ListConsigneeProfiles,
  ListConsigneeProfilesActionTypes,
  listConsigneeProfilesSucceeded,
  ListConsigneeProfilesSuccess,
  listConsigneeProfilesFailed,
} from './actions';
import { listConsigneeProfilesQuery } from './query';

export function listConsigneeProfilesEpic(action$, state$) {
  return action$.pipe(
    filter(
      (action: ListConsigneeProfilesAction) =>
        action.type ===
        ListConsigneeProfilesActionTypes.LIST_CONSIGNEE_PROFILES_REQUEST,
    ),
    debounceTime(300),
    switchMap((action: ListConsigneeProfiles) =>
      listConsigneeProfiles(action, state$.value.consigneeProfiles.size).catch(
        (error) => listConsigneeProfilesFailed(error),
      ),
    ),
  );
}

export async function listConsigneeProfiles(
  { currentPage, searchString }: ListConsigneeProfiles,
  size: number,
): Promise<ListConsigneeProfilesSuccess> {
  const graphQLClient = await getClient();

  const {
    data: {
      listConsigneeProfiles: { total, consigneeProfiles },
    },
  } = await graphQLClient.query({
    query: listConsigneeProfilesQuery,
    variables: {
      input: {
        from: (currentPage - 1) * size,
        query: searchString,
        size,
      },
    },
  });

  return listConsigneeProfilesSucceeded(total, consigneeProfiles);
}
