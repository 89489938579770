// External dependencies
import gql from 'graphql-tag';

export const updateVehicleProfileMutation = gql`
  mutation updateVehicleProfile($input: UpdateVehicleProfileInput) {
    updateVehicleProfile(input: $input) {
      id
      tareWeight
      tareWeightDate
      vehiclePlateNumber
      trailerPlateNumber
    }
  }
`;
