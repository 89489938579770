// External dependencies
import { filter, switchMap } from 'rxjs/operators';

// Local depedencies
import { getClient } from '../../../../clients/bts';
import { removeTypename } from '../../../common/helpers';
import { Contract } from '../../types';
import {
  GetContract,
  GetContractActions,
  GetContractActionsTypes,
  getContractFailed,
  getContractSucceeded,
  updateContractFailed,
  updateContractSucceeded,
} from './actions';
import { updateContractMutation } from './mutation';
import { getContractQuery } from './queries';

export function getContractEpic(action$) {
  return action$.pipe(
    filter(
      (action: GetContractActions) =>
        action.type === GetContractActionsTypes.GET_CONTRACT_REQUEST,
    ),
    switchMap((action: GetContract) =>
      getContract(action).then(getContractSucceeded).catch(getContractFailed),
    ),
  );
}

export async function getContract({ id }: GetContract): Promise<Contract> {
  const graphQLClient = await getClient();

  const {
    data: { getContract },
  } = await graphQLClient.query({
    query: getContractQuery,
    variables: {
      input: {
        id,
      },
    },
  });

  return getContract as Contract;
}

export function updateContractEpic(actions$) {
  return actions$.pipe(
    filter(
      (action: GetContractActions) =>
        action.type === GetContractActionsTypes.UPDATE_CONTRACT_REQUEST,
    ),
    switchMap((action: GetContractActions) =>
      updateContract(action)
        .then(updateContractSucceeded)
        .catch((error) => updateContractFailed(error)),
    ),
  );
}

export async function updateContract(action): Promise<Contract> {
  const graphQLClient = await getClient();

  const {
    data: { updateContract },
  } = await graphQLClient.mutate({
    mutation: updateContractMutation,
    variables: {
      input: removeTypename(action.contract),
    },
  });

  return updateContract as Contract;
}
