// Local dependencies
import { DEFAULT_PAGE_SIZE } from '../../common/helpers';
import { Device } from '../types';

export enum ListDevicesActionsTypes {
  LIST_DEVICES_REQUEST = 'LIST_DEVICES_REQUEST',
  LIST_DEVICES_SUCCESS = 'LIST_DEVICES_SUCCESS',
  LIST_DEVICES_FAILURE = 'LIST_DEVICES_FAILURE',
}

export type ListDevicesActions =
  | ListDevicesRequest
  | ListDevicesFailure
  | ListDevicesSuccess;

export enum SortDirection {
  ASC = 'ASC',
  DESC = 'DESC',
}

export interface Sort {
  field: string;
  direction: SortDirection;
}

export interface ListDevicesRequest {
  type: ListDevicesActionsTypes.LIST_DEVICES_REQUEST;
  from?: number;
  searchString?: string;
  size?: number;
  sort?: Sort;
}

export interface ListDevicesSuccess {
  type: ListDevicesActionsTypes.LIST_DEVICES_SUCCESS;
  devices: [Device];
  total: number;
}

export interface ListDevicesFailure {
  type: ListDevicesActionsTypes.LIST_DEVICES_FAILURE;
  error: Error;
}

export function listDevicesRequest(
  from = 0,
  searchString = '',
  size = DEFAULT_PAGE_SIZE,
  sort?: Sort,
): ListDevicesRequest {
  return {
    type: ListDevicesActionsTypes.LIST_DEVICES_REQUEST,
    from,
    searchString,
    size,
    sort,
  };
}

export function listDevicesSucceeded(
  devices: [Device],
  total: number,
): ListDevicesSuccess {
  return {
    type: ListDevicesActionsTypes.LIST_DEVICES_SUCCESS,
    devices,
    total,
  };
}

export function listDevicesFailed(error: Error): ListDevicesFailure {
  return {
    type: ListDevicesActionsTypes.LIST_DEVICES_FAILURE,
    error,
  };
}
