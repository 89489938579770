/* Local dependencies */
import { UpdateVehicleField } from '../../create-vehicle/redux/actions';
import { VehicleStatus } from '../../create-vehicle/vehicles_types';
import { Device } from '../../devices/types';
import { VehicleProfile } from '../../vehicles/vehicle-types';

export enum VehicleActionTypes {
  UPDATE_VEHICLE_REQUEST = 'UPDATE_VEHICLE_REQUEST',
  UPDATE_VEHICLE_SUCCESS = 'UPDATE_VEHICLE_SUCCESS',
  UPDATE_VEHICLE_ERROR = 'UPDATE_VEHICLE_ERROR',
  GET_VEHICLE_REQUEST = 'GET_VEHICLE_REQUEST',
  GET_VEHICLE_SUCCESS = 'GET_VEHICLE_SUCCESS',
  GET_VEHICLE_ERROR = 'GET_VEHICLE_ERROR',
  RESET_VEHICLE_DETAILS = 'RESET_VEHICLE_DETAILS',
  UPDATE_VEHICLE_ADD_INFO = 'UPDATE_VEHICLE_ADD_INFO',
  UPDATE_VEHICLE_RESET_ERROR = 'UPDATE_VEHICLE_RESET_ERROR',
  GET_DEVICE_REQUEST = 'GET_DEVICE_REQUEST',
  GET_DEVICE_SUCCESS = 'GET_DEVICE_SUCCESS',
  GET_DEVICE_ERROR = 'GET_DEVICE_ERROR',
  RESET_DEVICE = 'RESET_DEVICE',
}

type ExternalUser = {
  name: string;
  phone?: string;
  role?: string;
};

export interface AddInfoInput {
  actFormNumber?: string;
  borderCheckpoint?: string;
  departureCountry?: string;
  driver?: ExternalUser;
  status?: VehicleStatus;
  trailerPlateNumber?: string;
  vehicleProfile?: VehicleProfile;
  vehicleProfileId: string;
}

export interface Vehicle extends AddInfoInput {
  id?: string;
  paymentStatus?: string;
}

export interface ResetVehicleDetails {
  type: VehicleActionTypes.RESET_VEHICLE_DETAILS;
}

export interface UpdateVehicleRequest {
  type: VehicleActionTypes.UPDATE_VEHICLE_REQUEST;
  vehicle: AddInfoInput;
}

export interface UpdateVehicleSuccess {
  type: VehicleActionTypes.UPDATE_VEHICLE_SUCCESS;
  vehicle: Vehicle;
}

export interface UpdateVehicleError {
  type: VehicleActionTypes.UPDATE_VEHICLE_ERROR;
  error: Error;
}

export interface UpdateVehicleFieldsRequest {
  type: VehicleActionTypes.UPDATE_VEHICLE_ADD_INFO;
  updates: UpdateVehicleField;
}

export interface UpdateVehicleResetError {
  type: VehicleActionTypes.UPDATE_VEHICLE_RESET_ERROR;
}

export interface GetVehicleRequest {
  type: VehicleActionTypes.GET_VEHICLE_REQUEST;
  id: string;
}

export interface GetVehicleSuccess {
  type: VehicleActionTypes.GET_VEHICLE_SUCCESS;
  editableFields: string[];
  vehicle: Vehicle;
}

export interface GetVehicleError {
  type: VehicleActionTypes.GET_VEHICLE_ERROR;
  error: Error;
}

export interface GetDeviceRequest {
  type: VehicleActionTypes.GET_DEVICE_REQUEST;
  id: string;
}

export interface GetDeviceSuccess {
  type: VehicleActionTypes.GET_DEVICE_SUCCESS;
  device: Device;
}

export interface GetDeviceFailure {
  type: VehicleActionTypes.GET_DEVICE_ERROR;
  error: Error;
}

export interface ResetDevice {
  type: VehicleActionTypes.RESET_DEVICE;
}

export type VehicleAction =
  | UpdateVehicleRequest
  | UpdateVehicleSuccess
  | UpdateVehicleError
  | GetVehicleRequest
  | GetVehicleSuccess
  | GetVehicleError
  | ResetVehicleDetails
  | UpdateVehicleFieldsRequest
  | UpdateVehicleResetError
  | GetDeviceRequest
  | GetDeviceSuccess
  | GetDeviceFailure
  | ResetDevice;

export function resetVehicleDetails(): ResetVehicleDetails {
  return {
    type: VehicleActionTypes.RESET_VEHICLE_DETAILS,
  };
}

export function updateVehicleRequest(
  vehicle: AddInfoInput,
): UpdateVehicleRequest {
  return {
    type: VehicleActionTypes.UPDATE_VEHICLE_REQUEST,
    vehicle,
  };
}

export function updateVehicleSucceeded(vehicle: Vehicle): UpdateVehicleSuccess {
  return {
    type: VehicleActionTypes.UPDATE_VEHICLE_SUCCESS,
    vehicle,
  };
}

export function updateVehicleFailed(error: Error): UpdateVehicleError {
  return {
    type: VehicleActionTypes.UPDATE_VEHICLE_ERROR,
    error,
  };
}

export function updateVehicleAddInfo(
  updates: UpdateVehicleField,
): UpdateVehicleFieldsRequest {
  return {
    type: VehicleActionTypes.UPDATE_VEHICLE_ADD_INFO,
    updates,
  };
}

export function getVehicle(id: string): GetVehicleRequest {
  return {
    type: VehicleActionTypes.GET_VEHICLE_REQUEST,
    id,
  };
}

export function getVehicleSucceeded(
  vehicle: Vehicle,
  editableFields: string[],
): GetVehicleSuccess {
  return {
    type: VehicleActionTypes.GET_VEHICLE_SUCCESS,
    editableFields,
    vehicle,
  };
}

export function getVehicleFailed(error: Error): GetVehicleError {
  return {
    type: VehicleActionTypes.GET_VEHICLE_ERROR,
    error,
  };
}

export function resetError(): UpdateVehicleResetError {
  return {
    type: VehicleActionTypes.UPDATE_VEHICLE_RESET_ERROR,
  };
}

export function getDevice(id: string): GetDeviceRequest {
  return {
    type: VehicleActionTypes.GET_DEVICE_REQUEST,
    id,
  };
}

export function getDeviceSucceeded(device: Device): GetDeviceSuccess {
  return {
    type: VehicleActionTypes.GET_DEVICE_SUCCESS,
    device,
  };
}

export function getDeviceFailure(error: Error): GetDeviceFailure {
  return {
    type: VehicleActionTypes.GET_DEVICE_ERROR,
    error,
  };
}

export function resetDevice(): ResetDevice {
  return {
    type: VehicleActionTypes.RESET_DEVICE,
  };
}
