/* External dependencies */
import update from 'immutability-helper';

/* Local dependencies */
import { User } from '../userTypes';
import { validateForm } from '../validateForm';
import { UserActions, UserActionTypes } from './actions';

export interface UserState {
  error?: Error;
  firstNameError?: Error;
  lastNameError?: Error;
  loading?: boolean;
  phoneError?: Error;
  roleError?: Error;
  statusError?: Error;
  isUserFormChanged?: boolean;
  isUserUpdated?: boolean;
  user: User;
}

export const initialUserState: UserState = {
  user: {
    id: '',
    firstName: '',
    lastName: '',
    phone: '',
    role: '',
    status: '',
  },
};

export default function userReducer(
  state = initialUserState,
  action: UserActions,
) {
  switch (action.type) {
    case UserActionTypes.RESET_USER_DETAILS:
      return update(state, {
        $set: initialUserState,
      });

    case UserActionTypes.GET_USER_REQUEST:
      return update(state, {
        $unset: ['error'],
        loading: { $set: true },
      });

    case UserActionTypes.GET_USER_SUCCESS:
      return update(state, {
        loading: { $set: false },
        user: { $set: action.user },
      });

    case UserActionTypes.GET_USER_FAILURE:
      return update(state, {
        error: { $set: action.error },
        loading: { $set: false },
      });

    case UserActionTypes.UPDATE_USER_REQUEST:
      return update(state, {
        $unset: ['error'],
        loading: { $set: true },
        isUserFormChanged: { $set: false },
        isUserUpdated: { $set: false },
      });

    case UserActionTypes.UPDATE_USER_SUCCESS:
      return update(state, {
        isUserFormChanged: { $set: false },
        loading: { $set: false },
        user: { $set: action.user },
        isUserUpdated: { $set: true },
      });

    case UserActionTypes.UPDATE_USER_FAILURE:
      return update(state, {
        error: { $set: action.error },
        loading: { $set: false },
        isUserUpdated: { $set: false },
      });

    case UserActionTypes.UPDATE_USER_FIELDS:
      return validateForm(state, action.updates);

    default:
      return state;
  }
}
