export enum CreateUserActionTypes {
  CREATE_USER_REQUEST = 'CREATE_USER_REQUEST',
  CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS',
  CREATE_USER_ERROR = 'CREATE_USER_ERROR',
  CREATE_USER_RESET_ERROR = 'CREATE_USER_RESET_ERROR',
  RESET_CREATE_USER = 'RESET_CREATE_USER',
  UPDATE_USER_FIELDS = 'UPDATE_USER_FIELDS',
}

export interface UpdateUserInput {
  firstName?: string;
  lastName?: string;
  phone?: string;
  role?: string;
}

export interface CreateUserInput {
  firstName: string;
  lastName: string;
  phone: string;
  role: string;
}

export interface User extends CreateUserInput {
  id?: string;
}

export interface CreateUserRequest {
  type: CreateUserActionTypes.CREATE_USER_REQUEST;
  user: CreateUserInput;
}

export interface CreateUserSuccess {
  type: CreateUserActionTypes.CREATE_USER_SUCCESS;
  user: User;
}

export interface CreateUserError {
  type: CreateUserActionTypes.CREATE_USER_ERROR;
  error: Error;
}

export interface CreateUserResetError {
  type: CreateUserActionTypes.CREATE_USER_RESET_ERROR;
}

export interface ResetCreateUser {
  type: CreateUserActionTypes.RESET_CREATE_USER;
}

export interface UpdateUserInputRequest {
  type: CreateUserActionTypes.UPDATE_USER_FIELDS;
  updates: UpdateUserInput;
}

export type CreateUserAction =
  | CreateUserRequest
  | CreateUserSuccess
  | CreateUserError
  | CreateUserResetError
  | ResetCreateUser
  | UpdateUserInputRequest;

export function createUserRequest(user: CreateUserInput): CreateUserRequest {
  return {
    type: CreateUserActionTypes.CREATE_USER_REQUEST,
    user,
  };
}

export function createUserSucceeded(user: User): CreateUserSuccess {
  return {
    type: CreateUserActionTypes.CREATE_USER_SUCCESS,
    user,
  };
}

export function createUserFailedAction(error: Error): CreateUserError {
  return {
    type: CreateUserActionTypes.CREATE_USER_ERROR,
    error,
  };
}

export function resetCreateUser(): ResetCreateUser {
  return {
    type: CreateUserActionTypes.RESET_CREATE_USER,
  };
}

export function resetError(): CreateUserResetError {
  return {
    type: CreateUserActionTypes.CREATE_USER_RESET_ERROR,
  };
}

export function updateUserFields(
  updates: UpdateUserInput,
): UpdateUserInputRequest {
  return {
    type: CreateUserActionTypes.UPDATE_USER_FIELDS,
    updates,
  };
}
