/* External dependencies */
import gql from 'graphql-tag';

export const consigneeDetailQuery = gql`
  query getConsignee($input: GetConsigneeInput!) {
    getConsignee(input: $input) {
      arrivalDate
      cargoWeight
      id
      departureCountry
      name
      paymentStatus
      products
      registrationDate
      slots
      unloadedToWarehouseDate
      vehiclePlateNumber
      warehouse {
        id
        name
      }
    }
  }
`;
