/* External dependencies */
import { filter } from 'rxjs';
import { switchMap } from 'rxjs/operators';

/* Local dependencies */
import { getClient } from '../../../clients/bts';
import { removeTypename } from '../../common/helpers';
import { Warehouse } from '../../warehouses/types';
import {
  UpdateWarehouse,
  WarehouseActionTypes,
  WarehouseActions,
  updateWarehouseFailure,
  updateWarehouseSuccess,
} from './actions';
import { updateWarehouseMutation } from './mutations';

export default function updateWarehouseEpic(action$) {
  return action$.pipe(
    filter(
      (action: WarehouseActions) =>
        action.type === WarehouseActionTypes.UPDATE_WAREHOUSE_REQUEST,
    ),
    switchMap((action: UpdateWarehouse) =>
      updateWarehouseDetails(action)
        .then(updateWarehouseSuccess)
        .catch((error) => {
          if (error.graphQLErrors && error.graphQLErrors.length) {
            const [{ message }] = error.graphQLErrors;

            return updateWarehouseFailure(new Error(message));
          }

          return updateWarehouseFailure(error);
        }),
    ),
  );
}

export async function updateWarehouseDetails(action): Promise<Warehouse> {
  const graphQLClient = await getClient();

  const {
    data: { updateWarehouse },
  } = await graphQLClient.mutate({
    mutation: updateWarehouseMutation,
    variables: {
      input: removeTypename(action.warehouse),
    },
  });

  return updateWarehouse as Warehouse;
}
