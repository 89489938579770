/* External dependencies */
import Cookies from 'js-cookie';
import React from 'react';
import { IntlProvider } from 'gatsby-plugin-react-intl';
import { Provider } from 'react-redux';

/* Local dependencies */
import locales from '../intl/locales';
import { createStore } from './store';

const store = createStore();

function getLocale() {
  const locale = Cookies.get('lang');

  return locales.SUPPORTED_LOCALES.includes(locale)
    ? locale
    : locales.DEFAULT_LOCALE;
}

export default ({ element }) => {
  const locale = getLocale();
  const messages = require(`../intl/${locale}.json`);

  return (
    <IntlProvider locale={locale} messages={messages}>
      <Provider store={store}>{element}</Provider>
    </IntlProvider>
  );
};
