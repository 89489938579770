// External dependencies
import { filter, switchMap } from 'rxjs/operators';
import { getClient } from '../../../clients/bts';
import {
  ListCountriesActionTypes,
  ListCountriesActions,
  ListCountriesRequest,
  listCountriesFailed,
  listCountriesSucceeded,
} from './actions';
import { listCountriesQuery } from './query';

export function listCountriesEpic(actions$) {
  return actions$.pipe(
    filter(
      (action: ListCountriesActions) =>
        action.type === ListCountriesActionTypes.LIST_COUNTRIES_REQUEST,
    ),

    switchMap((action: ListCountriesRequest) => {
      return listCountries(action)
        .then((countries) =>
          listCountriesSucceeded(countries.countries, countries.total),
        )
        .catch((error) => listCountriesFailed(error));
    }),
  );
}

async function listCountries(action: ListCountriesRequest) {
  const graphQlClient = await getClient();

  const { currentPage = 1, filter, size = 20, searchString } = action;

  const {
    data: {
      listCountries: { countries, total },
    },
  } = await graphQlClient.query({
    query: listCountriesQuery,
    variables: {
      input: {
        filter,
        from: (currentPage - 1) * size,
        query: searchString,
      },
    },
  });

  const currencies = countries.filter((item)=> item.__typename === 'Currency')

  return {
    countries: currencies,
    total,
  };
}
