/* External dependencies */
import gql from 'graphql-tag';

export const updateWarehouseMutation = gql`
  mutation updateWarehouse($input: UpdateWarehouseInput!) {
    updateWarehouse(input: $input) {
      id
      name
      status
    }
  }
`;
