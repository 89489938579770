// External dependencies
import update from 'immutability-helper';

// Local dependencies
import { Contract, ContractType } from '../../createContracts/redux/types';
import { validateContractsfields } from '../../createContracts/redux/validation';
import { GetContractActions, GetContractActionsTypes } from './actions';
import { validateGetUpdateContractsfields } from './getUpdateContractValidation';

export interface GetContractState {
  contract?: Contract;
  error?: Error;
  isSuccess?: boolean;
  updateLoading?: boolean;
  isFormChanged?: boolean;
  isUpdateSuccess?: boolean;
  updateError?: Error;
  loading: boolean;
  updatedContract?: Contract;
  startDateError?: Error;
  endDateError?: Error;
  countryError?: Error;
  cityError?: Error;
  streetError?: Error;
  consigneeHeadFullNameError?: Error;
  fieldNameError?: Error;
  fieldValueError?: Error;
  eventTypeError?: Error;
  organizationError?: Error;
  individualError?: Error;
  deactivationReasonError?: Error;
}

const initialGetContractState: GetContractState = {
  loading: true,
};

export default function getContract(
  state = initialGetContractState,
  action: GetContractActions,
) {
  switch (action.type) {
    case GetContractActionsTypes.GET_CONTRACT_REQUEST:
      return update(state, {
        loading: { $set: true },
      });

    case GetContractActionsTypes.GET_CONTRACT_SUCCESS:
      return update(state, {
        loading: { $set: false },
        contract: { $set: action.contract },
        updatedContract: { $set: copyContract(action.contract) },
      });

    case GetContractActionsTypes.GET_CONTRACT_ERROR:
      return update(state, {
        loading: { $set: false },
        isSuccess: { $set: false },
        error: { $set: action.error },
      });

    case GetContractActionsTypes.RESET_GET_CONTRACT:
      return initialGetContractState;

    case GetContractActionsTypes.RESET_UPDATE_CONTRACT_ERROR:
      const { updateError, ...newState } = state;
      return newState;

    case GetContractActionsTypes.UPDATE_CONTRACT_REQUEST:
      return update(state, {
        updateLoading: { $set: true },
      });
    case GetContractActionsTypes.UPDATE_CONTRACT_SUCCESS:
      return update(state, {
        updateLoading: { $set: false },
        isUpdateSuccess: { $set: true },
        contract: { $set: action.contract },
      });
    case GetContractActionsTypes.UPDATE_CONTRACT_ERROR:
      return update(state, {
        updateLoading: { $set: false },
        isUpdateSuccess: { $set: false },
        updateError: { $set: action.updateError },
      });
    case GetContractActionsTypes.UPDATE_CONTRACT_FIELDS:
      return validateGetUpdateContractsfields(state, action.updates);
    case GetContractActionsTypes.RESET_UPDATE_CONTRACT:
      return initialGetContractState;
    default:
      return state;
  }
}

function copyContract(contract: Contract) {
  const { organization, ...restOfTheContract } = contract;

  return restOfTheContract;
}
