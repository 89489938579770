/* External dependencies */
import update from 'immutability-helper';

/* Local dependencies */
import {
  CargoEscort,
  CreateCargoAction,
  CreateCargoActionTypes,
  CreateCargoInput,
} from './actions';
import { validateCargo } from './validateCargo';

export interface CargoState {
  cargo?: CreateCargoInput;
  error?: Error;
  loading?: boolean;
  isSuccess?: boolean;
}

const initialCargoState: CargoState = {
  cargo: {
    cargoNetWeight: null,
    cargoSlots: null,
    cargoEscort: CargoEscort.TIR,
    consignees: [],
    vehicle: null,
  },
  isSuccess: false,
};

export default function newCargo(
  state = initialCargoState,
  action: CreateCargoAction,
) {
  switch (action.type) {
    case CreateCargoActionTypes.CREATE_CARGO_REQUEST:
      return update(state, {
        $unset: ['error'],
        loading: { $set: true },
      });

    case CreateCargoActionTypes.CREATE_CARGO_SUCCESS:
      return update(state, {
        loading: { $set: false },
        cargo: { $set: action.cargo },
        isSuccess: { $set: true },
      });

    case CreateCargoActionTypes.CREATE_CARGO_ERROR:
      return update(state, {
        loading: { $set: false },
        error: { $set: action.error },
      });

    case CreateCargoActionTypes.CREATE_CARGO_RESET:
      return update(state, {
        $set: initialCargoState,
      });

    case CreateCargoActionTypes.UPDATE_CARGO_FIELDS:
      return validateCargo(state, action.updates);

    case CreateCargoActionTypes.CREATE_CARGO_RESET_ERROR:
      return update(state, {
        $unset: ['error'],
      });

    default:
      return state;
  }
}
