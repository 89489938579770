/* Local dependencies */
import formatMessage from '../../../intl/formatMessage';
import renderDate from '../../common/renderDate';
import getFullName from '../../common/renderName';
import { Vehicle } from '../redux/actions';

export function getAdminFields() {
  return [
    {
      label: 'arrivalDate',
      value: (vehicle) => renderDate(vehicle.arrivalDate),
      placeholder: 'arrivalDate',
    },
    {
      label: 'vehiclePlateNumber',
      placeholder: 'vehiclePlateNumberPlaceholder',
    },
    {
      label: 'trailerPlateNumber',
      placeholder: 'trailerPlateNumberPlaceholder',
    },
    {
      isDropdownCapable: true,
      label: 'arrivalPurpose',
      placeholder: 'arrivalPurpose',
      value: (vehicle) => formatMessage(vehicle.arrivalPurpose),
    },
    {
      label: 'weightType',
      placeholder: 'weightType',
      value: (vehicle) => formatMessage(vehicle.weightType),
    },
    {
      label: 'grossWeight',
      placeholder: 'grossWeight',
      value: (vehicle: Vehicle) => vehicle.grossWeight,
    },
    {
      label: 'driverName',
      hasSeparator: true,
      placeholder: 'driverPlaceholder',
      value: (vehicle) => vehicle.driver.name,
    },
    {
      errorField: 'driverPhoneError',
      label: 'driverPhone',
      placeholder: 'driverPhonePlaceholder',
      value: (vehicle) => vehicle.driver.phone,
    },
    {
      label: 'actFormNumber',
      placeholder: 'actFormNumberPlaceholder',
    },
    {
      label: 'borderCheckpoint',
      isDropdownCapable: true,
      placeholder: 'borderCheckpointPlaceholder',
      value: (vehicle) => vehicle.borderCheckpoint,
    },
    {
      label: 'entryRegistered',
      hasSeparator: true,
      placeholder: 'entryRegistered',
      value: (vehicle) =>
        getFullName(vehicle.operator.firstName, vehicle.operator.lastName),
    },
  ];
}

export function getAdminEditableFields() {
  return [
    'arrivalDate',
    'vehiclePlateNumber',
    'trailerPlateNumber',
    'arrivalPurpose',
    'grossWeight',
    'driverName',
    'driverPhone',
    'actFormNumber',
    'borderCheckpoint',
  ];
}
