/*Local dependencies */
import { Vehicle } from './../../../vehicle-details/redux/actions';

export enum ListVehiclesForExtractionActionTypes {
  LIST_VEHICLES_FOR_EXTRACTION_REQUEST = 'LIST_VEHICLES_FOR_EXTRACTION_REQUEST',
  LIST_VEHICLES_FOR_EXTRACTION_SUCCESS = 'LIST_VEHICLES_FOR_EXTRACTION_SUCCESS',
  LIST_VEHICLES_FOR_EXTRACTION_ERROR = 'LIST_VEHICLES_FOR_EXTRACTION_ERROR',
}

export interface ListVehiclesForExtraction {
  type: ListVehiclesForExtractionActionTypes.LIST_VEHICLES_FOR_EXTRACTION_REQUEST;
}

export interface ListVehiclesForExtractionSuccess {
  type: ListVehiclesForExtractionActionTypes.LIST_VEHICLES_FOR_EXTRACTION_SUCCESS;
  vehicles: Vehicle[];
}

export interface ListVehiclesForExtractionError {
  type: ListVehiclesForExtractionActionTypes.LIST_VEHICLES_FOR_EXTRACTION_ERROR;
  error: Error;
}

export type ListVehiclesForExtractionAction =
  | ListVehiclesForExtraction
  | ListVehiclesForExtractionSuccess
  | ListVehiclesForExtractionError;

export function listVehiclesForExtraction(): ListVehiclesForExtraction {
  return {
    type: ListVehiclesForExtractionActionTypes.LIST_VEHICLES_FOR_EXTRACTION_REQUEST,
  };
}

export function listVehiclesForExtractionSucceeded(
  vehicles: Vehicle[],
): ListVehiclesForExtractionSuccess {
  return {
    type: ListVehiclesForExtractionActionTypes.LIST_VEHICLES_FOR_EXTRACTION_SUCCESS,
    vehicles,
  };
}

export function listVehiclesForExtractionFailed(
  error: Error,
): ListVehiclesForExtractionError {
  return {
    type: ListVehiclesForExtractionActionTypes.LIST_VEHICLES_FOR_EXTRACTION_ERROR,
    error,
  };
}
