/* External dependencies */
import gql from 'graphql-tag';

export const updateUserMutation = gql`
  mutation updateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      id
      firstName
      lastName
      phone
      role
      status
    }
  }
`;
