// Order matters. Will appear in the same order.
enum Roles {
  ADMIN = 'admin',
  CASHIER = 'cashier',
  CUSTOMS_OFFICER = 'customsOfficer',
  CUSTOMS_REPRESENTATIVE = 'customsRepresentative',
  MANAGER_OPERATOR = 'managerOperator',
  OFFICE_MANAGER = 'officeManager',
  OPERATOR = 'operator',
  OPERATOR_WEIGHMAN = 'operatorWeighman',
  WEIGHMAN = 'weighman',
}

const roles = [
  Roles.ADMIN,
  Roles.CASHIER,
  Roles.CUSTOMS_REPRESENTATIVE,
  Roles.MANAGER_OPERATOR,
  Roles.OPERATOR,
  Roles.OFFICE_MANAGER,
  Roles.OPERATOR_WEIGHMAN,
  Roles.WEIGHMAN,
];

export default roles;

export enum UserRoles {
  BTS_ADMIN = 'BTSAdmin',
  BTS_CASHIER = 'BTSCashier',
  BTS_CUSTOMS_REPRESENTATIVE = 'BTSCustomsRepresentative',
  BTS_OFFICE_MANAGER = 'BTSOfficeManager',
  BTS_OPERATOR_WEIGHMAN = 'BTSOperatorWeighman',
  BTS_OPERATOR = 'BTSOperator',
  BTS_MANAGER_OPERATOR = 'BTSManagerOperator',
  BTS_WEIGHMAN = 'BTSWeighman',
}

export function extractRoleFromARN(arn: string): UserRoles {
  const roles = Object.keys(UserRoles).map((key) => UserRoles[key]);
  // ARN of an IAM role in the following form
  // `arn:aws:iam::368080513008:role/BTSAdmin`.
  const roleRE = new RegExp(`^arn:[^:]+:iam::\\d+:role/.*(${roles.join('|')})`);
  const match = arn.match(roleRE);

  if (!match) {
    throw new Error('Could not determine the role of the user.');
  }

  return match[1] as UserRoles;
}
