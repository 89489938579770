export enum CreateWarehouseActionTypes {
  CREATE_WAREHOUSE_REQUEST = 'CREATE_WAREHOUSE_REQUEST',
  CREATE_WAREHOUSE_SUCCESS = 'CREATE_WAREHOUSE_SUCCESS',
  CREATE_WAREHOUSE_ERROR = 'CREATE_WAREHOUSE_ERROR',
  CREATE_WAREHOUSE_RESET_ERROR = 'CREATE_WAREHOUSE_RESET_ERROR',
  RESET_CREATE_WAREHOUSE = 'RESET_CREATE_WAREHOUSE',
  UPDATE_WAREHOUSE_NAME = 'UPDATE_WAREHOUSE_NAME',
}

export interface CreateWarehouseRequest {
  type: CreateWarehouseActionTypes.CREATE_WAREHOUSE_REQUEST;
  name: string;
}

export interface CreateWarehouseSuccess {
  type: CreateWarehouseActionTypes.CREATE_WAREHOUSE_SUCCESS;
}

export interface CreateWarehouseError {
  type: CreateWarehouseActionTypes.CREATE_WAREHOUSE_ERROR;
  error: Error;
}

export interface CreateWarehouseResetError {
  type: CreateWarehouseActionTypes.CREATE_WAREHOUSE_RESET_ERROR;
}

export interface ResetCreateWarehouse {
  type: CreateWarehouseActionTypes.RESET_CREATE_WAREHOUSE;
}

export interface UpdateWarehouseName {
  type: CreateWarehouseActionTypes.UPDATE_WAREHOUSE_NAME;
  name: string;
}

export type CreateWarehouseAction =
  | CreateWarehouseRequest
  | CreateWarehouseSuccess
  | CreateWarehouseError
  | CreateWarehouseResetError
  | ResetCreateWarehouse
  | UpdateWarehouseName;

export function createWarehouseRequest(name: string): CreateWarehouseRequest {
  return {
    type: CreateWarehouseActionTypes.CREATE_WAREHOUSE_REQUEST,
    name,
  };
}

export function createWarehouseSucceeded(): CreateWarehouseSuccess {
  return {
    type: CreateWarehouseActionTypes.CREATE_WAREHOUSE_SUCCESS,
  };
}

export function createWarehouseFailed(error: Error): CreateWarehouseError {
  return {
    type: CreateWarehouseActionTypes.CREATE_WAREHOUSE_ERROR,
    error,
  };
}

export function resetCreateWarehouse(): ResetCreateWarehouse {
  return {
    type: CreateWarehouseActionTypes.RESET_CREATE_WAREHOUSE,
  };
}

export function resetError(): CreateWarehouseResetError {
  return {
    type: CreateWarehouseActionTypes.CREATE_WAREHOUSE_RESET_ERROR,
  };
}

export function updateWarehouseName(name: string): UpdateWarehouseName {
  return {
    type: CreateWarehouseActionTypes.UPDATE_WAREHOUSE_NAME,
    name,
  };
}
