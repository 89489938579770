import { Consignee } from '../../create-cargo/redux/actions';
import { Vehicle } from '../../create-vehicle/redux/actions';
import { Warehouse } from '../../warehouses/types';

export enum ConsigneeStatus {
  ON_ARRIVED_VEHICLE = 'ON_ARRIVED_VEHICLE',
  UNLOADED_TO_VEHICLE = 'UNLOADED_TO_VEHICLE',
  UNLOADED_TO_WAREHOUSE = 'UNLOADED_TO_WAREHOUSE',
  DEPARTED = 'DEPARTED',
}

export enum ListConsigneesActionTypes {
  LIST_CONSIGNEES_REQUEST = 'LIST_CONSIGNEES_REQUEST',
  LIST_CONSIGNEES_SUCCESS = 'LIST_CONSIGNEES_SUCCESS',
  LIST_CONSIGNEES_ERROR = 'LIST_CONSIGNEES_ERROR',
  RESET_SELECTED_CONSIGNEES = 'RESET_SELECTED_CONSIGNEES',
  SELECT_CONSIGNEE = 'SELECT_CONSIGNEE',
  SELECT_VEHICLE_FOR_EXTRACTION = 'SELECT_VEHICLE_FOR_EXTRACTION',
  SORT_CONSIGNEES_BY = 'SORT_CONSIGNEES_BY',
  SELECT_PLACE_FOR_MOVING_CARGO = 'SELECT_PLACE_FOR_MOVING_CARGO',
  SELECT_WAREHOUSE_FOR_EXTRACTION = 'SELECT_WAREHOUSE_FOR_EXTRACTION',
}

export interface ListConsignees {
  type: ListConsigneesActionTypes.LIST_CONSIGNEES_REQUEST;
  currentPage?: number;
  searchString?: string;
  filter?: ListConsigneesFilter;
}

export interface ListConsigneesSuccess {
  type: ListConsigneesActionTypes.LIST_CONSIGNEES_SUCCESS;
  consignees: [];
  total: number;
}

export interface ListConsigneesError {
  type: ListConsigneesActionTypes.LIST_CONSIGNEES_ERROR;
  error: Error;
}

export interface ListConsigneesSort {
  type: ListConsigneesActionTypes.SORT_CONSIGNEES_BY;
  field: string;
}

export interface ListConsigneesFilter {
  status: ConsigneeStatus;
}

export interface SelectConsignee {
  type: ListConsigneesActionTypes.SELECT_CONSIGNEE;
  consignee: Consignee;
}

export interface ResetSelectedConsignee {
  type: ListConsigneesActionTypes.RESET_SELECTED_CONSIGNEES;
}

export interface SelectVehicleForExtraction {
  type: ListConsigneesActionTypes.SELECT_VEHICLE_FOR_EXTRACTION;
  vehicleForExtraction: Vehicle;
}
export interface SelectWarehouseForExtraction {
  type: ListConsigneesActionTypes.SELECT_WAREHOUSE_FOR_EXTRACTION;
  warehouseForExtraction: Warehouse;
}

export const DEFAULT_CONSIGNEES_STATUS: ListConsigneesFilter = {
  status: ConsigneeStatus.ON_ARRIVED_VEHICLE,
};

export interface SelectPlaceForMovingCargo {
  type: ListConsigneesActionTypes.SELECT_PLACE_FOR_MOVING_CARGO;
  cargoMovingTo: string;
}

export type ListConsigneesAction =
  | ListConsignees
  | ListConsigneesSuccess
  | ListConsigneesError
  | SelectConsignee
  | SelectVehicleForExtraction
  | SelectWarehouseForExtraction
  | ResetSelectedConsignee
  | SelectPlaceForMovingCargo;

export function listConsignees(
  searchString: string = '',
  currentPage: number = 1,
  filter: ListConsigneesFilter = DEFAULT_CONSIGNEES_STATUS,
): ListConsignees {
  return {
    type: ListConsigneesActionTypes.LIST_CONSIGNEES_REQUEST,
    searchString,
    currentPage,
    filter,
  };
}

export function listConsigneesSucceeded(
  total,
  consignees,
): ListConsigneesSuccess {
  return {
    type: ListConsigneesActionTypes.LIST_CONSIGNEES_SUCCESS,
    consignees,
    total,
  };
}

export function listConsigneesFailed(error: Error): ListConsigneesError {
  return {
    type: ListConsigneesActionTypes.LIST_CONSIGNEES_ERROR,
    error,
  };
}

export function sortConsigneesBy(field) {
  return {
    type: ListConsigneesActionTypes.SORT_CONSIGNEES_BY,
    field,
  };
}

export function selectConsignee(consignee: Consignee): SelectConsignee {
  return {
    type: ListConsigneesActionTypes.SELECT_CONSIGNEE,
    consignee,
  };
}

export function resetSelectedConsignees() {
  return {
    type: ListConsigneesActionTypes.RESET_SELECTED_CONSIGNEES,
  };
}

export function selectVehicleForExtraction(vehicleForExtraction: Vehicle) {
  return {
    type: ListConsigneesActionTypes.SELECT_VEHICLE_FOR_EXTRACTION,
    vehicleForExtraction,
  };
}

export function selectWarehouseForExtraction(
  warehouseForExtraction: Warehouse,
) {
  return {
    type: ListConsigneesActionTypes.SELECT_WAREHOUSE_FOR_EXTRACTION,
    warehouseForExtraction,
  };
}

export function selectPlaceForMovingCargo(
  cargoMovingTo: string,
): SelectPlaceForMovingCargo {
  return {
    type: ListConsigneesActionTypes.SELECT_PLACE_FOR_MOVING_CARGO,
    cargoMovingTo,
  };
}
