// Local dependencies
import { DEFAULT_PAGE_SIZE } from '../../../common/helpers';
import { ConsigneeGoodSpecifications } from '../../types';

export enum ListConsigneeGoodsSpecificationsActionTypes {
  LIST_CONSIGNEE_GOODS_SPECIFICATIONS_REQUEST = 'LIST_CONSIGNEE_GOODS_SPECIFICATIONS_REQUEST',
  LIST_CONSIGNEE_GOODS_SPECIFICATIONS_SUCCESS = 'LIST_CONSIGNEE_GOODS_SPECIFICATIONS_SUCCESS',
  LIST_CONSIGNEE_GOODS_SPECIFICATIONS_ERROR = 'LIST_CONSIGNEE_GOODS_SPECIFICATIONS_ERROR',
}

export type ListConsigneeGoodsSpecificationsActions =
  | ListConsigneeGoodsSpecificationsRequest
  | ListConsigneeGoodsSpecificationsSuccess
  | ListConsigneeGoodsSpecificationsFailure;

export interface ListConsigneeGoodsSpecificationsRequest {
  type: ListConsigneeGoodsSpecificationsActionTypes.LIST_CONSIGNEE_GOODS_SPECIFICATIONS_REQUEST;
  currentPage?: number;
  size?: number;
  searchString?: string;
}

export interface ListConsigneeGoodsSpecificationsSuccess {
  type: ListConsigneeGoodsSpecificationsActionTypes.LIST_CONSIGNEE_GOODS_SPECIFICATIONS_SUCCESS;
  total: number;
  consigneeGoodsSpecifications: ConsigneeGoodSpecifications[];
}

export interface ListConsigneeGoodsSpecificationsFailure {
  type: ListConsigneeGoodsSpecificationsActionTypes.LIST_CONSIGNEE_GOODS_SPECIFICATIONS_ERROR;
  error: Error;
}

export function listConsigneeGoodsSpecificationsRequest(
  searchString: string = '',
  currentPage: number = 1,
  size: number = DEFAULT_PAGE_SIZE,
): ListConsigneeGoodsSpecificationsRequest {
  return {
    type: ListConsigneeGoodsSpecificationsActionTypes.LIST_CONSIGNEE_GOODS_SPECIFICATIONS_REQUEST,
    searchString,
    currentPage,
    size,
  };
}

export function listConsigneeGoodsSpecificationsSucceeded(
  consigneeGoodsSpecifications: ConsigneeGoodSpecifications[],
  total: number,
): ListConsigneeGoodsSpecificationsSuccess {
  return {
    total,
    type: ListConsigneeGoodsSpecificationsActionTypes.LIST_CONSIGNEE_GOODS_SPECIFICATIONS_SUCCESS,
    consigneeGoodsSpecifications,
  };
}

export function listConsigneeGoodsSpecificationsFailed(
  error: Error,
): ListConsigneeGoodsSpecificationsFailure {
  return {
    type: ListConsigneeGoodsSpecificationsActionTypes.LIST_CONSIGNEE_GOODS_SPECIFICATIONS_ERROR,
    error,
  };
}
