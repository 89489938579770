/* External dependencies */
import gql from 'graphql-tag';

export const getConsigneeContractQuery = gql`
  query getConsigneeContract($input: GetConsigneeContractInput!) {
    getConsigneeContract(input: $input) {
      fields {
        id
        name
        order
        unit
        unitCount
        value
      }
      startDate
      title
    }
  }
`;
