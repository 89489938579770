// External dependencies
import { filter, switchMap } from 'rxjs';

// Local dependencies
import { getClient } from '../../../../clients/bts';
import {
  UpdateVehicleProfileActions,
  UpdateVehicleProfileActionTypes,
  updateVehicleProfileFailed,
  UpdateVehicleProfileRequest,
  updateVehicleProfileSucceeded,
} from './action';
import { updateVehicleProfileMutation } from './mutation';

export default function updateVehicleProfileEpic(action$, state$) {
  return action$.pipe(
    filter(
      (action: UpdateVehicleProfileActions) =>
        action.type ===
        UpdateVehicleProfileActionTypes.UPDATE_VEHICLE_PROFILE_REQUEST,
    ),
    switchMap((action: UpdateVehicleProfileRequest) =>
      updateVehicleProfile(action).catch(updateVehicleProfileFailed),
    ),
  );
}

async function updateVehicleProfile({ vehicleProfile }) {
  const graphQLClient = await getClient();
  const { id, vehiclePlateNumber, tareWeight } = vehicleProfile;

  const {
    data: { updateVehicleProfile },
  } = await graphQLClient.mutate({
    mutation: updateVehicleProfileMutation,
    variables: {
      input: {
        id,
        vehiclePlateNumber,
        tareWeight,
      },
    },
  });

  return updateVehicleProfileSucceeded(updateVehicleProfile);
}
