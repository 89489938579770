export enum DeviceType {
  CAMERA = 'CAMERA',
  SCALE = 'SCALE',
}

export enum WeightType {
  TARE_WEIGHT = 'TARE_WEIGHT',
  GROSS_WEIGHT = 'GROSS_WEIGHT',
}

export interface Device {
  id: string;
  type: DeviceType;
  updatedDate?: string;
  data: string;
}
