/* Local dependencies */
import { UIException } from '../../../../redux/exceptions';

export class ApplicationRequiredException extends UIException {
  code = 'ApplicationRequiredException';
}

export class DeclarationRequiredException extends UIException {
  code = 'DeclarationRequiredException';
}

export class PaymentTypeRequiredException extends UIException {
  code = 'PaymentTypeRequiredException';
}
