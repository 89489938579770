/* External dependencies */
import { of } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';

/* Local dependencies */
import {
  UnloadCargo,
  UnloadCargoAction,
  UnloadCargoActionTypes,
  unloadCargoFailed,
  unloadCargoSucceeded,
  UnloadCargoSuccess,
} from './actions';
import { updateConsigneeMutation } from './mutations';
import { getClient } from '../../../../clients/bts';

export default function cargoUnloadEpic(action$) {
  return action$.pipe(
    filter(
      (action: UnloadCargoAction) =>
        action.type === UnloadCargoActionTypes.CARGO_UNLOADING_REQUEST,
    ),
    switchMap((action: UnloadCargo) =>
      unloadCargo(action)
        .then(unloadCargoSucceeded as any)
        .catch((error) => unloadCargoFailed(error)),
    ),
  );
}

export async function unloadCargo(
  action: UnloadCargo,
): Promise<UnloadCargoSuccess> {
  const graphQLClient = await getClient();

  const { selectedConsignee, unloadingData } = action;
  const {
    vehicleForExtraction,
    isCargoUnloadByClient,
    warehouseForExtraction,
    cargoProductsName,
  } = unloadingData as any;

  const [consignee] = selectedConsignee;

  let input;

  if (vehicleForExtraction) {
    input = {
      id: consignee.id,
      vehicleToExtractCargo: {
        id: vehicleForExtraction.id,
        vehiclePlateNumber: vehicleForExtraction.vehiclePlateNumber,
        isCargoUnloadingByClient: isCargoUnloadByClient,
      },
    };
  } else {
    input = {
      id: consignee.id,
      warehouse: {
        id: warehouseForExtraction.id,
      },
    };
  }

  const {
    data: { updateConsignee },
  } = await graphQLClient.mutate({
    mutation: updateConsigneeMutation,
    variables: {
      input,
    },
  });

  return updateConsignee;
}
