// External dependencies
import update from 'immutability-helper';

// Local dependencies
import { ContractStatus } from './../../types';
import {
  Contract,
  ListContractsFilter,
} from '../../types';
import { ListContractsAction, ListContractsActionTypes } from './action';

export interface ListContractsState {
  currentPage: number;
  error?: Error;
  loading: boolean;
  contracts: Contract[];
  searchString: string;
  filter?: ListContractsFilter;
  size: number;
  total: number;
}

export const initialContractsState: ListContractsState = {
  currentPage: 1,
  filter: { status: ContractStatus.ACTIVE_CONTRACTS },
  loading: false,
  contracts: [],
  searchString: '',
  size: 20,
  total: 0,
};

export default function listContracts(
  state = initialContractsState,
  action: ListContractsAction,
) {
  switch (action.type) {
    case ListContractsActionTypes.LIST_CONTRACTS_REQUEST:
      const { currentPage, searchString, filter } = action;
      return update(state, {
        $unset: ['error'],
        loading: { $set: true },
        currentPage: { $set: currentPage },
        searchString: { $set: searchString },
        filter: { $set: filter },
      });

    case ListContractsActionTypes.LIST_CONTRACTS_SUCCESS:
      return update(state, {
        loading: { $set: false },
        contracts: { $set: action.contracts },
        total: { $set: action.total },
      });

    case ListContractsActionTypes.LIST_CONTRACTS_ERROR:
      return update(state, {
        error: { $set: action.error },
        loading: { $set: false },
      });
    case ListContractsActionTypes.RESET_LIST_CONTRACTS:
      return update(state, { $set: initialContractsState });

    case ListContractsActionTypes.RESET_ERROR_LIST_CONTRACTS:
      return update(state, {
        $unset: ['error'],
      });

    default:
      return state;
  }
}
