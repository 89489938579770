import { VehicleStatus } from '../../create-vehicle/vehicles_types';
import { Vehicle } from '../../vehicle-details/redux/actions';

export enum ListVehiclesActionTypes {
  LIST_VEHICLES_REQUEST = 'LIST_VEHICLE_REQUEST',
  LIST_VEHICLES_SUCCESS = 'LIST_VEHICLE_SUCCESS',
  LIST_VEHICLES_ERROR = 'LIST_VEHICLE_ERROR',
  SORT_VEHICLES_BY = 'SORT_VEHICLES_BY',
}

export enum SortByField {
  ARRIVALDATE = 'arrivalDate',
}

export enum SortDirection {
  ASC = 'ASC',
  DESC = 'DESC',
}

export interface ListVehicles {
  type: ListVehiclesActionTypes.LIST_VEHICLES_REQUEST;
  currentPage?: number;
  searchString?: string;
  filter?: ListVehiclesFilter;
  sort?: ListVehiclesSort;
}

export interface ListVehiclesSuccess {
  type: ListVehiclesActionTypes.LIST_VEHICLES_SUCCESS;
  total: number;
  vehicles: Vehicle[];
}

export interface ListVehiclesError {
  type: ListVehiclesActionTypes.LIST_VEHICLES_ERROR;
  error: Error;
}

export interface ListVehiclesSort {
  field?: string;
  direction?: SortDirection;
}

export interface ListVehiclesFilter {
  arrivalPurpose?: string[];
  status?: string[];
}

export type ListVehiclesAction =
  | ListVehicles
  | ListVehiclesSuccess
  | ListVehiclesError;

export const DEFAULT_VEHICLES_FILTER: ListVehiclesFilter = {
  status: [VehicleStatus.ARRIVED],
};

export const DEFAULT_VEHICLES_SORT: ListVehiclesSort = {
  field: SortByField.ARRIVALDATE,
  direction: SortDirection.DESC,
};

export function listVehicles(
  searchString: string = '',
  currentPage: number = 1,
  filter: ListVehiclesFilter = DEFAULT_VEHICLES_FILTER,
  sort: ListVehiclesSort = DEFAULT_VEHICLES_SORT,
): ListVehicles {
  return {
    type: ListVehiclesActionTypes.LIST_VEHICLES_REQUEST,
    searchString,
    currentPage,
    filter,
    sort,
  };
}

export function listVehiclesSucceeded(
  total: number,
  vehicles: Vehicle[],
): ListVehiclesSuccess {
  return {
    type: ListVehiclesActionTypes.LIST_VEHICLES_SUCCESS,
    total,
    vehicles,
  };
}

export function listVehiclesFailed(error: Error): ListVehiclesError {
  return {
    type: ListVehiclesActionTypes.LIST_VEHICLES_ERROR,
    error,
  };
}

// export function sortVehiclesBy(
//   field: string = SortByField.ARRIVALDATE,
//   direction: SortDirection,
// ): ListVehiclesSort {
//   return {
//     type: ListVehiclesActionTypes.SORT_VEHICLES_BY,
//     field,
//     direction,
//   };
// }
