/* External dependencies */
import update from 'immutability-helper';

/* Local dependencies */
import { validateVehicle } from '../../create-vehicle/redux/validateVehicle';
import { Device, DeviceType } from '../../devices/types';
import { Vehicle, VehicleAction, VehicleActionTypes } from './actions';

export interface VehicleState {
  isAdded?: boolean;
  actFormNumberError?: Error;
  arrivalDate: number | null;
  vehiclePlateNumber: string;
  borderCheckpointError?: Error;
  driverNameError?: Error;
  driverPhoneError?: Error;
  editableFields: string[];
  error?: Error;
  grossWeight?: number;
  grossWeightError?: Error;
  isFormChanged?: boolean;
  loading?: boolean;
  netWeightError?: Error;
  representativeNameError?: Error;
  representativePhoneError?: Error;
  representativeRoleError?: Error;
  saving?: boolean;
  device?: Device;
  statusError?: Error;
  tareWeightError?: Error;
  trailerPlateNumberError?: Error;
  vehicle?: Vehicle;
  vehiclePlateNumberError?: Error;
}

// sorted according to design
export const initialVehicleState: VehicleState = {
  arrivalDate: null,
  isAdded: false,
  editableFields: [],
  loading: true,
  vehiclePlateNumber: '',
  device: {
    id: '',
    data: '',
    type: DeviceType.SCALE,
  },
};

export default function vehicleReducer(
  state = initialVehicleState,
  action: VehicleAction,
) {
  switch (action.type) {
    case VehicleActionTypes.RESET_VEHICLE_DETAILS:
      return update(state, {
        $set: initialVehicleState,
      });

    case VehicleActionTypes.UPDATE_VEHICLE_REQUEST:
      return update(state, {
        $unset: ['error'],
        saving: { $set: true },
        isAdded: { $set: false },
      });

    case VehicleActionTypes.UPDATE_VEHICLE_SUCCESS:
      return update(state, {
        isFormChanged: { $set: false },
        saving: { $set: false },
        vehicle: { $set: action.vehicle },
        isAdded: { $set: true },
      });

    case VehicleActionTypes.UPDATE_VEHICLE_ERROR:
      return update(state, {
        error: { $set: action.error },
        saving: { $set: false },
      });

    case VehicleActionTypes.GET_VEHICLE_REQUEST:
      return update(state, {
        $unset: ['error'],
        editableFields: { $set: [] },
        isFormChanged: { $set: false },
        loading: { $set: true },
      });

    case VehicleActionTypes.GET_VEHICLE_SUCCESS:
      return update(state, {
        loading: { $set: false },
        vehicle: { $set: action.vehicle },
        editableFields: { $set: action.editableFields },
      });

    case VehicleActionTypes.GET_VEHICLE_ERROR:
      return update(state, {
        error: { $set: action.error },
        loading: { $set: false },
      });

    case VehicleActionTypes.UPDATE_VEHICLE_ADD_INFO:
      return validateVehicle(state, action.updates);

    case VehicleActionTypes.UPDATE_VEHICLE_RESET_ERROR:
      return update(state, {
        $unset: ['error'],
      });

    case VehicleActionTypes.GET_DEVICE_REQUEST:
      return update(state, {
        $unset: ['error'],
      });

    case VehicleActionTypes.GET_DEVICE_SUCCESS:
      const hasScalingStopped =
        Number(state.device?.data) === Number(action.device?.data);
      const newScale = hasScalingStopped && !!action.device.data;

      switch (action.device.type) {
        case DeviceType.SCALE: {
          return update(state, {
            loading: { $set: false },
            device: { $set: action.device },
            isFormChanged: {
              $set: hasScalingStopped,
            },
            vehicle: {
              tareWeight: {
                $set: newScale ? Number(action.device.data) : 0,
              },
            },
          });
        }
      }

    case VehicleActionTypes.GET_DEVICE_ERROR:
      return update(state, {
        error: { $set: action.error },
        loading: { $set: false },
      });

    case VehicleActionTypes.RESET_DEVICE:
      return update(state, {
        device: { $set: initialVehicleState.device },
      });

    default:
      return state;
  }
}
