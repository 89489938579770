/* External dependencies */
import update from 'immutability-helper';

/* Local dependencies */
import { PaymentDocumentType, PaymentType } from '../common/invoice-types';
import { UpdateInvoiceFields } from './actions';
import {
  ApplicationRequiredException,
  DeclarationRequiredException,
  PaymentTypeRequiredException,
} from './exceptions';

export function validateGetInvoice(state, updates: UpdateInvoiceFields) {
  const {
    documentType,
    declarationNumber,
    applicationNumber,
    paymentType,
    file,
  } = updates;
  const change: any = {};

  if (updates.hasOwnProperty('documentType')) {
    if (!documentType) {
      change.documentTypeError = { $set: new DeclarationRequiredException() };
    } else {
      change['$unset'] = ['documentTypeError'];
    }

    return update(state, {
      ...change,
      documentType: { $set: documentType },
      declarationNumber: { $set: '' },
      applicationNumber: { $set: '' },
    });
  }

  if (updates.hasOwnProperty('declarationNumber')) {
    if (!declarationNumber) {
      change.declarationNumberError = {
        $set: new DeclarationRequiredException(),
      };
    } else {
      change['$unset'] = ['declarationNumberError'];
    }

    const { value, mask } = validateDeclarationNumber(declarationNumber);

    return update(state, {
      ...change,
      declarationNumber: { $set: value },
      declarationMask: { $set: mask },
    });
  }

  if (updates.hasOwnProperty('applicationNumber')) {
    if (!applicationNumber) {
      change.applicationNumberError = {
        $set: new ApplicationRequiredException(),
      };
    } else {
      change['$unset'] = ['applicationNumberError'];
    }

    return update(state, {
      ...change,
      applicationNumber: { $set: applicationNumber },
    });
  }

  if (updates.hasOwnProperty('paymentType')) {
    if (!paymentType) {
      change.paymentTypeError = {
        $set: new PaymentTypeRequiredException(),
      };
    } else {
      change['$unset'] = ['paymentTypeError'];
    }

    return update(state, {
      ...change,
      paymentType: { $set: paymentType },
    });
  }
  if (updates.hasOwnProperty('file')) {
    return update(state, {
      ...change,
      file: { $set: file },
    });
  }

  return state;
}

export function getInvoiceFields(state, invoice, changes) {
  const documentType = invoice.applicationNumber
    ? PaymentDocumentType.APPLICATION
    : PaymentDocumentType.DECLARATION;
  const paymentType = invoice.paymentType || PaymentType.CASH;
  const applicationNumber = invoice.applicationNumber;
  const { value, mask } = validateDeclarationNumber(
    invoice.declarationNumber ? invoice.declarationNumber + '//' : '',
  );
  const file = invoice.file;

  return update(state, {
    ...changes,
    invoice: { $set: invoice },
    documentType: { $set: documentType },
    paymentType: { $set: paymentType },
    applicationNumber: { $set: applicationNumber },
    declarationNumber: { $set: value },
    declarationMask: { $set: mask },

    file: { $set: file },
  });
}

export const validateDeclarationNumber = (declarationNumber) => {
  let clean = declarationNumber.trim().replace(/[^\d]/g, '').substring(0, 21);

  let formattedNumber = '________/______/_______';

  if (declarationNumber.length < 23) {
    clean = clean.slice(0, -1);
  }

  let len = clean.length;

  switch (true) {
    case len <= 8:
      formattedNumber = `${clean}${formattedNumber.substring(clean.length)}`;
      break;

    case len > 8 && len <= 14:
      formattedNumber = `${clean.substring(0, 8)}/${clean.substring(
        8,
      )}${formattedNumber.substring(clean.length + 1)}`;
      break;

    default:
      formattedNumber = `${clean.substring(0, 8)}/${clean.substring(
        8,
        14,
      )}/${clean.substring(14)}${formattedNumber.substring(clean.length + 2)}`;
  }

  return {
    value: clean,
    mask: formattedNumber,
  };
};
