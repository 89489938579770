/* External dependencies */
import gql from 'graphql-tag';

export const getInvoiceQuery = gql`
  query getInvoice($input: GetInvoiceInput!) {
    getInvoice(input: $input) {
      applicationNumber
      consignee {
        id
        arrivalDate
        cargoWeight
        name
      }
      declarationNumber
      documentType
      file
      id
      events {
        consignee {
          id
        }
        eventDate
        eventType
        sum
        unit
        vehicle {
          id
        }
      }
      paymentCurrency
      paymentDate
      paymentStatus
      paymentAmount
      paymentType
      vehicle {
        id
        arrivalDate
        driver {
          name
        }
      }
    }
  }
`;
