/* Local dependencies */
import { UIException } from '../../../redux/exceptions';

export class CargoNetWeightRequiredException extends UIException {
  code = 'cargoNetWeightError';
}

export class CargoNetWeightTypeRequiredException extends UIException {
  code = 'cargoNetWeightTypeError';
}

export class CargoEscortRequiredException extends UIException {
  code = 'cargoEscortError';
}

export class CargoSlotsRequiredException extends UIException {
  code = 'cargoSlotsError';
}

export class DepartureCountryRequiredException extends UIException {
  code = 'DepartureCountryRequiredException';
}

export class nameRequiredException extends UIException {
  code = 'consigneeNameError';
}

export class slotsRequiredException extends UIException {
  code = 'slotsError';
}

export class consigneesNetWeightException extends UIException {
  code = 'consigneesNetWeightError';
}

export class consigneesSlotsException extends UIException {
  code = 'consigneesSlotsError';
}
