// External dependencies
import { debounceTime, filter, switchMap } from 'rxjs/operators';

// Local depedencies
import { getClient } from '../../../../clients/bts';
import {
  ListContracts,
  ListContractsAction,
  ListContractsActionTypes,
  listContractsFailed,
  listContractsSucceeded,
  ListContractsSuccess,
} from './action';
import { listContractsQuery } from './queries';

export default function listContractsEpic(action$, state$) {
  return action$.pipe(
    filter(
      (action: ListContractsAction) =>
        action.type === ListContractsActionTypes.LIST_CONTRACTS_REQUEST,
    ),
    debounceTime(500),
    switchMap((action: ListContracts) =>
      listContracts(action, state$?.value?.listContracts?.size).catch((error) =>
        listContractsFailed(error),
      ),
    ),
  );
}

export async function listContracts(
  { currentPage, searchString, filter }: ListContracts,
  size: number,
): Promise<ListContractsSuccess> {
  const graphQLClient = await getClient();
  const {
    data: {
      listContracts: { contracts, total },
    },
  } = await graphQLClient.query({
    query: listContractsQuery,
    variables: {
      input: {
        from: (currentPage - 1) * size,
        query: searchString,
        size,
        filter,
      },
    },
  });

  return listContractsSucceeded(total, contracts);
}
