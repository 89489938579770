/* External dependencies */
import { of } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';

/* Local dependencies */
import { getClient } from '../../../clients/bts';
import { Consignee } from '../../create-cargo/redux/actions';
import {
  ConsigneeAction,
  ConsigneeActionTypes,
  GetConsigneeRequest,
  getConsigneeFailed,
  getConsigneeSucceeded,
} from './actions';
import { consigneeDetailQuery } from './queries';

export default function consigneeEpic(action$, state$) {
  return action$.pipe(
    filter(
      (action: ConsigneeAction) =>
        action.type === ConsigneeActionTypes.GET_CONSIGNEE_REQUEST,
    ),
    switchMap((action: GetConsigneeRequest) =>
      getConsignee(action)
        .then((consignee: Consignee) => {
          return getConsigneeSucceeded(consignee);
        })
        .catch((error) => of(getConsigneeFailed(error))),
    ),
  );
}

export async function getConsignee({
  id,
}: GetConsigneeRequest): Promise<Consignee> {
  const graphQLClient = await getClient();

  const {
    data: { getConsignee },
  } = await graphQLClient.query({
    query: consigneeDetailQuery,
    variables: {
      input: {
        id,
      },
    },
  });

  return getConsignee;
}
