/* Local dependencies */
import { UIException } from '../../../redux/exceptions';

export class ActFormNumberRequiredException extends UIException {
  code = 'ActFormNumberRequiredException';
}

export class ArrivalPurposeRequiredException extends UIException {
  code = 'ArrivalPurposeRequiredException';
}

export class BorderCheckpointRequiredException extends UIException {
  code = 'BorderCheckpointRequiredException';
}
export class DriverRequiredException extends UIException {
  code = 'DriverRequiredException';
}

export class GrossWeightRequiredException extends UIException {
  code = 'GrossWeightRequiredException';
}

export class GrossWeightInvalidException extends UIException {
  code = 'GrossWeightInvalidException';
}

export class NetWeightRequiredException extends UIException {
  code = 'NetWeightRequiredException';
}

export class RepresentativeRequiredException extends UIException {
  code = 'RepresentativeRequiredException';
}

export class StatusInvalidException extends UIException {
  code = 'StatusInvalidException';
}

export class TareWeightRequiredException extends UIException {
  code = 'TareWeightRequiredException';
}

export class TrailerPlateNumberRequiredException extends UIException {
  code = 'TrailerPlateNumberRequiredException';
}

export class VehiclePlateNumberRequiredException extends UIException {
  code = 'VehiclePlateNumberRequiredException';
}

export class WeightTypeRequiredException extends UIException {
  code = 'WeightTypeRequiredException';
}

export class DuplicateVehicleException extends UIException {
  code = 'DuplicateVehicleException';
}

export class UserPhoneNumberInvalidException extends UIException {
  code = 'UserPhoneNumberInvalidException';
}

export class TareDateInvalidException extends UIException {
  code = 'TareDateInvalidExpection';
}

export class NewVehicleMustTareError extends UIException {
  code = 'NewVehicleMustTareError';
}
