/* External dependencies */
import gql from 'graphql-tag';

export const updateContractMutation = gql`
  mutation updateContract($input: UpdateContractInput!) {
    updateContract(input: $input) {
      id
      type
      consigneeProfile {
        id
        name
      }
      endDate
      startDate
      consigneeRepresentative {
        country
        city
        street
        INN
        OKPO
        bankName
        BIK
        bankAccount
        consigneeHeadFullName
        consigneeHeadPosition
        consigneeType
        consigneeHeadTrustee {
          fullName
          trusteeDocument
        }
      }
      tariffs {
        id
        name
        fields {
          id
          name
          value
          unit
          eventType
        }
      }
    }
  }
`;
