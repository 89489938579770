/* Local dependencies */
import { Consignee } from '../../../create-cargo/redux/actions';
import { Vehicle } from '../../../create-vehicle/redux/actions';
import { Warehouse } from '../../../warehouses/types';

export enum UnloadCargoActionTypes {
  CARGO_UNLOADING_REQUEST = 'CARGO_UNLOADING_REQUEST',
  CARGO_UNLOADING_SUCCESS = 'CARGO_UNLOADING_SUCCESS',
  CARGO_UNLOADING_ERROR = 'CARGO_UNLOADING_ERROR',
  CARGO_UNLOADING_BY_CLIENT = 'CARGO_UNLOADING_BY_CLIENT',
  CARGO_UNLOADING_RESET = 'CARGO_UNLOADING_RESET',
  CARGO_UNLOADING_RESET_ERROR = 'CARGO_UNLOADING_RESET_ERROR',
  SELECTED_CONSIGNEE = 'SELECTED_CONSIGNEE',
  CARGO_PRODUCTS_NAME = 'CARGO_PRODUCTS_NAME',
}

interface UnloadingVehicleData {
  vehicleForExtraction: Vehicle;
  isCargoUnloadByClient: boolean;
}
interface UnloadingWarehouseData {
  warehouseForExtraction: Warehouse;
  cargoProductsName: string;
}

export interface UnloadCargo {
  type: UnloadCargoActionTypes.CARGO_UNLOADING_REQUEST;
  selectedConsignee: Consignee[];
  unloadingData: UnloadingVehicleData | UnloadingWarehouseData;
}

export interface UnloadCargoSuccess {
  type: UnloadCargoActionTypes.CARGO_UNLOADING_SUCCESS;
  unloadedConsignee: Array<any>;
}

export interface UnloadCargoError {
  type: UnloadCargoActionTypes.CARGO_UNLOADING_ERROR;
  error: Error;
}

export interface UnloadCargoByClient {
  type: UnloadCargoActionTypes.CARGO_UNLOADING_BY_CLIENT;
  isCargoUnloadingByClient: boolean;
}
export interface ChangeCargoProductsName {
  type: UnloadCargoActionTypes.CARGO_PRODUCTS_NAME;
  cargoProductsName: string;
}

export interface UnloadCargoReset {
  type: UnloadCargoActionTypes.CARGO_UNLOADING_RESET;
}

export interface UnloadCargoResetError {
  type: UnloadCargoActionTypes.CARGO_UNLOADING_RESET_ERROR;
}

export type UnloadCargoAction =
  | UnloadCargo
  | UnloadCargoSuccess
  | UnloadCargoError
  | UnloadCargoByClient
  | ChangeCargoProductsName
  | UnloadCargoReset
  | UnloadCargoResetError;

export function unloadCargo(
  selectedConsignee: Consignee[],
  unloadingData: UnloadingVehicleData | UnloadingWarehouseData,
): UnloadCargo {
  return {
    type: UnloadCargoActionTypes.CARGO_UNLOADING_REQUEST,
    selectedConsignee,
    unloadingData,
  };
}

export function unloadCargoSucceeded(
  unloadedConsignee: Array<any>,
): UnloadCargoSuccess {
  return {
    type: UnloadCargoActionTypes.CARGO_UNLOADING_SUCCESS,
    unloadedConsignee,
  };
}

export function unloadCargoFailed(error: Error): UnloadCargoError {
  return {
    type: UnloadCargoActionTypes.CARGO_UNLOADING_ERROR,
    error,
  };
}

export function onUnloadCargoByClient(
  isCargoUnloadingByClient: boolean,
): UnloadCargoByClient {
  return {
    type: UnloadCargoActionTypes.CARGO_UNLOADING_BY_CLIENT,
    isCargoUnloadingByClient,
  };
}
export function changeCargoProductsName(
  cargoProductsName: string,
): ChangeCargoProductsName {
  return {
    type: UnloadCargoActionTypes.CARGO_PRODUCTS_NAME,
    cargoProductsName,
  };
}

export function resetUnloadCargo(): UnloadCargoReset {
  return {
    type: UnloadCargoActionTypes.CARGO_UNLOADING_RESET,
  };
}

export function resetError(): UnloadCargoResetError {
  return {
    type: UnloadCargoActionTypes.CARGO_UNLOADING_RESET_ERROR,
  };
}
