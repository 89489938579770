// Local dependencies
import { transformTariffs } from '../../helper';
import { Contract, CreateContractInput } from './types';

export enum CreateContractTypes {
  CREATE_CONTRACT_REQUEST = 'CREATE_CONTRACT_REQUEST',
  CREATE_CONTRACT_SUCCESS = 'CREATE_CONTRACT_SUCCESS',
  UPDATE_CREATE_CONTRACT_FIELDS = 'UPDATE_CREATE_CONTRACT_FIELDS',
  CREATE_CONTRACT_ERROR = 'CREATE_CONTRACT_ERROR',
  RESET_CREATE_CONTRACT = 'RESET_CREATE_CONTRACT',
  RESET_CREATE_CONTRACT_ERROR = 'RESET_CREATE_CONTRACT_ERROR',
  OPEN_SHOW_POPUP = 'OPEN_SHOW_POPUP,',
}

export type CreateContractAction =
  | CreateContractRequest
  | CreateContractSuccess
  | CreateContractError
  | ResetCreateContract
  | ResetError
  | OpenShowPopup
  | UpdateCreacteContractFields;

export interface CreateContractRequest {
  type: CreateContractTypes.CREATE_CONTRACT_REQUEST;
  contract: CreateContractInput;
}

export interface CreateContractSuccess {
  type: CreateContractTypes.CREATE_CONTRACT_SUCCESS;
  contract: Contract;
}

export interface CreateContractError {
  type: CreateContractTypes.CREATE_CONTRACT_ERROR;
  error: Error;
}

export interface UpdateCreacteContractFields {
  type: CreateContractTypes.UPDATE_CREATE_CONTRACT_FIELDS;
  updates;
}

export interface ResetCreateContract {
  type: CreateContractTypes.RESET_CREATE_CONTRACT;
}

export interface OpenShowPopup {
  type: CreateContractTypes.OPEN_SHOW_POPUP;
  value: boolean;
}

export interface ResetError {
  type: CreateContractTypes.RESET_CREATE_CONTRACT_ERROR;
}

export function updateCreateContractFields(
  updates,
): UpdateCreacteContractFields {
  return {
    type: CreateContractTypes.UPDATE_CREATE_CONTRACT_FIELDS,
    updates,
  };
}

export function createContractRequest(
  contract: CreateContractInput,
): CreateContractRequest {
  const newTarifs = transformTariffs(contract, true);
  const newContract = { ...contract, tariffs: newTarifs };

  return {
    type: CreateContractTypes.CREATE_CONTRACT_REQUEST,
    contract: newContract as any,
  };
}

export function createContractSucceeded(
  contract: Contract,
): CreateContractSuccess {
  return {
    type: CreateContractTypes.CREATE_CONTRACT_SUCCESS,
    contract,
  };
}

export function createContractFailed(error: Error): CreateContractError {
  return {
    type: CreateContractTypes.CREATE_CONTRACT_ERROR,
    error,
  };
}

export function resetCreateContract(): ResetCreateContract {
  return {
    type: CreateContractTypes.RESET_CREATE_CONTRACT,
  };
}

export function openShowPopup(value: boolean): OpenShowPopup {
  return {
    type: CreateContractTypes.OPEN_SHOW_POPUP,
    value,
  };
}

export function resetError(): ResetError {
  return {
    type: CreateContractTypes.RESET_CREATE_CONTRACT_ERROR,
  };
}
