/* Local dependencies */
import formatMessage from '../../../intl/formatMessage';
import renderDate from '../../common/renderDate';
import getFullName from '../../common/renderName';

export function getOperatorFields() {
  return [
    {
      label: 'arrivalDate',
      value: (vehicle) => renderDate(vehicle.arrivalDate),
      placeholder: 'arrivalDate',
    },
    {
      label: 'vehiclePlateNumber',
      placeholder: 'vehiclePlateNumberPlaceholder',
    },
    {
      label: 'trailerPlateNumber',
      placeholder: 'trailerPlateNumberPlaceholder',
    },
    {
      label: 'arrivalPurpose',
      placeholder: 'arrivalPurpose',
      value: (vehicle) => formatMessage(vehicle.arrivalPurpose),
    },
    {
      label: 'weightType',
      placeholder: 'weightType',
      value: (vehicle) => formatMessage(vehicle.weightType),
    },
    {
      label: 'grossWeight',
      placeholder: 'grossWeight',
    },
    // {
    //   label: 'tareWeight',
    //   placeholder: 'tareWeight',
    //   isCheckbox: true,
    // },
    {
      label: 'driver',
      hasSeparator: true,
      placeholder: 'driverPlaceholder',
      value: (vehicle) => vehicle.driver.name,
    },
    {
      label: 'driverPhone',
      placeholder: 'driverPhonePlaceholder',
      value: (vehicle) => vehicle.driver.phone,
    },
    {
      label: 'comment',
      placeholder: 'enterComment',
      value: (vehicle) => vehicle.comment,
    },
    {
      label: 'actFormNumber',
      placeholder: 'actFormNumberPlaceholder',
    },
    {
      label: 'borderCheckpoint',
      isDropdownCapable: true,
      placeholder: 'borderCheckpointPlaceholder',
      value: (vehicle) => vehicle.borderCheckpoint,
    },
    {
      label: 'entryRegistered',
      hasSeparator: true,
      placeholder: 'entryRegistered',
      value: (vehicle) =>
        getFullName(vehicle.operator.firstName, vehicle.operator.lastName),
    },
  ];
}

export function getOperatorEditableFields() {
  return [];
}
