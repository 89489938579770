/* External dependencies */
import update from 'immutability-helper';

/* Local dependencies */
import {
  CreateCargoInput,
  UpdateCargoFields,
  createCargoFailed,
} from './../../create-cargo/redux/actions';
import {
  CargoNetWeightRequiredException,
  CargoSlotsRequiredException,
  CargoEscortRequiredException,
  nameRequiredException,
  DepartureCountryRequiredException,
  consigneesNetWeightException,
  consigneesSlotsException,
} from './../../create-cargo/redux/exceptions';
import { COUNTRY_NAMES } from '../../countries/countryNames';

export function validateCargo(state, updates: UpdateCargoFields) {
  const { cargoNetWeight, cargoEscort, cargoSlots, departureCountry } = updates;

  const change: any = {};

  if (updates.hasOwnProperty('cargoNetWeight')) {
    const netWeight = parseFloat(cargoNetWeight);

    if (cargoNetWeight <= 0) {
      change.cargoNetWeightError = {
        $set: new CargoNetWeightRequiredException(),
      };
    } else {
      change['$unset'] = ['cargoNetWeightError'];
    }

    return update(state, {
      ...change,
      cargo: {
        $set: update(state.cargo, {
          cargoNetWeight: { $set: netWeight },
        }),
      },
    });
  }

  if (updates.hasOwnProperty('cargoSlots')) {
    const cargoSeats = parseInt(cargoSlots, 10);

    if (cargoSlots <= 0) {
      change.cargoSlotsError = {
        $set: new CargoSlotsRequiredException(),
      };
    } else {
      change['$unset'] = ['cargoSlotsError'];
    }

    return update(state, {
      ...change,
      cargo: {
        $set: update(state.cargo, {
          cargoSlots: { $set: cargoSeats },
        }),
      },
    });
  }

  if (updates.hasOwnProperty('cargoEscort')) {
    if (!cargoEscort) {
      change.cargoEscortError = {
        $set: new CargoEscortRequiredException(),
      };
    } else {
      change['$unset'] = ['cargoEscortError'];
    }

    return update(state, {
      ...change,
      cargo: {
        $set: update(state.cargo, {
          cargoEscort: { $set: cargoEscort },
        }),
      },
    });
  }

  if (updates.hasOwnProperty('consignees')) {
    // Keep only those rows which have at least partial values.
    let consignees = updates.consignees?.filter(
      ({ cargoWeight, name, slots, departureCountry, products, profileId }) => {
        // Remove rows which are completely empty.
        return !(
          !cargoWeight &&
          !departureCountry &&
          !products &&
          !profileId &&
          !name &&
          !slots
        );
      },
    );

    let consigneesError = false;

    for (const item of consignees!) {
      if (
        !item.name ||
        !item.cargoWeight ||
        !item.slots ||
        !item.products ||
        !item.departureCountry
      ) {
        consigneesError = true;
        break;
      }
    }

    if (consigneesError || !consignees?.length) {
      change.consigneeNameError = {
        $set: new nameRequiredException(),
      };
    } else {
      consigneesError = false;
      change['$unset'] = ['consigneeNameError'];
    }

    return update(state, {
      ...change,
      cargo: {
        $set: update(state.cargo, {
          consignees: {
            $set: consignees,
          },
        }),
      },
    });
  }

  if (updates.hasOwnProperty('departureCountry')) {
    if (!departureCountry || !COUNTRY_NAMES.includes(departureCountry)) {
      change.departureCountryError = {
        $set: new DepartureCountryRequiredException(),
      };
    } else {
      change['$unset'] = ['departureCountryError'];
    }

    return update(state, {
      ...change,
      vehicle: {
        $set: update(state.vehicle, {
          departureCountry: { $set: departureCountry },
        }),
      },
    });
  }

  return state;
}

export function validateCargoNetWeightAndSlots(
  cargo: CreateCargoInput,
): Error | false {
  const { cargoNetWeight, cargoSlots, consignees } = cargo;

  const { totalNetWeight, totalSlots } = consignees.reduce(
    (acc, item) => ({
      totalNetWeight: acc.totalNetWeight + +item.cargoWeight,
      totalSlots: acc.totalSlots + +item.slots,
    }),
    { totalNetWeight: 0, totalSlots: 0 },
  );

  if (totalSlots > cargoSlots) {
    return new consigneesSlotsException();
  }

  if (totalNetWeight > cargoNetWeight) {
    return new consigneesNetWeightException();
  }

  return false;
}
