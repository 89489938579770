// Local dependencies
import {
  ConsigneeType,
  Contract,
  ContractType,
  EventType,
  PriceUnit,
} from './redux/types';

export function isActiveContractFields(contract) {
  const isIndividual =
    contract?.consigneeRepresentative?.consigneeType ===
    ConsigneeType.INDIVIDUAL;
  const isActive =
    !!contract?.consigneeProfile && isIndividual
      ? contract?.consigneeProfile.name
      : !!contract?.consigneeRepresentative?.consigneeHeadFullName &&
        !!contract?.consigneeRepresentative?.country &&
        !!contract?.consigneeRepresentative?.city &&
        !!contract?.consigneeRepresentative?.street &&
        !!(
          new Date(contract?.endDate).getDay() !==
          new Date(contract?.startDate).getDay()
        );

  return isActive;
}

export function getValidateField(contract: Contract, type: ContractType) {
  const isIndividual =
    contract?.consigneeRepresentative?.consigneeType ===
    ConsigneeType.INDIVIDUAL;
  const data = [
    {
      name: isIndividual ? 'individual' : 'organization',
      value: contract?.consigneeProfile || '',
    },
    {
      name: 'consigneeHeadFullName',
      value: isIndividual
        ? contract?.consigneeProfile?.name
        : contract?.consigneeRepresentative?.consigneeHeadFullName || '',
    },
    {
      name: 'country',
      value: contract?.consigneeRepresentative?.country || '',
    },
    {
      name: 'city',
      value: contract?.consigneeRepresentative?.city || '',
    },
    {
      name: 'street',
      value: contract?.consigneeRepresentative?.street || '',
    },
  ];

  if (type !== ContractType.CUSTOMS_WAREHOUSE) {
    return [
      ...data,
      {
        name: 'endDate',
        value: contract?.endDate || new Date(contract?.endDate).getTime(),
      },
    ];
  } else {
    return data;
  }
}

export function dynamicFields(contract, includeId = true) {
  if (!contract || !contract.tariffs) {
    return [];
  }

  return contract.tariffs.map((tariff) => {
    if (!tariff || !tariff.fields) {
      return { name: tariff?.name, fields: [] };
    }

    const fields = tariff.fields.reduce((acc, field) => {
      if (!field) {
        return acc;
      }

      const match = acc.find(
        (f) => f.name === field.name && f.eventType === field.eventType,
      );

      if (match) {
        match.values.push({
          value: field.value,
          unit: field.unit,
          ...(includeId ? { id: field?.id } : {}),
        });
      } else {
        acc.push({
          name: field.name,
          eventType: field.eventType,
          values: [{ value: field.value, unit: field.unit }],
          ...(includeId ? { id: field?.id } : {}),
        });
      }

      return acc;
    }, []);

    return {
      name: tariff.name,
      fields,
      ...(includeId ? { id: tariff.id } : {}),
    };
  });
}

export function removeIDFromTariffs(tariffs) {
  return tariffs.map((tariff) => {
    const fields = tariff.fields.map((field) => ({
      ...field,
      id: undefined,
    }));
    return {
      ...tariff,
      fields,
      id: undefined,
    };
  });
}

export const customWareHousestate = {
  consigneeProfile: null,
  startDate: new Date().getTime(),
  tariffs: [
    {
      name: 'default',
      fields: [
        {
          name: '',
          eventType: EventType.UNLOAD_TO_WAREHOUSE,
          values: [
            {
              value: '',
              unit: PriceUnit.SOM_DAY,
            },
          ],
        },
      ],
    },
  ],
  consigneeRepresentative: {
    consigneeHeadFullName: '',
    consigneeHeadPosition: '',
    country: '',
    city: '',
    street: '',
    INN: '',
    OKPO: '',
    bankName: '',
    BIK: '',
    bankAccount: '',
    consigneeHeadTrustee: {
      fullName: '',
      trusteeDocument: '',
    },
    consigneeType: ConsigneeType.COMPANY,
  },
};

export function removeTariffId(tariff: any) {
  if (!tariff || !tariff.length) return [];

  return tariff.map(({ id, fields, ...rest }) => ({
    ...rest,
    fields: fields.map((field) => {
      const { id, ...fieldWithoutId } = field;
      return fieldWithoutId;
    }),
  }));
}
