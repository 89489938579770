// External dependencies
import update from 'immutability-helper';
import moment from 'moment';

// Local dependenceis
import { GetContractState } from './../../getContract/redux/reducer';
import {
  CityException,
  ContractFieldNameException,
  ContractFieldValueException,
  ContractFieldEventTypeException,
  ConsigneeHeadFullNameException,
  ConsigneeProfileNameIndividualException,
  ConsigneeProfileNameOrganizationException,
  CountryException,
  EndDateException,
  EndDateRequiredException,
  StartDateException,
  StreetException,
  DeactivationReasonException,
} from './exceptions';
import { CreateContractState } from './reducer';
import { CreateContractInput } from './types';

export function validateContractsfields(
  state: CreateContractState | GetContractState,
  updates: CreateContractInput,
) {
  const {
    organization,
    consigneeHeadFullName,
    consigneeHeadPosition,
    country,
    city,
    street,
    INN,
    OKPO,
    bankName,
    BIK,
    bankAccount,
    headTrusteeName,
    trusteeDocument,
    startDate,
    endDate,
    individual,
    isFormChanged,
    contractType,
    TEMPORARY_STORAGE,
    addFields,
    removeFields,
    fieldName,
    fieldValue,
    fieldUnit,
    addFieldsItem,
    removeItemField,
    eventType,
    consigneeType,
    CUSTOMS_DECLARATION,
    CUSTOMS_WAREHOUSE,
    TYPICAL_CONTRACT,
    addTariff,
    tariffName,
    removeTariff,
    updateTariff,
    deactivationReason,
  } = updates;

  const change: any = {
    isFormChanged: {
      $set: isFormChanged === undefined ? true : isFormChanged,
    },
  };

  if (updates.hasOwnProperty('organization')) {
    if (!organization.name) {
      change.organizationError = {
        $set: new ConsigneeProfileNameOrganizationException(),
      };
    } else {
      change['$unset'] = ['organizationError'];
    }

    return update(state, {
      ...change,
      contract: {
        $set: update(state.contract, {
          consigneeProfile: { $set: organization },
        }),
      },
    });
  }

  if (updates.hasOwnProperty('individual')) {
    if (!individual.name) {
      change.individualError = {
        $set: new ConsigneeProfileNameIndividualException(),
      };
    } else {
      change['$unset'] = ['individualError'];
    }

    return update(state, {
      ...change,
      contract: {
        $set: update(state.contract, {
          consigneeProfile: { $set: individual },
        }),
      },
    });
  }

  if (updates.hasOwnProperty('consigneeHeadFullName')) {
    if (!consigneeHeadFullName) {
      change.consigneeHeadFullNameError = {
        $set: new ConsigneeHeadFullNameException(),
      };
    } else {
      change['$unset'] = ['consigneeHeadFullNameError'];
    }

    return update(state, {
      ...change,
      contract: {
        $set: update(state.contract, {
          consigneeRepresentative: {
            $set: update(state.contract?.consigneeRepresentative, {
              consigneeHeadFullName: { $set: consigneeHeadFullName },
            }),
          },
        }),
      },
    });
  }

  if (updates.hasOwnProperty('consigneeHeadPosition')) {
    if (!consigneeHeadPosition) {
      change.consigneeHeadPosition = {
        $set: new Error(),
      };
    } else {
      change['$unset'] = ['consigneeHeadPosition'];
    }

    return update(state, {
      ...change,
      contract: {
        $set: update(state.contract, {
          consigneeRepresentative: {
            $set: update(state.contract?.consigneeRepresentative, {
              consigneeHeadPosition: { $set: consigneeHeadPosition },
            }),
          },
        }),
      },
    });
  }

  if (updates.hasOwnProperty('deactivationReason')) {
    if (!deactivationReason) {
      change.deactivationReasonError = {
        $set: new DeactivationReasonException(),
      };
    } else {
      change['$unset'] = ['deactivationReasonError'];
    }

    return update(state, {
      ...change,
      contract: {
        $set: update(state.contract, {
          contractEndReason: { $set: deactivationReason },
        }),
      },
    });
  }

  if (updates.hasOwnProperty('country')) {
    if (!country) {
      change.countryError = {
        $set: new CountryException(),
      };
    } else {
      change['$unset'] = ['countryError'];
    }

    return update(state, {
      ...change,
      contract: {
        $set: update(state.contract, {
          consigneeRepresentative: {
            $set: update(state.contract?.consigneeRepresentative, {
              country: { $set: country },
            }),
          },
        }),
      },
    });
  }

  if (updates.hasOwnProperty('city')) {
    if (!city) {
      change.cityError = {
        $set: new CityException(),
      };
    } else {
      change['$unset'] = ['cityError'];
    }

    return update(state, {
      ...change,
      contract: {
        $set: update(state.contract, {
          consigneeRepresentative: {
            $set: update(state.contract?.consigneeRepresentative, {
              city: { $set: city },
            }),
          },
        }),
      },
    });
  }

  if (updates.hasOwnProperty('street')) {
    if (!street) {
      change.streetError = {
        $set: new StreetException(),
      };
    } else {
      change['$unset'] = ['streetError'];
    }

    return update(state, {
      ...change,
      contract: {
        $set: update(state.contract, {
          consigneeRepresentative: {
            $set: update(state.contract?.consigneeRepresentative, {
              street: { $set: street },
            }),
          },
        }),
      },
    });
  }

  if (updates.hasOwnProperty('INN')) {
    if (!INN) {
      change.INN = {
        $set: new Error(),
      };
    } else {
      change['$unset'] = ['INN'];
    }

    return update(state, {
      ...change,
      contract: {
        $set: update(state.contract, {
          consigneeRepresentative: {
            $set: update(state.contract?.consigneeRepresentative, {
              INN: { $set: INN },
            }),
          },
        }),
      },
    });
  }

  if (updates.hasOwnProperty('OKPO')) {
    if (!OKPO) {
      change.OKPO = {
        $set: new Error(),
      };
    } else {
      change['$unset'] = ['OKPO'];
    }

    return update(state, {
      ...change,
      contract: {
        $set: update(state.contract, {
          consigneeRepresentative: {
            $set: update(state.contract?.consigneeRepresentative, {
              OKPO: { $set: OKPO },
            }),
          },
        }),
      },
    });
  }

  if (updates.hasOwnProperty('bankName')) {
    if (!bankName) {
      change.bankName = {
        $set: new Error(),
      };
    } else {
      change['$unset'] = ['bankName'];
    }

    return update(state, {
      ...change,
      contract: {
        $set: update(state.contract, {
          consigneeRepresentative: {
            $set: update(state.contract?.consigneeRepresentative, {
              bankName: { $set: bankName },
            }),
          },
        }),
      },
    });
  }

  if (updates.hasOwnProperty('BIK')) {
    if (!BIK) {
      change.BIK = {
        $set: new Error(),
      };
    } else {
      change['$unset'] = ['BIK'];
    }

    return update(state, {
      ...change,
      contract: {
        $set: update(state.contract, {
          consigneeRepresentative: {
            $set: update(state.contract?.consigneeRepresentative, {
              BIK: { $set: BIK },
            }),
          },
        }),
      },
    });
  }

  if (updates.hasOwnProperty('bankAccount')) {
    if (!bankAccount) {
      change.bankAccount = {
        $set: new Error(),
      };
    } else {
      change['$unset'] = ['bankAccount'];
    }

    return update(state, {
      ...change,
      contract: {
        $set: update(state.contract, {
          consigneeRepresentative: {
            $set: update(state.contract?.consigneeRepresentative, {
              bankAccount: { $set: bankAccount },
            }),
          },
        }),
      },
    });
  }

  if (updates.hasOwnProperty('consigneeType')) {
    if (!consigneeType) {
      change.consigneeType = {
        $set: new Error(),
      };
    } else {
      change['$unset'] = ['consigneeType'];
    }

    return update(state, {
      ...change,
      contract: {
        $set: update(state.contract, {
          consigneeRepresentative: {
            $set: update(state.contract?.consigneeRepresentative, {
              consigneeType: { $set: consigneeType },
            }),
          },
        }),
      },
    });
  }

  if (updates.hasOwnProperty('headTrusteeName')) {
    if (!headTrusteeName) {
      change.headTrusteeName = {
        $set: new Error(),
      };
    } else {
      change['$unset'] = ['headTrusteeName'];
    }

    return update(state, {
      ...change,
      contract: {
        $set: update(state.contract, {
          consigneeRepresentative: {
            $set: update(state.contract?.consigneeRepresentative, {
              consigneeHeadTrustee: {
                $set: update(
                  state.contract?.consigneeRepresentative?.consigneeHeadTrustee,
                  {
                    fullName: { $set: headTrusteeName },
                  },
                ),
              },
            }),
          },
        }),
      },
    });
  }

  if (updates.hasOwnProperty('trusteeDocument')) {
    if (!trusteeDocument) {
      change.trusteeDocument = {
        $set: new Error(),
      };
    } else {
      change['$unset'] = ['trusteeDocument'];
    }

    return update(state, {
      ...change,
      contract: {
        $set: update(state.contract, {
          consigneeRepresentative: {
            $set: update(state.contract?.consigneeRepresentative, {
              consigneeHeadTrustee: {
                $set: update(
                  state.contract?.consigneeRepresentative?.consigneeHeadTrustee,
                  {
                    trusteeDocument: { $set: trusteeDocument },
                  },
                ),
              },
            }),
          },
        }),
      },
    });
  }

  if (updates.hasOwnProperty('startDate')) {
    if (startDate > state?.contract?.endDate) {
      change.startDateError = {
        $set: new StartDateException(),
      };
    } else {
      change['$unset'] = ['startDateError'];
    }

    return update(state, {
      ...change,
      contract: {
        $set: update(state.contract, {
          startDate: { $set: startDate },
        }),
      },
    });
  }

  if (updates.hasOwnProperty('endDate')) {
    if (endDate < state?.contract?.startDate) {
      change.endDateError = {
        $set: new EndDateException(),
      };
    } else if (
      moment(endDate).day() === moment(state?.contract?.startDate).day() &&
      moment(endDate).week() === moment(state?.contract?.startDate).week()
    ) {
      change.endDateError = {
        $set: new EndDateRequiredException(),
      };
    } else {
      change['$unset'] = ['endDateError'];
    }

    return update(state, {
      ...change,
      contract: {
        $set: update(state.contract, {
          endDate: { $set: endDate },
        }),
      },
    });
  }

  if (updates.hasOwnProperty('contractType')) {
    if (!contractType) {
      change.contractType = {
        $set: new Error(),
      };
    } else {
      change['$unset'] = ['contractType'];
    }

    return update(state, {
      ...change,
      contract: {
        $set: update(state.contract, {
          type: { $set: contractType },
        }),
      },
    });
  }

  if (updates.hasOwnProperty('TEMPORARY_STORAGE')) {
    if (!TEMPORARY_STORAGE) {
      change.TEMPORARY_STORAGE = {
        $set: new Error(),
      };
    } else {
      change['$unset'] = ['TEMPORARY_STORAGE'];
    }

    return update(state, {
      ...change,
      contract: {
        $set: update(state.contract, {
          tariffs: { $set: TEMPORARY_STORAGE },
        }),
      },
    });
  }

  if (updates.hasOwnProperty('CUSTOMS_DECLARATION')) {
    if (!CUSTOMS_DECLARATION) {
      change.CUSTOMS_DECLARATION = {
        $set: new Error(),
      };
    } else {
      change['$unset'] = ['CUSTOMS_DECLARATION'];
    }

    return update(state, {
      ...change,
      contract: {
        $set: update(state.contract, {
          tariffs: { $set: CUSTOMS_DECLARATION },
        }),
      },
    });
  }

  if (updates.hasOwnProperty('CUSTOMS_WAREHOUSE')) {
    return update(state, {
      ...change,
      contract: {
        $set: CUSTOMS_WAREHOUSE,
      },
    });
  }

  if (updates.hasOwnProperty('TYPICAL_CONTRACT')) {
    return update(state, {
      ...change,
      contract: {
        $set: TYPICAL_CONTRACT,
      },
    });
  }

  if (updates.hasOwnProperty('updateTariff')) {
    return update(state, {
      ...change,
      contract: {
        $set: update(state.contract, {
          tariffs: { $set: updateTariff },
        }),
      },
    });
  }

  if (updates.hasOwnProperty('addTariff')) {
    return update(state, {
      ...change,
      contract: {
        $set: update(state.contract, {
          tariffs: { $push: [addTariff] },
        }),
      },
    });
  }

  if (updates.hasOwnProperty('tariffName')) {
    return update(state, {
      ...change,
      contract: {
        $set: update(state.contract, {
          tariffs: {
            $set: update(state.contract?.tariffs, {
              [tariffName.index]: {
                name: { $set: tariffName.value },
              },
            }),
          },
        }),
      },
    });
  }

  if (updates.hasOwnProperty('addFields')) {
    return update(state, {
      ...change,
      contract: {
        $set: update(state.contract, {
          tariffs: {
            $set: update(state.contract?.tariffs, {
              [addFields.index]: {
                fields: {
                  $push: [addFields.data],
                },
              },
            }),
          },
        }),
      },
    });
  }

  if (updates.hasOwnProperty('removeFields')) {
    return update(state, {
      ...change,
      contract: {
        $set: update(state.contract, {
          tariffs: {
            $set: update(state.contract?.tariffs, {
              [removeFields.index]: {
                fields: {
                  $splice: [[removeFields.item.index, 1]],
                },
              },
            }),
          },
        }),
      },
    });
  }

  if (updates.hasOwnProperty('removeTariff')) {
    return update(state, {
      ...change,
      contract: {
        $set: update(state.contract, {
          tariffs: {
            $splice: [[removeTariff, 1]],
          },
        }),
      },
    });
  }

  if (updates.hasOwnProperty('fieldName')) {
    if (!fieldName.name) {
      state.contract?.tariffs.map((tariff, index) => {
        if (fieldName.index === index) {
          tariff.fields.map((field, idx) => {
            if (idx === fieldName.item.index) {
              field.nameError = new ContractFieldNameException();
            }
          });
          tariff.fieldNameError = true;
        }
      });
    } else {
      state.contract?.tariffs.map((tariff, index) => {
        if (fieldName.index === index) {
          tariff.fields.map((field, idx) => {
            if (idx === fieldName.item.index) {
              field.nameError = false;
            }
          });
          delete tariff.fieldNameError;
        }
      });
    }

    return update(state, {
      ...change,
      contract: {
        $set: update(state.contract, {
          tariffs: {
            $set: update(state.contract?.tariffs, {
              [fieldName.index]: {
                fields: {
                  $set: update(
                    state.contract?.tariffs[fieldName.index].fields,
                    {
                      [fieldName.item.index]: {
                        name: { $set: fieldName.name },
                      },
                    },
                  ),
                },
              },
            }),
          },
        }),
      },
    });
  }

  if (updates.hasOwnProperty('eventType')) {
    if (!eventType.value) {
      state.contract?.tariffs.map((tariff, index) => {
        if (eventType.index === index) {
          tariff.fields.map((field, idx) => {
            if (idx === eventType.item.index) {
              field.typeError = new ContractFieldEventTypeException();
            }
          });
          tariff.eventTypeError = true;
        }
      });
    } else {
      state.contract?.tariffs.map((tariff, index) => {
        if (eventType.index === index) {
          tariff.fields.map((field, idx) => {
            if (idx === eventType.item.index) {
              field.typeError = false;
            }
          });
          delete tariff.eventTypeError;
        }
      });
    }

    return update(state, {
      ...change,
      contract: {
        $set: update(state.contract, {
          tariffs: {
            $set: update(state.contract?.tariffs, {
              [eventType.index]: {
                fields: {
                  $set: update(
                    state.contract?.tariffs[eventType.index].fields,
                    {
                      [eventType.item.index]: {
                        eventType: { $set: eventType.value },
                      },
                    },
                  ),
                },
              },
            }),
          },
        }),
      },
    });
  }

  if (updates.hasOwnProperty('fieldValue')) {
    if (!fieldValue.value) {
      state.contract?.tariffs.map((tariff, index) => {
        if (fieldValue.index === index) {
          tariff.fields.map((field, idx) => {
            if (idx === fieldValue.item.index) {
              field.values.map((value, itemIndex) => {
                if (itemIndex === fieldValue.item.item.index) {
                  value.valueError = new ContractFieldValueException();
                }
              });
            }
          });
          tariff.fieldValueError = true;
        }
      });
    } else {
      state.contract?.tariffs.map((tariff, index) => {
        if (fieldValue.index === index) {
          tariff.fields.map((field, idx) => {
            if (idx === fieldValue.item.index) {
              field.values.map((value, itemIndex) => {
                if (itemIndex === fieldValue.item.item.index) {
                  value.valueError = false;
                }
              });
            }
          });
          delete tariff.fieldValueError;
        }
      });
    }

    return update(state, {
      ...change,
      contract: {
        $set: update(state.contract, {
          tariffs: {
            $set: update(state.contract?.tariffs, {
              [fieldValue.index]: {
                fields: {
                  $set: update(
                    state.contract?.tariffs[fieldValue.index]?.fields,
                    {
                      [fieldValue.item.index]: {
                        values: {
                          $set: update(
                            state.contract?.tariffs[fieldValue.index]?.fields[
                              fieldValue.item.index
                            ].values,
                            {
                              [fieldValue.item.item.index]: {
                                value: {
                                  $set:
                                    +fieldValue.value < 0
                                      ? ''
                                      : fieldValue.value,
                                },
                              },
                            },
                          ),
                        },
                      },
                    },
                  ),
                },
              },
            }),
          },
        }),
      },
    });
  }

  if (updates.hasOwnProperty('fieldUnit')) {
    if (!fieldUnit) {
      change.fieldUnit = {
        $set: new Error(),
      };
    } else {
      change['$unset'] = ['fieldUnit'];
    }

    return update(state, {
      ...change,
      contract: {
        $set: update(state.contract, {
          tariffs: {
            $set: update(state.contract?.tariffs, {
              [fieldUnit.index]: {
                fields: {
                  $set: update(
                    state.contract?.tariffs[fieldUnit.index]?.fields,
                    {
                      [fieldUnit.item.index]: {
                        values: {
                          $set: update(
                            state.contract?.tariffs[fieldUnit.index]?.fields[
                              fieldUnit.item.index
                            ].values,
                            {
                              [fieldUnit.item.item.index]: {
                                unit: { $set: fieldUnit.value },
                              },
                            },
                          ),
                        },
                      },
                    },
                  ),
                },
              },
            }),
          },
        }),
      },
    });
  }

  if (updates.hasOwnProperty('removeItemField')) {
    if (!removeItemField) {
      change.removeItemField = {
        $set: new Error(),
      };
    } else {
      change['$unset'] = ['removeItemField'];
    }

    return update(state, {
      ...change,
      contract: {
        $set: update(state.contract, {
          tariffs: {
            $set: update(state.contract?.tariffs, {
              [removeItemField.index]: {
                fields: {
                  $set: update(
                    state.contract?.tariffs[removeItemField.index].fields,
                    {
                      [removeItemField.item.index]: {
                        values: {
                          $splice: [[removeItemField.item.item.index, 1]],
                        },
                      },
                    },
                  ),
                },
              },
            }),
          },
        }),
      },
    });
  }

  if (updates.hasOwnProperty('addFieldsItem')) {
    return update(state, {
      ...change,
      contract: {
        $set: update(state.contract, {
          tariffs: {
            $set: update(state.contract?.tariffs, {
              [addFieldsItem.index]: {
                fields: {
                  $set: update(
                    state.contract?.tariffs[addFieldsItem.index].fields,
                    {
                      [addFieldsItem.item.index]: {
                        values: { $push: [addFieldsItem.data] },
                      },
                    },
                  ),
                },
              },
            }),
          },
        }),
      },
    });
  }

  return state;
}
