/* External dependencies */
import update from 'immutability-helper';
import { parsePhoneNumber } from 'libphonenumber-js';

/* Local dependencies */
import {
  PhoneNumberInvalidException,
  PhoneNumberNotKyrgyzstanException,
  PhoneNumberRequiredException,
} from '../../redux/exceptions';
import {
  FirstNameRequiredException,
  LastNameRequiredException,
  RoleRequiredException,
  StatusRequiredException,
} from '../create-user/redux/exceptions';
import { User } from './userTypes';

export function validateForm(state, updates: User) {
  const { firstName, lastName, phone, role, status } = updates;

  const change: any = {
    isUserFormChanged: { $set: true },
  };

  if (updates.hasOwnProperty('firstName')) {
    if (firstName.length < 1) {
      change.firstNameError = { $set: new FirstNameRequiredException() };
    } else {
      change['$unset'] = ['firstNameError'];
    }

    return update(state, {
      ...change,
      user: {
        $set: update(state.user, {
          firstName: { $set: firstName },
        }),
      },
    });
  }

  if (updates.hasOwnProperty('lastName')) {
    if (lastName.length < 1) {
      change.lastNameError = { $set: new LastNameRequiredException() };
    } else {
      change['$unset'] = ['lastNameError'];
    }

    return update(state, {
      ...change,
      user: {
        $set: update(state.user, {
          lastName: { $set: lastName },
        }),
      },
    });
  }

  if (updates.hasOwnProperty('phone')) {
    let phoneNumber;

    if (phone.length >= 10) {
      phoneNumber = parsePhoneNumber(phone, 'KG');
    }

    if (phone.length < 1) {
      change.phoneError = { $set: new PhoneNumberRequiredException() };
    } else if (phone.length !== 13) {
      change.phoneError = { $set: new PhoneNumberInvalidException() };
    } else if (phoneNumber.country !== 'KG' && !phoneNumber.isValid()) {
      change.phoneError = { $set: new PhoneNumberNotKyrgyzstanException() };
    } else {
      change['$unset'] = ['phoneError'];
    }

    return update(state, {
      ...change,
      user: {
        $set: update(state.user, {
          phone: { $set: phone },
        }),
      },
    });
  }

  if (updates.hasOwnProperty('role')) {
    if (role.length < 1) {
      change.roleError = { $set: new RoleRequiredException() };
    } else {
      change['$unset'] = ['roleError'];
    }

    return update(state, {
      ...change,
      user: {
        $set: update(state.user, {
          role: { $set: role },
        }),
      },
    });
  }

  if (updates.hasOwnProperty('status')) {
    if (status.length < 1) {
      change.statusError = { $set: new StatusRequiredException() };
    } else {
      change['$unset'] = ['statusError'];
    }

    return update(state, {
      ...change,
      user: {
        $set: update(state.user, {
          status: { $set: status },
        }),
      },
    });
  }

  return state;
}
