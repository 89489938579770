/* External dependencies */
import update from 'immutability-helper';

/* Local dependencies */
import { WarehouseNameRequiredException } from '../../create-warehouse/redux/exceptions';
import { Warehouse, WarehouseStatus } from '../../warehouses/types';
import {
  WarehouseAction,
  WarehouseActions,
  WarehouseActionTypes,
} from './actions';

export interface WarehouseState {
  error?: Error;
  loading?: boolean;
  nameError?: Error;
  isWarehouseUpdated?: boolean;
  isFormChanged?: boolean;
  warehouse: Warehouse;
  warehouseAction: WarehouseAction;
}

export const initialWarehouseState: WarehouseState = {
  warehouse: {
    id: '',
    name: '',
    status: WarehouseStatus.ENABLED,
  },
  warehouseAction: WarehouseAction.UPDATE,
};

export default function warehouseReducer(
  state = initialWarehouseState,
  action: WarehouseActions,
) {
  switch (action.type) {
    case WarehouseActionTypes.RESET_WAREHOUSE_DETAILS:
      return update(state, {
        $set: initialWarehouseState,
      });

    case WarehouseActionTypes.GET_WAREHOUSE_SUCCESS:
      return update(state, {
        loading: { $set: false },
        warehouse: { $set: action.warehouse },
      });

    case WarehouseActionTypes.UPDATE_WAREHOUSE_REQUEST:
      return update(state, {
        $unset: ['error'],
        loading: { $set: true },
        isWarehouseUpdated: { $set: false },
      });

    case WarehouseActionTypes.UPDATE_WAREHOUSE_SUCCESS:
      return update(state, {
        loading: { $set: false },
        warehouse: { $set: action.warehouse },
        isWarehouseUpdated: { $set: true },
      });

    case WarehouseActionTypes.UPDATE_WAREHOUSE_FAILURE:
      return update(state, {
        error: { $set: action.error },
        loading: { $set: false },
        isWarehouseUpdated: { $set: false },
      });

    case WarehouseActionTypes.CHANGE_WAREHOUSE_NAME:
      return validateName(state, action.name);

    case WarehouseActionTypes.CHANGE_WAREHOUSE_ACTION:
      return update(state, {
        warehouseAction: { $set: action.action },
      });

    case WarehouseActionTypes.RESET_WAREHOUSE_ERROR:
      return update(state, {
        $unset: ['error'],
      });

    default:
      return state;
  }
}

function validateName(state, name) {
  const change: any = {
    isFormChanged: { $set: true },
  };

  if (name.length < 1) {
    change.nameError = { $set: new WarehouseNameRequiredException() };
  } else {
    change['$unset'] = ['nameError'];
  }

  return update(state, {
    ...change,
    warehouse: {
      $set: update(state.warehouse, {
        name: { $set: name },
      }),
    },
  });
}
