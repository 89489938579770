// Local dependencies
import { filter, switchMap } from 'rxjs/operators';

// Local dependencies
import { getClient } from '../../../../clients/bts';
import { removeIDFromTariffs } from '../helper';
import {
  CreateContractTypes,
  CreateContractAction,
  createContractFailed,
  createContractSucceeded,
} from './actions';
import { createContractMutation } from './mutation';
import { Contract } from './types';

export default function createContractEpic(actions$) {
  return actions$.pipe(
    filter(
      (action: CreateContractAction) =>
        action.type === CreateContractTypes.CREATE_CONTRACT_REQUEST,
    ),
    switchMap((action: CreateContractAction) =>
      createContract(action)
        .then(createContractSucceeded)
        .catch(createContractFailed),
    ),
  );
}

export async function createContract({ contract }): Promise<Contract> {
  const graphQLClient = await getClient();

  const tariffs = removeIDFromTariffs(contract.tariffs);
  const input = { ...contract, tariffs: tariffs };

  const {
    data: { createContract },
  } = await graphQLClient.mutate({
    mutation: createContractMutation,
    variables: {
      input,
    },
  });

  return createContract as Contract;
}
