// External dependencies
import { DEFAULT_PAGE_SIZE } from '../../common/helpers';
import { VehicleProfile } from '../../vehicles/vehicle-types';

export enum ListVehicleProfilesActionTypes {
  LIST_VEHICLE_PROFILES_REQUEST = 'LIST_VEHICLE_PROFILES_REQUEST',
  LIST_VEHICLE_PROFILES_SUCCESS = 'LIST_VEHICLE_PROFILES_SUCCESS',
  LIST_VEHICLE_PROFILES_ERROR = 'LIST_VEHICLE_PROFILES_ERROR',
  RESET_SELECT_VEHICLE_PROFILE = 'RESET_SELECT_VEHICLE_PROFILE',
  SELECT_VEHICLE_PROFILE = 'SELECT_VEHICLE_PROFILE',
}

export type ListVehicleProfilesActions =
  | ListVehicleProfilesRequest
  | ListVehicleProfilesSuccess
  | ListVehicleProfilesFailure
  | ResetSelectVehicleProfile
  | SelectVehicleProfile;

export interface ListVehicleProfilesFilter {
  vehiclePlateNumber?: string;
  trailerPlateNumber?: string;
}

export interface SelectVehicleProfile {
  type: ListVehicleProfilesActionTypes.SELECT_VEHICLE_PROFILE;
  id: string;
}

export interface ResetSelectVehicleProfile {
  type: ListVehicleProfilesActionTypes.RESET_SELECT_VEHICLE_PROFILE;
}

export interface ListVehicleProfilesRequest {
  type: ListVehicleProfilesActionTypes.LIST_VEHICLE_PROFILES_REQUEST;
  currentPage?: number;
  size?: number;
  searchString?: string;
  filter?: ListVehicleProfilesFilter;
}

export interface ListVehicleProfilesSuccess {
  type: ListVehicleProfilesActionTypes.LIST_VEHICLE_PROFILES_SUCCESS;
  total: number;
  vehicleProfiles: [VehicleProfile];
}

export interface ListVehicleProfilesFailure {
  type: ListVehicleProfilesActionTypes.LIST_VEHICLE_PROFILES_ERROR;
  error: Error;
}

export function listVehicleProfilesRequest(
  searchString: string = '',
  currentPage: number = 1,
  filter?: ListVehicleProfilesFilter,
  size: number = DEFAULT_PAGE_SIZE,
): ListVehicleProfilesRequest {
  return {
    type: ListVehicleProfilesActionTypes.LIST_VEHICLE_PROFILES_REQUEST,
    searchString,
    filter,
    currentPage,
    size,
  };
}

export function listVehicleProfilesSucceeded(
  vehicleProfiles: [VehicleProfile],
  total: number,
): ListVehicleProfilesSuccess {
  return {
    total,
    type: ListVehicleProfilesActionTypes.LIST_VEHICLE_PROFILES_SUCCESS,
    vehicleProfiles,
  };
}

export function listVehicleProfilesFailed(
  error: Error,
): ListVehicleProfilesFailure {
  return {
    type: ListVehicleProfilesActionTypes.LIST_VEHICLE_PROFILES_ERROR,
    error,
  };
}

export function selectVehicleProfile(id: string): SelectVehicleProfile {
  return {
    type: ListVehicleProfilesActionTypes.SELECT_VEHICLE_PROFILE,
    id,
  };
}

export function resetSelectedVehicleProfile(): ResetSelectVehicleProfile {
  return {
    type: ListVehicleProfilesActionTypes.RESET_SELECT_VEHICLE_PROFILE,
  };
}
