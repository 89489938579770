/* External dependencies */
import gql from 'graphql-tag';

export const createCargoMutation = gql`
  mutation createCargo($input: CreateCargoInput!) {
    createCargo(input: $input) {
      cargoEscort
      cargoNetWeight
      cargoSlots
      consignees {
        cargoWeight
        departureCountry
        name
        products
        slots
        transit
      }
      vehicle {
        arrivalDate
        id
        vehiclePlateNumber
      }
    }
  }
`;
