/* External dependencies */
import update from 'immutability-helper';

/* Local dependencies */
import {
  ListVehiclesForExtractionAction,
  ListVehiclesForExtractionActionTypes,
} from './actions';

export interface VehiclesForExtractionState {
  error?: Error;
  loading?: boolean;
  vehicles: Array<any>;
}

export const initialVehiclesForExtractionState: VehiclesForExtractionState = {
  loading: false,
  vehicles: [],
};

export default function vehiclesForExtraction(
  state = initialVehiclesForExtractionState,
  action: ListVehiclesForExtractionAction,
) {
  switch (action.type) {
    case ListVehiclesForExtractionActionTypes.LIST_VEHICLES_FOR_EXTRACTION_REQUEST:
      return update(state, {
        $unset: ['error'],
        loading: { $set: true },
      });

    case ListVehiclesForExtractionActionTypes.LIST_VEHICLES_FOR_EXTRACTION_SUCCESS:
      return update(state, {
        loading: { $set: false },
        vehicles: { $set: action.vehicles },
      });

    case ListVehiclesForExtractionActionTypes.LIST_VEHICLES_FOR_EXTRACTION_ERROR:
      return update(state, {
        error: { $set: action.error },
        loading: { $set: false },
      });

    default:
      return state;
  }
}
