/* Local dependencies */
import formatMessage from '../../../intl/formatMessage';
import renderDate from '../../common/renderDate';
import getFullName from '../../common/renderName';
import { Vehicle } from '../redux/actions';

export function getWeighmanFields() {
  return [
    {
      label: 'arrivalDate',
      value: (vehicle: Vehicle) => renderDate(vehicle.arrivalDate),
      placeholder: 'arrivalDate',
    },
    {
      label: 'vehiclePlateNumber',
      placeholder: 'vehiclePlateNumberPlaceholder',
    },
    {
      label: 'trailerPlateNumber',
      placeholder: 'trailerPlateNumberPlaceholder',
    },
    {
      label: 'arrivalPurpose',
      hasSeparator: true,
      placeholder: 'arrivalPurpose',
      value: (vehicle: Vehicle) => formatMessage(vehicle.arrivalPurpose),
    },
    {
      label: 'weightType',
      placeholder: 'weightType',
      value: (vehicle: Vehicle) => formatMessage(vehicle.weightType),
    },
    {
      label: 'grossWeight',
      placeholder: 'grossWeight',
      value: (vehicle: Vehicle) => vehicle.grossWeight,
    },
    {
      label: 'driver',
      hasSeparator: true,
      placeholder: 'driverPlaceholder',
      value: (vehicle: Vehicle) => vehicle.driver.name,
    },
    {
      label: 'driverPhone',
      placeholder: 'driverPhonePlaceholder',
      value: (vehicle: Vehicle) => vehicle.driver.phone,
    },
    {
      label: 'actFormNumber',
      placeholder: 'actFormNumberPlaceholder',
    },
    {
      label: 'borderCheckpoint',
      isDropdownCapable: true,
      placeholder: 'borderCheckpointPlaceholder',
      value: (vehicle: Vehicle) => vehicle.borderCheckpoint,
    },
    {
      label: 'entryRegistered',
      hasSeparator: true,
      placeholder: 'entryRegistered',
      value: (vehicle: Vehicle) =>
        getFullName(vehicle.operator.firstName, vehicle.operator.lastName),
    },
    {
      label: 'grossWeight',
      hasSeparator: true,
      placeholder: 'grossWeight',
      value: (vehicle: Vehicle) => vehicle.grossWeight,
    },
  ];
}

export function getWeighmanEditableFields() {
  return ['grossWeight'];
}
