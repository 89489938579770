/* External dependencies */
import gql from 'graphql-tag';

export const updateConsigneeMutation = gql`
  mutation updateConsignee($input: UpdateConsigneeInput!) {
    updateConsignee(input: $input) {
      id
      vehicleToExtractCargo {
        id
        vehiclePlateNumber
        isCargoUnloadingByClient
      }
      warehouse {
        id
      }
    }
  }
`;
