/* External dependencies */
import gql from 'graphql-tag';

export enum WeightType {
  GROSS = 'GROSS',
  TARE = 'TARE',
}

type AWSPhone = String;

type ExternalUserInput = {
  name: String;
  phone: String;
  role: String;
};

export enum VehicleArrivalPurpose {
  ARREST = 'ARREST',
  CARGO_EXTRACTION = 'CARGO_EXTRACTION',
  CARGO_IMPORT = 'CARGO_IMPORT',
  PARKING = 'PARKING',
  PARKING_WITH_CARGO = 'PARKING_WITH_CARGO',
  WEIGHIN = 'WEIGHIN',
}

export const createVehicleMutation = gql`
  mutation createVehicle($input: CreateVehicleInput!
  ) {
    createVehicle(
      input: $input
    ) {
      arrivalPurpose
      arrivalDate
      driver {
        name
      }
      grossWeight
      vehiclePlateNumber
      weightType
    }
  }
`;
