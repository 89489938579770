import gql from 'graphql-tag';

export const createWarehouseMutation = gql`
  mutation createWarehouse($input: WarehouseInput!) {
    createWarehouse(input: $input) {
      id
      name
      status
    }
  }
`;
