/* External dependencies */
import { filter, switchMap } from 'rxjs/operators';

/* Local dependencies */
import { getClient } from '../../../../clients/bts';
import { GetInvoiceActionTypes } from '../common/invoice-types';
import {
  getInvoiceFailed,
  GetInvoiceRequest,
  getInvoiceSucceeded,
  InvoiceAction,
  InvoiceDetails,
  makePaymentFailed,
  MakePaymentRequest,
  makePaymentSucceeded,
  Payment,
  revokePaymentFailed,
  RevokePaymentRequest,
  revokePaymentSucceeded,
} from './actions';
import { makePaymentMutation, revokePaymentMutation } from './mutations';
import { getInvoiceQuery } from './queries';

export function getInvoiceEpic(action$) {
  return action$.pipe(
    filter(
      (action: InvoiceAction) =>
        action.type === GetInvoiceActionTypes.GET_INVOICE_REQUEST,
    ),
    switchMap((action: GetInvoiceRequest) =>
      getInvoice(action).then(getInvoiceSucceeded).catch(getInvoiceFailed),
    ),
  );
}

//prettier-ignore
export async function getInvoice({ id }: GetInvoiceRequest): Promise<InvoiceDetails> {
  const graphQLClient =  await getClient();

  const {
    data: { getInvoice },
  } = await graphQLClient.query({
    query: getInvoiceQuery,
    variables: {
      input: {
        id,
      },
    },
  });

  return getInvoice;
}

export function makePaymentEpic(action$) {
  return action$.pipe(
    filter(
      (action: MakePaymentRequest) =>
        action.type === GetInvoiceActionTypes.MAKE_PAYMENT_INVOICE_REQUEST,
    ),
    switchMap((action: MakePaymentRequest) =>
      makePayment(action).then(makePaymentSucceeded).catch(makePaymentFailed),
    ),
  );
}

export async function makePayment(action): Promise<Payment> {
  const graphQLClient = await getClient();
  const { payment } = action;

  //Checking which fields have values
  const input = Object.keys(payment).reduce((input, key) => {
    if (payment.hasOwnProperty(key) && typeof payment[key] !== 'undefined') {
      input[key] = payment[key];
    }

    return input;
  }, {});

  delete input?.documentType;
  delete input?.documentId;

  const {
    data: { makePayment },
  } = await graphQLClient.mutate({
    mutation: makePaymentMutation,
    variables: {
      input,
    },
  });

  return makePayment as InvoiceDetails;
}

export function revokePaymentEpic(action$) {
  return action$.pipe(
    filter(
      (action: RevokePaymentRequest) =>
        action.type === GetInvoiceActionTypes.REVOKE_PAYMENT_REQUEST,
    ),
    switchMap((action: RevokePaymentRequest) =>
      revokePayment(action)
        .then(revokePaymentSucceeded)
        .catch(revokePaymentFailed),
    ),
  );
}

export async function revokePayment({
  id,
}: RevokePaymentRequest): Promise<InvoiceDetails> {
  const graphQLClient = await getClient();

  const {
    data: { revokePayment },
  } = await graphQLClient.mutate({
    mutation: revokePaymentMutation,
    variables: {
      input: {
        id,
      },
    },
  });

  return revokePayment;
}
