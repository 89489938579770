/* External dependencies */
import update from 'immutability-helper';

/* Local dependencies */
import {
  ListWarehousesForUnloadingActionTypes,
  ListWarehousesForUnloadingAction,
  DEFAULT_WAREHOUSES_FILTER,
} from './actions';
import {
  ListWarehousesFilter,
  WarehouseStatus,
} from '../../../warehouses/types';

export interface WarehousesForUnloadingState {
  currentPage: number;
  error?: Error;
  filter: ListWarehousesFilter;
  loading?: boolean;
  searchString: string;
  size: number;
  total: number;
  warehouses: Array<any>;
}

export const initialWarehousesForUnloadingState: WarehousesForUnloadingState = {
  currentPage: 1,
  filter: DEFAULT_WAREHOUSES_FILTER,
  loading: false,
  searchString: '',
  size: 20,
  total: 0,
  warehouses: [],
};

export default function warehousesForUnloading(
  state = initialWarehousesForUnloadingState,
  action: ListWarehousesForUnloadingAction,
) {
  switch (action.type) {
    case ListWarehousesForUnloadingActionTypes.LIST_WAREHOUSES_FOR_UNLOADING_REQUEST:
      return update(state, {
        $unset: ['error'],
        loading: { $set: true },
        currentPage: { $set: action.currentPage },
        searchString: { $set: action.searchString },
        filter: { $set: action.filter },
      });

    case ListWarehousesForUnloadingActionTypes.LIST_WAREHOUSES_FOR_UNLOADING_SUCCESS:
      return update(state, {
        loading: { $set: false },
        warehouses: { $set: action.warehouses },
        total: { $set: action.total },
      });

    case ListWarehousesForUnloadingActionTypes.LIST_WAREHOUSES_FOR_UNLOADING_ERROR:
      return update(state, {
        error: { $set: action.error },
        loading: { $set: false },
      });

    case ListWarehousesForUnloadingActionTypes.RESET_LIST_WAREHOUSES:
      return update(state, { $set: initialWarehousesForUnloadingState });

    case ListWarehousesForUnloadingActionTypes.RESET_ERROR_LIST_WAREHOUSES:
      return update(state, {
        $unset: ['error'],
      });

    default:
      return state;
  }
}
