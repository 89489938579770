/* External dependencies */
import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
  NormalizedCacheObject,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import * as AWSCognito from 'amazon-cognito-identity-js';
import { AUTH_TYPE, createAuthLink } from 'aws-appsync-auth-link';
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link';
import AWS, { CognitoIdentityCredentials } from 'aws-sdk/global';
import { v4 as uuidv4 } from 'uuid';

/* Local dependencies */
import {
  InitClientFailed,
  initClientFailed,
  InitClientSucceeded,
  initClientSucceeded,
} from '../components/login/redux/actions';
import { COGNITO_USER_POOL_DATA, getSession } from './cognito';
import CustomCognitoUserSession from './cognitoUserSession';

type AppSyncClient = ApolloClient<NormalizedCacheObject>;
let client: AppSyncClient;
let credentials;

export const config = {
  APPSYNC_ENDPOINT: process.env.GATSBY_APPSYNC_ENDPOINT,
  AVERSPAY_USER_ID: process.env.GATSBY_AVERSPAY_USER_ID,
  COGNITO_IDENTITY_POOL_ID: process.env.GATSBY_COGNITO_IDENTITY_POOL_ID,
  COGNITO_USER_POOL_ID: process.env.GATSBY_COGNITO_USER_POOL_ID,
  REGION: process.env.GATSBY_REGION,
};
const {
  APPSYNC_ENDPOINT,
  COGNITO_IDENTITY_POOL_ID,
  COGNITO_USER_POOL_ID,
  REGION,
} = config;

// Log any GraphQL errors or network error that occurred
const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(
          locations,
        )}, Path: ${path}`,
      ),
    );
  if (networkError) console.log(`[Network error]: ${networkError}`);
});

export function setupClient(session: CustomCognitoUserSession) {
  // In order to access environment variables the full version
  // like `process.env.XXX` must be written. It will not work
  // when `process.env` is destructed.
  const idToken = session.getIdToken().getJwtToken();
  const providerName = `cognito-idp.${REGION}.amazonaws.com/${COGNITO_USER_POOL_ID}`;

  credentials = new CognitoIdentityCredentials({
    IdentityPoolId: COGNITO_IDENTITY_POOL_ID!,
    Logins: {
      [providerName]: idToken,
    },
  });

  AWS.config.update({
    region: REGION,
    credentials,
  });

  const appSyncConfig = {
    url: APPSYNC_ENDPOINT!,
    region: REGION!,
    auth: {
      type: AUTH_TYPE.AWS_IAM as AUTH_TYPE.AWS_IAM,
      credentials,
    },
    offlineConfig: {
      keyPrefix: `client-instance-${uuidv4()}`,
    },
  };

  const httpLink = new HttpLink({
    uri: appSyncConfig.url,
  });

  const link = ApolloLink.from([
    errorLink,
    createAuthLink(appSyncConfig),
    createSubscriptionHandshakeLink(appSyncConfig, httpLink),
  ]);

  return (client = new ApolloClient({
    link,
    cache: new InMemoryCache(),
    defaultOptions: {
      query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'ignore',
      },
    },
  }));
}

export async function cleanUpSession() {
  if (client) {
    try {
      await client.clearStore();
    } catch (_) {
      // Ignore the error since we anyway wanted to cleanup the client.
    }

    client = undefined;
  }

  if (AWS.config.credentials) {
    (AWS.config.credentials as CognitoIdentityCredentials).clearCachedId();
    AWS.config.credentials = null;
  }

  return initClientFailed();
}

export async function getClient(): Promise<
  ApolloClient<NormalizedCacheObject>
> {
  if (credentials.needsRefresh() && credentials.expireTime) {
    const cognitoUserPool = new AWSCognito.CognitoUserPool(
      COGNITO_USER_POOL_DATA,
    );

    const currentUser = cognitoUserPool.getCurrentUser();
    const session: CustomCognitoUserSession = await getSession();

    return new Promise((resolve, reject) => {
      currentUser?.refreshSession(
        session.getRefreshToken(),
        (err, newSession) => {
          if (err) {
            reject(err);
          }

          resolve(setupClient(newSession));
        },
      );
    });
  }

  return client;
}

export function getDefaultClient() {
  return client;
}

export async function initClient(): Promise<
  InitClientFailed | InitClientSucceeded
> {
  const session: CustomCognitoUserSession = await getSession();

  if (session.isValid()) {
    return initClientSucceeded(session);
  }

  return initClientFailed();
}
