// External dependencies
import update from 'immutability-helper';

// Local dependencies
import {
  UpdateVehicleProfileActions,
  UpdateVehicleProfileActionTypes,
} from './action';

export interface UpdateVehicleProfileState {
  error?: Error;
  loading: boolean;
  isSuccess: boolean;
  vehicleProfile?: any;
}

export const updateVehicleProfileInitialState = {
  loading: false,
  isSuccess: false,
  vehicleProfile: {},
};

export default function updateVehicleProfileReducer(
  state = updateVehicleProfileInitialState,
  action: UpdateVehicleProfileActions,
) {
  switch (action.type) {
    case UpdateVehicleProfileActionTypes.UPDATE_VEHICLE_PROFILE_REQUEST: {
      return update(state, {
        loading: { $set: true },
      });
    }

    case UpdateVehicleProfileActionTypes.UPDATE_VEHICLE_PROFILE_SUCCESS: {
      return update(state, {
        loading: { $set: false },
        isSuccess: { $set: true },
        vehicleProfile: { $set: action.vehicleProfile },
      });
    }

    case UpdateVehicleProfileActionTypes.UPDATE_VEHICLE_PROFILE_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    case UpdateVehicleProfileActionTypes.RESET_UPDATE_VEHICLE_PROFILE: {
      return updateVehicleProfileInitialState;
    }

    default:
      return state;
  }
}
