/* External dependencies */
import gql from 'graphql-tag';

export const listVehiclesForExtractionQuery = gql`
  query listVehicles(
    $from: Int
    $size: Int
    $query: String
    $sort: Sort
    $filter: ListVehiclesFilter
  ) {
    listVehicles(
      filter: $filter
      from: $from
      query: $query
      size: $size
      sort: $sort
    ) {
      vehicles {
        id
        vehiclePlateNumber
      }
    }
  }
`;
