// Local dependencies
import { ConsigneeGoodSpecifications } from '../../types';
import {
  ListConsigneeGoodsSpecificationsActionTypes,
  ListConsigneeGoodsSpecificationsActions,
} from './actions';

export interface ListConsigneeGoodsSpecificationsState {
  loading: boolean;
  error: Error | null;
  currentPage: number;
  size: number;
  searchString: string;
  total: number;
  consigneeGoodsSpecifications: ConsigneeGoodSpecifications[];
}

const listConsigneeGoodsSpecificationsInitialState: ListConsigneeGoodsSpecificationsState =
  {
    loading: false,
    error: null,
    currentPage: 1,
    size: 20, 
    searchString: '',
    total: 0,
    consigneeGoodsSpecifications: [],
  };

const listConsigneeGoodsSpecificationsReducer = (
  state = listConsigneeGoodsSpecificationsInitialState,
  action: ListConsigneeGoodsSpecificationsActions,
): ListConsigneeGoodsSpecificationsState => {
  switch (action.type) {
    case ListConsigneeGoodsSpecificationsActionTypes.LIST_CONSIGNEE_GOODS_SPECIFICATIONS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
        currentPage:
          action.currentPage ||
          listConsigneeGoodsSpecificationsInitialState.currentPage,
        size: action.size || listConsigneeGoodsSpecificationsInitialState.size,
        searchString:
          action.searchString ||
          listConsigneeGoodsSpecificationsInitialState.searchString,
      };

    case ListConsigneeGoodsSpecificationsActionTypes.LIST_CONSIGNEE_GOODS_SPECIFICATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        total: action.total,
        consigneeGoodsSpecifications: action.consigneeGoodsSpecifications,
      };

    case ListConsigneeGoodsSpecificationsActionTypes.LIST_CONSIGNEE_GOODS_SPECIFICATIONS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    default:
      return state;
  }
};

export default listConsigneeGoodsSpecificationsReducer;
