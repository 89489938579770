export enum GetInvoiceActionTypes {
  CANCEL_PAYMENT_POPUP = 'CANCEL_PAYMENT_POPUP',
  GET_INVOICE_REQUEST = 'GET_INVOICE_REQUEST',
  GET_INVOICE_SUCCESS = 'GET_INVOICE_SUCCESS',
  GET_INVOICE_ERROR = 'GET_INVOICE_ERROR',
  HIDE_CONFIRMATION_POPUP = 'HIDE_CONFIRMATION_POPUP',
  IS_SUCCESS_POPUP = 'IS_SUCCESS_POPUP',
  MAKE_PAYMENT_INVOICE_REQUEST = 'MAKE_PAYMENT_INVOICE_REQUEST',
  MAKE_PAYMENT_INVOICE_SUCCESS = 'MAKE_PAYMENT_INVOICE_SUCCESS',
  MAKE_PAYMENT_INVOICE_ERROR = 'MAKE_PAYMENT_INVOICE_ERROR',
  MAKE_PAYMENT_RESET_ERROR = 'MAKE_PAYMENT_RESET_ERROR',
  MAKE_PAYMENT_RESET = 'MAKE_PAYMENT_RESET',
  REVOKE_PAYMENT_REQUEST = 'REVOKE_PAYMENT_REQUEST',
  REVOKE_PAYMENT_SUCCESS = 'REVOKE_PAYMENT_SUCCESS',
  REVOKE_PAYMENT_ERROR = 'REVOKE_PAYMENT_ERROR',
  SET_PAYMENT_FIELDS = 'SET_PAYMENT_FIELDS',
  SHOW_CONFIRMATION_POPUP = 'SHOW_CONFIRMATION_POPUP',
  UPDATE_INVOICE_FIELDS = 'UPDATE_INVOICE_FIELDS',
}

export enum PriceUnit {
  SOM = 'SOM',
  SOM_DAY = 'SOM_DAY',
  SOM_FIRST_OPERATION = 'SOM_FIRST_OPERATION',
  SOM_HOUR = 'SOM_HOUR',
  SOM_KG = 'SOM_KG',
  SOM_KG_DAY = 'SOM_KG_DAY',
  SOM_MONTH = 'SOM_MONTH',
  SOM_NEXT_OPERATION = 'SOM_NEXT_OPERATION',
  SOM_PROCEDURE = 'SOM_PROCEDURE',
  SOM_SET = 'SOM_SET',
  SOM_THREE_DAYS = 'SOM_THREE_DAYS',
  SOM_WEEK = 'SOM_WEEK',
  SOM_UNIT = 'SOM_UNIT',
  SOM_YEAR = 'SOM_YEAR',
}

export declare enum ContractType {
  CUSTOMS_DECLARATION = 'CUSTOMS_DECLARATION',
  CUSTOMS_WAREHOUSE = 'CUSTOMS_WAREHOUSE',
  TEMPORARY_STORAGE = 'TEMPORARY_STORAGE',
}

export enum PaymentDocumentType {
  APPLICATION = 'APPLICATION',
  DECLARATION = 'DECLARATION',
}

export const enum PaymentStatus {
  PAID = 'PAID',
  UNPAID = 'UNPAID',
}

export enum PaymentType {
  CASH = 'CASH',
  CARD = 'CARD',
}
