/* External dependencies */
import { of } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';

/* Local dependencies */
import { getClient } from '../../../clients/bts';
import {
  Cargo,
  CargoAction,
  CargoActionTypes,
  cargoFailed,
  GetCargoRequest,
  cargoSuccessed,
} from './actions';
import { cargoDetailQuery } from './queries';

export default function cargoEpic(action$) {
  return action$.pipe(
    filter(
      (action: CargoAction) => action.type === CargoActionTypes.CARGO_REQUEST,
    ),
    switchMap((action: GetCargoRequest) =>
      getCargo(action).then(cargoSuccessed).catch(cargoFailed),
    ),
  );
}

export async function getCargo({ id }: GetCargoRequest): Promise<Cargo> {
  const graphQLClient = await getClient();

  const {
    data: { getCargoByVehicleId },
  } = await graphQLClient.query({
    query: cargoDetailQuery,
    variables: {
      input: {
        id,
      },
    },
  });

  return getCargoByVehicleId;
}
