/* Local dependencies */
import { EventType } from '../../../contracts/createContracts/redux/types';
import { Consignee } from '../../../create-cargo/redux/actions';
import { Vehicle } from '../../../vehicle-details/redux/actions';
import {
  GetInvoiceActionTypes,
  PaymentDocumentType,
  PaymentType,
  PriceUnit,
} from '../common/invoice-types';

interface ObjectID {
  id: string;
}

type VehicleID = ObjectID;
type ConsigneeID = ObjectID;

export interface Event {
  id: string;
  eventDate: number;
  eventType: EventType;
  vehicle?: VehicleID;
  consignee?: ConsigneeID;
  sum: number;
  unit: PriceUnit;
}

export interface Payment {
  id: string;
}

export interface MakePaymentInput extends Payment {
  id: string;
  applicationNumber?: string;
  documentType?: string;
  declarationNumber?: string;
  paymentAmount?: number;
  paymentCurrency?: string;
  paymentType?: PaymentType;
  consignee?: {
    id?: string;
  };
  contract?: {
    id?: string;
  };
  file?: string;
}

export interface InvoiceDetails {
  id: string;
  applicationNumber?: string;
  declarationNumber?: string;
  paymentCurrency: any;
  paymentAmount?: string;
  consignee?: Consignee;
  events?: Event[];
  vehicle?: Vehicle;
}

export interface UpdateInvoiceFields {
  documentId?: string;
  documentType?: PaymentDocumentType;
  declarationNumber?: string;
  applicationNumber?: string;
  paymentType?: PaymentType;
  file?: string;
}

export interface GetInvoiceRequest {
  type: GetInvoiceActionTypes.GET_INVOICE_REQUEST;
  id: string;
}

export interface GetInvoiceSuccess {
  type: GetInvoiceActionTypes.GET_INVOICE_SUCCESS;
  invoice: InvoiceDetails;
}

export interface GetInvoiceError {
  type: GetInvoiceActionTypes.GET_INVOICE_ERROR;
  error: Error;
}

export interface MakePaymentRequest {
  type: GetInvoiceActionTypes.MAKE_PAYMENT_INVOICE_REQUEST;
  payment: MakePaymentInput;
}

export interface MakePaymentSuccess {
  type: GetInvoiceActionTypes.MAKE_PAYMENT_INVOICE_SUCCESS;
  payment: InvoiceDetails;
}

export interface MakePaymentError {
  type: GetInvoiceActionTypes.MAKE_PAYMENT_INVOICE_ERROR;
  error: Error;
}

export interface MakePaymentReset {
  type: GetInvoiceActionTypes.MAKE_PAYMENT_RESET;
}

export interface MakePaymentResetError {
  type: GetInvoiceActionTypes.MAKE_PAYMENT_RESET_ERROR;
}

export interface RevokePaymentRequest {
  type: GetInvoiceActionTypes.REVOKE_PAYMENT_REQUEST;
  id: string;
}

export interface RevokePaymentSuccess {
  type: GetInvoiceActionTypes.REVOKE_PAYMENT_SUCCESS;
  invoice: InvoiceDetails;
}

export interface RevokePaymentError {
  type: GetInvoiceActionTypes.REVOKE_PAYMENT_ERROR;
  error: Error;
}

export interface ShowConfirmationPopup {
  type: GetInvoiceActionTypes.SHOW_CONFIRMATION_POPUP;
  value: boolean;
}

export interface HideConfirmationPopup {
  type: GetInvoiceActionTypes.HIDE_CONFIRMATION_POPUP;
}

export interface UpdateInvoiceFieldsRequest {
  type: GetInvoiceActionTypes.UPDATE_INVOICE_FIELDS;
  updates: UpdateInvoiceFields;
}

export interface SetPaymentFieldsRequest {
  type: GetInvoiceActionTypes.SET_PAYMENT_FIELDS;
  payment: MakePaymentInput;
}

export interface cancelPaymentPopup {
  type: GetInvoiceActionTypes.CANCEL_PAYMENT_POPUP;
  value: boolean;
}

export interface IsSuccessPopup {
  type: GetInvoiceActionTypes.IS_SUCCESS_POPUP;
}

export type InvoiceAction =
  | cancelPaymentPopup
  | GetInvoiceRequest
  | GetInvoiceSuccess
  | GetInvoiceError
  | MakePaymentRequest
  | MakePaymentSuccess
  | MakePaymentError
  | MakePaymentReset
  | MakePaymentResetError
  | HideConfirmationPopup
  | IsSuccessPopup
  | RevokePaymentRequest
  | RevokePaymentSuccess
  | RevokePaymentError
  | ShowConfirmationPopup
  | SetPaymentFieldsRequest
  | UpdateInvoiceFieldsRequest;

export function cancelPaymentPopup(value: boolean): cancelPaymentPopup {
  return {
    type: GetInvoiceActionTypes.CANCEL_PAYMENT_POPUP,
    value,
  };
}

export function getInvoice(id: string): GetInvoiceRequest {
  return {
    type: GetInvoiceActionTypes.GET_INVOICE_REQUEST,
    id,
  };
}

export function getInvoiceSucceeded(
  invoice: InvoiceDetails,
): GetInvoiceSuccess {
  return {
    type: GetInvoiceActionTypes.GET_INVOICE_SUCCESS,
    invoice,
  };
}

export function getInvoiceFailed(error: Error): GetInvoiceError {
  return {
    type: GetInvoiceActionTypes.GET_INVOICE_ERROR,
    error,
  };
}

export function hideConfirmationPopup(): HideConfirmationPopup {
  return {
    type: GetInvoiceActionTypes.HIDE_CONFIRMATION_POPUP,
  };
}

export function isSuccessPopup(): IsSuccessPopup {
  return {
    type: GetInvoiceActionTypes.IS_SUCCESS_POPUP,
  };
}

export function makePaymentRequest(
  payment: MakePaymentInput,
): MakePaymentRequest {
  return {
    type: GetInvoiceActionTypes.MAKE_PAYMENT_INVOICE_REQUEST,
    payment,
  };
}

export function makePaymentSucceeded(
  payment: InvoiceDetails,
): MakePaymentSuccess {
  return {
    type: GetInvoiceActionTypes.MAKE_PAYMENT_INVOICE_SUCCESS,
    payment,
  };
}

export function makePaymentFailed(error: Error): MakePaymentError {
  return {
    type: GetInvoiceActionTypes.MAKE_PAYMENT_INVOICE_ERROR,
    error,
  };
}

export function resetError(): MakePaymentResetError {
  return {
    type: GetInvoiceActionTypes.MAKE_PAYMENT_RESET_ERROR,
  };
}

export function resetMakePayment(): MakePaymentReset {
  return {
    type: GetInvoiceActionTypes.MAKE_PAYMENT_RESET,
  };
}

export function revokePaymentRequest(id: string): RevokePaymentRequest {
  return {
    type: GetInvoiceActionTypes.REVOKE_PAYMENT_REQUEST,
    id,
  };
}

export function revokePaymentSucceeded(
  invoice: InvoiceDetails,
): RevokePaymentSuccess {
  return {
    type: GetInvoiceActionTypes.REVOKE_PAYMENT_SUCCESS,
    invoice,
  };
}

export function revokePaymentFailed(error: Error): RevokePaymentError {
  return {
    type: GetInvoiceActionTypes.REVOKE_PAYMENT_ERROR,
    error,
  };
}

export function showConfirmationPopup(value: boolean): ShowConfirmationPopup {
  return {
    type: GetInvoiceActionTypes.SHOW_CONFIRMATION_POPUP,
    value,
  };
}

export function setPaymentField(
  payment: MakePaymentInput,
): SetPaymentFieldsRequest {
  return {
    type: GetInvoiceActionTypes.SET_PAYMENT_FIELDS,
    payment,
  };
}

export function updateInvoiceFields(
  updates: UpdateInvoiceFields,
): UpdateInvoiceFieldsRequest {
  return {
    type: GetInvoiceActionTypes.UPDATE_INVOICE_FIELDS,
    updates,
  };
}
