/* External dependencies */
import gql from 'graphql-tag';

export const cargoDetailQuery = gql`
  query getCargoByVehicleId($input: GetCargoInput!) {
    getCargoByVehicleId(input: $input) {
      cargoEscort
      cargoNetWeight
      cargoSlots
      consignees {
        cargoWeight
        departureCountry
        name
        products
        slots
        invoice {
          applicationNumber
          declarationNumber
          file
          paymentAmount
          applicationNumber
          declarationNumber
          file
        }
      }
      registrationDate
      vehicle {
        id
        arrivalDate
        vehiclePlateNumber
      }
      operator {
        id
      }
    }
  }
`;
