/* Local dependencies */
import { UIException } from '../../../redux/exceptions';

export class FirstNameRequiredException extends UIException {
  code = 'FirstNameRequiredException';
}

export class LastNameRequiredException extends UIException {
  code = 'LastNameRequiredException';
}

export class RoleRequiredException extends UIException {
  code = 'RoleRequiredException';
}

export class StatusRequiredException extends UIException {
  code = 'StatusRequiredException';
}

export class DuplicateUserException extends UIException {
  code = 'DuplicateUserException';
}
