// External dependencies
import { filter, map, Observable, switchMap } from 'rxjs';

// Local dependenceis
import { getClient } from '../../../clients/bts';
import { RootState } from '../../../redux/store';
import { updateVehicleFields } from '../../create-vehicle/redux/actions';
import {
  ListVehicleProfilesActions,
  ListVehicleProfilesActionTypes,
  listVehicleProfilesFailed,
  ListVehicleProfilesFilter,
  listVehicleProfilesSucceeded,
  ListVehicleProfilesSuccess,
} from './actions';
import { listVehilceProfilesQuery } from './query';

export default function listVehicleProfilesEpic(
  actions$: Observable<ListVehicleProfilesActions>,
  state$: { value: RootState },
) {
  return actions$.pipe(
    filter(
      (actions: ListVehicleProfilesActions) =>
        actions.type ===
        ListVehicleProfilesActionTypes.LIST_VEHICLE_PROFILES_REQUEST,
    ),
    switchMap(() => {
      const { filter, currentPage, searchString, size } =
        state$.value.vehicleProfiles;

      return listVehicleProfiles(filter, currentPage, searchString, size).catch(
        listVehicleProfilesFailed,
      );
    }),
  );
}

export function changeVehicleTareWeight(
  actions$: Observable<ListVehicleProfilesActions>,
  state$: { value: RootState },
) {
  return actions$.pipe(
    filter(
      (actions: ListVehicleProfilesActions) =>
        actions.type === ListVehicleProfilesActionTypes.SELECT_VEHICLE_PROFILE,
    ),

    map(() => {
      const { selectedVehicleProfile } = state$.value.vehicleProfiles;

      return updateVehicleFields({
        tareWeight: selectedVehicleProfile?.tareWeight,
      });
    }),
  );
}

export function resetVehicleTareWeight(
  actions$: Observable<ListVehicleProfilesActions>,
) {
  return actions$.pipe(
    filter(
      (actions: ListVehicleProfilesActions) =>
        actions.type ===
        ListVehicleProfilesActionTypes.RESET_SELECT_VEHICLE_PROFILE,
    ),

    map(() =>
      updateVehicleFields({
        tareWeight: 0,
      }),
    ),
  );
}

export async function listVehicleProfiles(
  filter: ListVehicleProfilesFilter,
  currentPage: number,
  searchString: string,
  size: number,
): Promise<ListVehicleProfilesSuccess> {
  const graphQLClient = await getClient();

  let variables: any = {
    input: {
      from: (currentPage - 1) * size,
      size,
      query: searchString,
    },
  };

  if (filter.vehiclePlateNumber || filter.trailerPlateNumber) {
    variables = {
      input: {
        from: (currentPage - 1) * size,
        filter,
        size,
        query: searchString,
      },
    };
  }

  const {
    data: {
      listVehicleProfiles: { total, vehicleProfiles },
    },
  } = await graphQLClient.query({
    query: listVehilceProfilesQuery,
    variables,
  });

  return listVehicleProfilesSucceeded(vehicleProfiles, total);
}
