/* External dependencies */
import moment from 'moment';

/* Local dependencies */
import formatMessage from '../../intl/formatMessage';

export const DATE_FORMAT: string = 'DD.MM.YY';
export const DATE_FORMAT_FULL_YEAR: string = 'DD.MM.YYYY';
export const DATE_FORMAT_FOR_LINK = 'DD-MM-YYYY';
export const TIME_FORMAT: string = 'HH:mm';
// The default timezone should point to Bishkek, Kyrgyzstan.
export const DEFAULT_TIMEZONE: string = '+06:00';

// it formats as '26.02.21 в 14:40'
export default function renderDate(arrivalDate: any) {
  return formatMessage('dateTime', {
    valueDate: moment(arrivalDate)
      .utcOffset(DEFAULT_TIMEZONE)
      .format(DATE_FORMAT),
    valueTime: moment(arrivalDate)
      .utcOffset(DEFAULT_TIMEZONE)
      .format(TIME_FORMAT),
  });
}

export function formatDateLink(arrivalDate: any) {
  return moment(arrivalDate)
    .utcOffset(DEFAULT_TIMEZONE)
    .format(DATE_FORMAT_FOR_LINK);
}
