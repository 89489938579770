// External dependencies
import { gql } from 'graphql-tag';

export const createConsigneeGoodsSpecificationMutatoin = gql`
  mutation createConsigneeGoodsSpecification(
    $input: CreateConsigneeGoodsSpecificationInput!
  ) {
    createConsigneeGoodsSpecification(input: $input) {
      exportFile {
        url
      }
    }
  }
`;
