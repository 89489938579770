// External dependencies
import { filter, switchMap } from 'rxjs/operators';

// Local dependencies
import { getClient } from '../../../../clients/bts';
import {
  ConsigneeGoodSpecifications,
  CreateConsigneeGoodsSpecificationInput,
} from '../../types';
import {
  CreateConsigneeGoodsSpecificationAction,
  CreateConsigneeGoodsSpecificationActionTypes,
  createConsigneeGoodsSpecFailed,
  createConsigneeGoodsSpecSucceeded,
} from './actions';
import { createConsigneeGoodsSpecificationMutatoin } from './mutation';

export function createConsigneeGoodsSpecificationEpic(actions$, state$) {
  return actions$.pipe(
    filter(
      (action: CreateConsigneeGoodsSpecificationAction) =>
        action.type ===
        CreateConsigneeGoodsSpecificationActionTypes.CREATE_CONSIGNEE_GOODS_SPEC_REQUEST,
    ),
    switchMap(async () => {
      const { consigneeGoodsSpecification } =
        state$.value.consigneeGoodsSpecification;
      try {
        const data = await createConsigneeGoodsSpecification(
          consigneeGoodsSpecification,
        );
        return createConsigneeGoodsSpecSucceeded(data);
      } catch (error) {
        return createConsigneeGoodsSpecFailed(error);
      }
    }),
  );
}

async function createConsigneeGoodsSpecification(
  consigneeGoodsSpecification: CreateConsigneeGoodsSpecificationInput,
): Promise<ConsigneeGoodSpecifications> {
  const graphQLClient = await getClient();

  const {
    data: { createConsigneeGoodsSpecification },
  } = await graphQLClient.mutate({
    mutation: createConsigneeGoodsSpecificationMutatoin,
    variables: {
      input: consigneeGoodsSpecification,
    },
  });

  return createConsigneeGoodsSpecification;
}
