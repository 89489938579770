/* External dependencies */
import { debounceTime, filter, switchMap } from 'rxjs/operators';

/* Local dependencies */
import { getClient } from '../../../clients/bts';
import {
  ListVehicles,
  ListVehiclesAction,
  ListVehiclesActionTypes,
  listVehiclesFailed,
  listVehiclesSucceeded,
  ListVehiclesSuccess,
} from './actions';
import { listVehiclesQuery } from './queries';

export default function listVehiclesEpic(action$, state$) {
  return action$.pipe(
    filter(
      (action: ListVehiclesAction) =>
        action.type === ListVehiclesActionTypes.LIST_VEHICLES_REQUEST,
    ),
    debounceTime(500),
    switchMap((action: ListVehicles) =>
      listVehicles(action, state$.value.vehicles.size).catch((error) =>
        listVehiclesFailed(error),
      ),
    ),
  );
}

export async function listVehicles(
  { currentPage, searchString, filter, sort }: ListVehicles,
  size: number,
): Promise<ListVehiclesSuccess> {
  const graphQLClient = await getClient();
  const {
    data: {
      listVehicles: { total, vehicles },
    },
  } = await graphQLClient.query({
    query: listVehiclesQuery,
    variables: {
      from: (currentPage - 1) * size,
      query: searchString,
      size,
      filter,
      sort,
    },
  });

  return listVehiclesSucceeded(total, vehicles);
}
