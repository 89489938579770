/* External dependencies */
import { filter, switchMap } from 'rxjs/operators';

/* Local dependencies */
import { getClient } from '../../../clients/bts';
import {
  CreateVehicleAction,
  CreateVehicleActionTypes,
  createVehicleFailed,
  CreateVehicleInput,
  createVehicleSucceeded,
  Vehicle,
} from './actions';
import {
  DuplicateVehicleException,
  UserPhoneNumberInvalidException,
} from './exceptions';
import { createVehicleMutation } from './mutations';

export default function createVehicleEpic(action$) {
  return action$.pipe(
    filter(
      (action: CreateVehicleAction) =>
        action.type === CreateVehicleActionTypes.CREATE_VEHICLE_REQUEST,
    ),
    switchMap((action: CreateVehicleAction) =>
      createVehicle(action)
        .then(createVehicleSucceeded)
        .catch((error) => {
          if (error.graphQLErrors && error.graphQLErrors.length) {
            const [{ errorType, message }] = error.graphQLErrors;
            const invalidPhoneNumberError =
              /Variable 'phone' has an invalid value./.test(message);

            if (errorType === 'ConditionalCheckFailedException') {
              return createVehicleFailed(new DuplicateVehicleException());
            }

            if (invalidPhoneNumberError) {
              return createVehicleFailed(new UserPhoneNumberInvalidException());
            }

            return createVehicleFailed(new Error(message));
          }

          return createVehicleFailed(error);
        }),
    ),
  );
}

export async function createVehicle(action): Promise<Vehicle> {
  const graphQLClient = await getClient();
  const { vehicle } = action;

  //Checking which fields have values
  const input = Object.keys(vehicle).reduce((input, key) => {
    if (vehicle.hasOwnProperty(key) && typeof vehicle[key] !== 'undefined') {
      input[key] = vehicle[key];
    }

    return input;
  }, {});

  const vehicleToCreate = input as CreateVehicleInput;

  if (vehicleToCreate.images && vehicleToCreate.images.length) {
    vehicleToCreate.images.forEach((image) => {
      image.type = vehicleToCreate.weightType!;
    });
  }
  delete input?.status;

  const {
    data: { createVehicle },
  } = await graphQLClient.mutate({
    mutation: createVehicleMutation,
    variables: {
      input: vehicleToCreate,
    },
    fetchPolicy: 'no-cache',
  });

  return createVehicle as Vehicle;
}
