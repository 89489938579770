/* External dependencies */
import update from 'immutability-helper';

/* Local dependencies */
import { DEFAULT_PAGE_SIZE } from '../../common/helpers';
import {
  DEFAULT_INVOICES_FILTER,
  ListInvoicesAction,
  ListInvoicesActionTypes,
  ListInvoicesFilter,
} from './actions';

export interface InvoicesState {
  invoices: Array<any>;
  currentPage: number | undefined;
  filter?: ListInvoicesFilter;
  error?: Error;
  loading?: boolean;
  searchString: string | undefined;
  selectedItems: Array<any>;
  size: number;
  total: number;
}

export const initialInvoicesState: InvoicesState = {
  invoices: [],
  currentPage: 1,
  filter: DEFAULT_INVOICES_FILTER,
  loading: true,
  searchString: '',
  selectedItems: [],
  size: DEFAULT_PAGE_SIZE,
  total: 0,
};

export default function invoicesReducer(
  state = initialInvoicesState,
  action: ListInvoicesAction,
) {
  switch (action.type) {
    case ListInvoicesActionTypes.LIST_INVOICES_REQUEST:
      const { currentPage, filter, searchString } = action;

      return update(state, {
        $unset: ['error'],
        loading: { $set: true },
        currentPage: { $set: currentPage },
        searchString: { $set: searchString },
        filter: { $set: filter },
      });

    case ListInvoicesActionTypes.LIST_INVOICES_SUCCESS:
      return update(state, {
        loading: { $set: false },
        invoices: { $set: action.invoices },
        total: { $set: action.total },
      });

    case ListInvoicesActionTypes.LIST_INVOICES_ERROR:
      return update(state, {
        error: { $set: action.error },
        loading: { $set: false },
      });

    case ListInvoicesActionTypes.SELECT_INVOICE:
      return update(state, {
        selectedItems: { $set: [action.invoice] },
      });

    default:
      return state;
  }
}
