/* External dependencies */
import gql from 'graphql-tag';

export const listUsersQuery = gql`
  query listUsers($from: Int, $query: String, $size: Int) {
    listUsers(from: $from, query: $query, size: $size) {
      total
      users {
        firstName
        id
        lastName
        phone
        role
      }
    }
  }
`;
