/* Local dependencies */
import { Warehouse, WarehouseStatus } from '../../warehouses/types';

export enum WarehouseActionTypes {
  GET_WAREHOUSE_SUCCESS = 'GET_WAREHOUSE_SUCCESS',
  RESET_WAREHOUSE_DETAILS = 'RESET_WAREHOUSE_DETAILS',
  UPDATE_WAREHOUSE_REQUEST = 'UPDATE_WAREHOUSE_REQUEST',
  UPDATE_WAREHOUSE_SUCCESS = 'UPDATE_WAREHOUSE_SUCCESS',
  UPDATE_WAREHOUSE_FAILURE = 'UPDATE_WAREHOUSE_FAILURE',
  CHANGE_WAREHOUSE_NAME = 'CHANGE_WAREHOUSE_NAME',
  CHANGE_WAREHOUSE_ACTION = 'CHANGE_WAREHOUSE_ACTION',
  RESET_WAREHOUSE_ERROR = 'RESET_WAREHOUSE_ERROR',
}

export enum WarehouseAction {
  UPDATE = 'UPDATE',
  DEACTIVATE = 'DEACTIVATE',
  ACTIVATE = 'ACTIVATE',
}

export interface UpdateWarehouseInput {
  id: string;
  status?: WarehouseStatus;
  name?: string;
}

export interface GetWarehouseSuccess {
  type: WarehouseActionTypes.GET_WAREHOUSE_SUCCESS;
  warehouse;
}

export interface ResetWarehouseDetails {
  type: WarehouseActionTypes.RESET_WAREHOUSE_DETAILS;
}

export interface UpdateWarehouse {
  type: WarehouseActionTypes.UPDATE_WAREHOUSE_REQUEST;
  warehouse: UpdateWarehouseInput;
}

export interface UpdateWarehouseSuccess {
  type: WarehouseActionTypes.UPDATE_WAREHOUSE_SUCCESS;
  warehouse: Warehouse;
}

export interface UpdateWarehouseFailure {
  type: WarehouseActionTypes.UPDATE_WAREHOUSE_FAILURE;
  error: Error;
}

export interface ChangeWarehouseName {
  type: WarehouseActionTypes.CHANGE_WAREHOUSE_NAME;
  name: string;
}

export interface ChangeWarehouseAction {
  type: WarehouseActionTypes.CHANGE_WAREHOUSE_ACTION;
  action: WarehouseAction;
}

export interface ResetWarehouseError {
  type: WarehouseActionTypes.RESET_WAREHOUSE_ERROR;
}

export type WarehouseActions =
  | GetWarehouseSuccess
  | ResetWarehouseDetails
  | UpdateWarehouse
  | UpdateWarehouseSuccess
  | UpdateWarehouseFailure
  | ChangeWarehouseName
  | ChangeWarehouseAction
  | ResetWarehouseError;

export function getWarehouseSucceeded(
  warehouse: Warehouse,
): GetWarehouseSuccess {
  return {
    type: WarehouseActionTypes.GET_WAREHOUSE_SUCCESS,
    warehouse,
  };
}

export function resetWarehouseDetails(): ResetWarehouseDetails {
  return {
    type: WarehouseActionTypes.RESET_WAREHOUSE_DETAILS,
  };
}

export function updateWarehouse(
  warehouse: UpdateWarehouseInput,
): UpdateWarehouse {
  return {
    type: WarehouseActionTypes.UPDATE_WAREHOUSE_REQUEST,
    warehouse,
  };
}

export function updateWarehouseSuccess(warehouse): UpdateWarehouseSuccess {
  return {
    type: WarehouseActionTypes.UPDATE_WAREHOUSE_SUCCESS,
    warehouse,
  };
}

export function updateWarehouseFailure(error: Error): UpdateWarehouseFailure {
  return {
    type: WarehouseActionTypes.UPDATE_WAREHOUSE_FAILURE,
    error,
  };
}

export function changeWarehouseName(name: string): ChangeWarehouseName {
  return {
    type: WarehouseActionTypes.CHANGE_WAREHOUSE_NAME,
    name,
  };
}

export function changeWarehouseAction(
  action: WarehouseAction,
): ChangeWarehouseAction {
  return {
    type: WarehouseActionTypes.CHANGE_WAREHOUSE_ACTION,
    action,
  };
}

export function resetError(): ResetWarehouseError {
  return {
    type: WarehouseActionTypes.RESET_WAREHOUSE_ERROR,
  };
}
