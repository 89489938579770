// Local dependencies
import { dynamicFields } from '../../createContracts/helper';
import { transformTariffs } from '../../helper';
import { Contract } from '../../types';

export enum GetContractActionsTypes {
  GET_CONTRACT_REQUEST = 'GET_CONTRACT_REQUEST',
  GET_CONTRACT_SUCCESS = 'GET_CONTRACT_SUCCESS',
  GET_CONTRACT_ERROR = 'GET_CONTRACT_ERROR',
  RESET_GET_CONTRACT = 'RESET_GET_CONTRACT',
  UPDATE_CONTRACT_FIELDS = 'UPDATE_CONTRACT_FIELDS',
  UPDATE_CONTRACT_REQUEST = 'UPDATE_CONTRACT_REQUEST',
  UPDATE_CONTRACT_SUCCESS = 'UPDATE_CONTRACT_SUCCESS',
  UPDATE_CONTRACT_GET_NEW = 'UPDATE_CONTRACT_GET_NEW',
  UPDATE_CONTRACT_ERROR = 'UPDATE_CONTRACT_ERROR',
  RESET_UPDATE_CONTRACT = 'RESET_UPDATE_CONTRACT',
  RESET_UPDATE_CONTRACT_ERROR = 'RESET_UPDATE_CONTRACT_ERROR',
}

export type GetContractActions =
  | GetContract
  | GetContractSuccess
  | GetContractError
  | ResetGetContract
  | UpdateContractRequest
  | UpdateContractSuccess
  | UpdateContractError
  | ResetUpdateContract
  | UpdateContractFields
  | UpdateContractGetNewContract
  | ResetUpdateContractError;

export interface GetContract {
  type: GetContractActionsTypes.GET_CONTRACT_REQUEST;
  id: string;
}

export interface GetContractSuccess {
  type: GetContractActionsTypes.GET_CONTRACT_SUCCESS;
  contract: Contract;
}

export interface GetContractError {
  type: GetContractActionsTypes.GET_CONTRACT_ERROR;
  error: Error;
}

export interface ResetGetContract {
  type: GetContractActionsTypes.RESET_GET_CONTRACT;
}

export function getContract(id: string): GetContract {
  return {
    type: GetContractActionsTypes.GET_CONTRACT_REQUEST,
    id,
  };
}

export function getContractSucceeded(contract: Contract): GetContractSuccess {
  const tariffs = dynamicFields(contract);
  const newData = { ...contract, tariffs: tariffs };

  return {
    type: GetContractActionsTypes.GET_CONTRACT_SUCCESS,
    contract: newData,
  };
}

export function getContractFailed(error): GetContractError {
  return {
    type: GetContractActionsTypes.GET_CONTRACT_ERROR,
    error,
  };
}

export function resetGetContract(): ResetGetContract {
  return {
    type: GetContractActionsTypes.RESET_GET_CONTRACT,
  };
}

export interface UpdateContractRequest {
  type: GetContractActionsTypes.UPDATE_CONTRACT_REQUEST;
  contract: Contract;
}

export interface UpdateContractSuccess {
  type: GetContractActionsTypes.UPDATE_CONTRACT_SUCCESS;
  contract: Contract;
}

export interface UpdateContractError {
  type: GetContractActionsTypes.UPDATE_CONTRACT_ERROR;
  updateError: Error;
}

export interface ResetUpdateContract {
  type: GetContractActionsTypes.RESET_UPDATE_CONTRACT;
}

export interface ResetUpdateContractError {
  type: GetContractActionsTypes.RESET_UPDATE_CONTRACT_ERROR;
}

export interface UpdateContractFields {
  type: GetContractActionsTypes.UPDATE_CONTRACT_FIELDS;
  updates;
}

export interface UpdateContractGetNewContract {
  type: GetContractActionsTypes.UPDATE_CONTRACT_GET_NEW;
  contract: Contract;
}

export function updateContract(
  updateContract: Contract,
): UpdateContractRequest {
  const newTarifss = transformTariffs(updateContract, false);
  let newContract = {
    ...updateContract,
    tariffs: newTarifss,
  };

  return {
    type: GetContractActionsTypes.UPDATE_CONTRACT_REQUEST,
    contract: newContract,
  };
}

export function updateContractSucceeded(
  contract: Contract,
): UpdateContractSuccess {
  return {
    type: GetContractActionsTypes.UPDATE_CONTRACT_SUCCESS,
    contract,
  };
}

export function updateContractFailed(error: Error): UpdateContractError {
  return {
    type: GetContractActionsTypes.UPDATE_CONTRACT_ERROR,
    updateError: error,
  };
}

export function updateContractGetNewContract(
  contract: Contract,
): UpdateContractGetNewContract {
  return {
    type: GetContractActionsTypes.UPDATE_CONTRACT_GET_NEW,
    contract,
  };
}

export function resetUpdateContract(): ResetUpdateContract {
  return {
    type: GetContractActionsTypes.RESET_UPDATE_CONTRACT,
  };
}

export function resetUpdateContractError(): ResetUpdateContractError {
  return {
    type: GetContractActionsTypes.RESET_UPDATE_CONTRACT_ERROR,
  };
}

export function updateContractFields(updates): UpdateContractFields {
  return {
    type: GetContractActionsTypes.UPDATE_CONTRACT_FIELDS,
    updates,
  };
}
