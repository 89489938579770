/* Local dependencies */
import { Consignee } from '../../create-cargo/redux/actions';
import { Vehicle } from '../../create-vehicle/redux/actions';
import { VehicleStatus } from '../../create-vehicle/vehicles_types';
import { PaymentStatus } from '../invoice/common/invoice-types';

export enum ListInvoicesActionTypes {
  LIST_INVOICES_REQUEST = 'LIST_INVOICES_REQUEST',
  LIST_INVOICES_SUCCESS = 'LIST_INVOICES_SUCCESS',
  LIST_INVOICES_ERROR = 'LIST_INVOICES_ERROR',
  SELECT_INVOICE = 'SELECT_INVOICE',
  SORT_INVOICES_BY = 'SORT_INVOICES_BY',
}

export interface Invoice {
  consignee?: Consignee;
  id: string;
  paymentStatus: PaymentStatus;
  vehicle?: Vehicle;
}

export interface ListInvoicesFilter {
  paymentStatus: PaymentStatus;
  vehicleStatus?: VehicleStatus;
}

export interface ListInvoices {
  type: ListInvoicesActionTypes.LIST_INVOICES_REQUEST;
  currentPage?: number;
  searchString?: string;
  filter?: ListInvoicesFilter;
}

export interface ListInvoicesSuccess {
  type: ListInvoicesActionTypes.LIST_INVOICES_SUCCESS;
  invoices: Invoice[];
  total: number;
}

export interface ListInvoicesError {
  type: ListInvoicesActionTypes.LIST_INVOICES_ERROR;
  error: Error;
}

export interface ListInvoicesSort {
  type: ListInvoicesActionTypes.SORT_INVOICES_BY;
  field: string;
}

export interface SelectInvoice {
  type: ListInvoicesActionTypes.SELECT_INVOICE;
  invoice: Invoice;
}

export type ListInvoicesAction =
  | ListInvoices
  | ListInvoicesSuccess
  | ListInvoicesError
  | SelectInvoice;

export const DEFAULT_INVOICES_FILTER: ListInvoicesFilter = {
  paymentStatus: PaymentStatus.UNPAID,
};

export function listInvoices(
  searchString: string = '',
  currentPage: number = 1,
  filter: ListInvoicesFilter = DEFAULT_INVOICES_FILTER,
): ListInvoices {
  return {
    type: ListInvoicesActionTypes.LIST_INVOICES_REQUEST,
    searchString,
    currentPage,
    filter,
  };
}

export function listInvoicesSucceeded(
  total: number,
  invoices: Invoice[],
): ListInvoicesSuccess {
  return {
    type: ListInvoicesActionTypes.LIST_INVOICES_SUCCESS,
    invoices,
    total,
  };
}

export function listInvoicesFailed(error: Error): ListInvoicesError {
  return {
    type: ListInvoicesActionTypes.LIST_INVOICES_ERROR,
    error,
  };
}

export function sortInvoicesBy(field) {
  return {
    type: ListInvoicesActionTypes.SORT_INVOICES_BY,
    field,
  };
}

export function selectInvoice(invoice: Invoice): SelectInvoice {
  return {
    type: ListInvoicesActionTypes.SELECT_INVOICE,
    invoice,
  };
}
