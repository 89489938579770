/* External dependencies */
import gql from 'graphql-tag';

export const listContractsQuery = gql`
  query listContracts($input: ListContractsInput!) {
    listContracts(input: $input) {
      total
      contracts {
        id
        consigneeProfile {
          id
          name
        }
        fields {
          id
          name
          order
          unit
          unitCount
          value
        }
        type
        startDate
        title
      }
    }
  }
`;
