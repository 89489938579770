/* External dependencies */
import update from 'immutability-helper';
import groupBy from 'lodash/groupBy';
import sortBy from 'lodash/sortBy';
import moment, { duration, DurationInputArg2 } from 'moment';

/* Local dependencies */
import { Consignee } from '../../../create-cargo/redux/actions';
import {
  Contract,
  ConsigneeContractAction,
  GetConsigneeContractActionTypes,
  ContractField,
  MakePaymentInput,
  PaymentDocumentType,
  PriceUnit,
} from './actions';

export interface ConsigneeContractState {
  arrivalDate?: number;
  consignee?: Consignee;
  contract?: Contract;
  error?: Error;
  fields?: ContractField[];
  loading?: boolean;
  payment?: MakePaymentInput;
  totalSum: string;
}

export const initialContractState: ConsigneeContractState = {
  loading: false,
  totalSum: 0,
};

export default function contractReducer(
  state = initialContractState,
  action: ConsigneeContractAction,
) {
  switch (action.type) {
    case GetConsigneeContractActionTypes.GET_CONSIGNEE_CONTRACT_REQUEST:
      return update(state, {
        $unset: ['error'],
        consignee: { $set: action.consignee },
        loading: { $set: true },
      });

    case GetConsigneeContractActionTypes.GET_CONSIGNEE_CONTRACT_SUCCESS:
      const { contract } = action;
      let totalSum = 0;
      const now = moment();
      const startDate = moment(contract.startDate);
      // There could be multiple fields with the same `id` which means
      // they should be used to calculate the sum together.
      // Therefore, we are grouping fields by `id`.
      const fields = groupBy(contract.fields, 'id');

      Object.keys(fields).forEach((i, id) => {
        // There is a specific order fields should be to calculate
        // the sum.
        fields[i] = sortBy(fields[id], 'order');

        fields[i].forEach((field) => {
          let { unit, unitCount, value } = field;
          // Reset the sum for this field.
          field.sum = 0;

          // `unit` is optional. Defaults to `1`.
          !unitCount && (unitCount = field.unitCount = 1);

          switch (unit) {
            case 'SOM':
              field.sum += parseFloat(value);
              break;
            case 'SOM_DAY':
            case 'SOM_HOUR':
            case 'SOM_WEEK':
            case 'SOM_MONTH':
            case 'SOM_YEAR':
              let [, period] = unit.match(/SOM_(.+)/);
              // `period` for `moment` should be in lowercase
              period = period.toLowerCase();

              if (unitCount === 1) {
                // If the unit count is `1`, then we need to add
                // the value for each day.
                do {
                  field.sum += parseFloat(value);
                  startDate.add(unitCount, period as DurationInputArg2);
                } while (startDate.isBefore(now));
              } else {
                // If the unit count is other than `1`, then we need
                // to add the value once for that number of days.
                field.sum += parseFloat(value);
                // Move the days forward for the number of
                startDate.add(unitCount, period as DurationInputArg2);
              }
              break;
            default:
              throw new Error(`Unexpected contract field unit "${unit}".`);
          }

          totalSum += field.sum;
        });
      });

      return update(state, {
        loading: { $set: false },
        contract: { $set: action.contract },
        fields: { $set: fields },
        totalSum: { $set: totalSum },
      });

    case GetConsigneeContractActionTypes.GET_CONSIGNEE_CONTRACT_ERROR:
      return update(state, {
        error: { $set: action.error },
        loading: { $set: false },
      });

    case GetConsigneeContractActionTypes.MAKE_PAYMENT_REQUEST:
      return update(state, {
        $unset: ['error'],
        loading: { $set: true },
      });

    case GetConsigneeContractActionTypes.MAKE_PAYMENT_SUCCESS:
      return update(state, {
        loading: { $set: false },
        payment: { $set: action.payment },
      });

    case GetConsigneeContractActionTypes.MAKE_PAYMENT_ERROR:
      return update(state, {
        error: { $set: action.error },
        loading: { $set: false },
      });

    default:
      return state;
  }
}
