/* External dependencies */
import gql from 'graphql-tag';

export enum WeightType {
  GROSS = 'GROSS',
  TARE = 'TARE',
}

export enum VehicleArrivalPurpose {
  ARREST = 'ARREST',
  CARGO_EXTRACTION = 'CARGO_EXTRACTION',
  CARGO_IMPORT = 'CARGO_IMPORT',
  PARKING = 'PARKING',
  PARKING_WITH_CARGO = 'PARKING_WITH_CARGO',
  WEIGHIN = 'WEIGHIN',
}

export const updateVehicleMutation = gql`
  mutation updateVehicle($input: UpdateVehicleInput!) {
    updateVehicle(input: $input) {
      arrivalDate
      arrivalPurpose
      borderCheckpoint
      driver {
        name
        phone
        role
      }
      grossWeight
      id
      operator {
        firstName
        id
        lastName
      }
      trailerPlateNumber
      vehiclePlateNumber
      weightType
    }
  }
`;
