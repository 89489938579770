/* External dependencies */
import update from 'immutability-helper';

/* Local dependencies */
import { CreateWarehouseAction, CreateWarehouseActionTypes } from './actions';
import { WarehouseNameRequiredException } from './exceptions';

export interface CreateWarehouseState {
  nameError?: Error;
  error?: Error;
  loading?: boolean;
  name: string;
  isSuccess?: boolean;
}

const initialWarehouseState: CreateWarehouseState = {
  name: '',
};

export default function createWarehouse(
  state = initialWarehouseState,
  action: CreateWarehouseAction,
) {
  switch (action.type) {
    case CreateWarehouseActionTypes.CREATE_WAREHOUSE_REQUEST:
      return update(state, {
        $unset: ['error'],
        loading: { $set: true },
      });

    case CreateWarehouseActionTypes.CREATE_WAREHOUSE_SUCCESS:
      return update(state, {
        loading: { $set: false },
        isSuccess: { $set: true },
      });

    case CreateWarehouseActionTypes.CREATE_WAREHOUSE_ERROR:
      return update(state, {
        error: { $set: action.error },
        loading: { $set: false },
      });

    case CreateWarehouseActionTypes.CREATE_WAREHOUSE_RESET_ERROR:
      return update(state, {
        $unset: ['error'],
      });

    case CreateWarehouseActionTypes.RESET_CREATE_WAREHOUSE:
      return update(state, { $set: initialWarehouseState });

    case CreateWarehouseActionTypes.UPDATE_WAREHOUSE_NAME:
      return validateName(state, action.name);

    default:
      return state;
  }
}

function validateName(state, name) {
  const change: any = {};

  if (name.length < 1) {
    change.nameError = { $set: new WarehouseNameRequiredException() };
  } else {
    change['$unset'] = ['nameError'];
  }

  return update(state, {
    ...change,
    name: { $set: name },
  });
}
