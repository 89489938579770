/* External dependencies */
import gql from 'graphql-tag';

export const listConsigneesQuery = gql`
  query listConsignees(
    $filter: ListConsigneesFilter
    $from: Int
    $query: String
    $size: Int
  ) {
    listConsignees(filter: $filter, from: $from, query: $query, size: $size) {
      total
      consignees {
        arrivalDate
        cargoWeight
        departureCountry
        id
        name
        registrationDate
        slots
        vehiclePlateNumber
        warehouse {
          id
          name
        }
      }
    }
  }
`;
