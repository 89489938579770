exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-consignees-consignee-details-index-tsx": () => import("./../../../src/pages/consignees/consignee-details/index.tsx" /* webpackChunkName: "component---src-pages-consignees-consignee-details-index-tsx" */),
  "component---src-pages-consignees-contract-index-tsx": () => import("./../../../src/pages/consignees/contract/index.tsx" /* webpackChunkName: "component---src-pages-consignees-contract-index-tsx" */),
  "component---src-pages-consignees-index-tsx": () => import("./../../../src/pages/consignees/index.tsx" /* webpackChunkName: "component---src-pages-consignees-index-tsx" */),
  "component---src-pages-contracts-contract-details-index-tsx": () => import("./../../../src/pages/contracts/contract-details/index.tsx" /* webpackChunkName: "component---src-pages-contracts-contract-details-index-tsx" */),
  "component---src-pages-contracts-create-index-tsx": () => import("./../../../src/pages/contracts/create/index.tsx" /* webpackChunkName: "component---src-pages-contracts-create-index-tsx" */),
  "component---src-pages-contracts-index-tsx": () => import("./../../../src/pages/contracts/index.tsx" /* webpackChunkName: "component---src-pages-contracts-index-tsx" */),
  "component---src-pages-customs-representative-guide-index-tsx": () => import("./../../../src/pages/customs-representative-guide/index.tsx" /* webpackChunkName: "component---src-pages-customs-representative-guide-index-tsx" */),
  "component---src-pages-dev-404-page-tsx": () => import("./../../../src/pages/dev-404-page.tsx" /* webpackChunkName: "component---src-pages-dev-404-page-tsx" */),
  "component---src-pages-goods-specifications-index-tsx": () => import("./../../../src/pages/goods-specifications/index.tsx" /* webpackChunkName: "component---src-pages-goods-specifications-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-invoices-index-tsx": () => import("./../../../src/pages/invoices/index.tsx" /* webpackChunkName: "component---src-pages-invoices-index-tsx" */),
  "component---src-pages-invoices-invoice-index-tsx": () => import("./../../../src/pages/invoices/invoice/index.tsx" /* webpackChunkName: "component---src-pages-invoices-invoice-index-tsx" */),
  "component---src-pages-login-change-password-index-tsx": () => import("./../../../src/pages/login/change-password/index.tsx" /* webpackChunkName: "component---src-pages-login-change-password-index-tsx" */),
  "component---src-pages-login-forgot-password-index-tsx": () => import("./../../../src/pages/login/forgot-password/index.tsx" /* webpackChunkName: "component---src-pages-login-forgot-password-index-tsx" */),
  "component---src-pages-login-index-tsx": () => import("./../../../src/pages/login/index.tsx" /* webpackChunkName: "component---src-pages-login-index-tsx" */),
  "component---src-pages-login-verify-code-index-tsx": () => import("./../../../src/pages/login/verify-code/index.tsx" /* webpackChunkName: "component---src-pages-login-verify-code-index-tsx" */),
  "component---src-pages-users-create-index-tsx": () => import("./../../../src/pages/users/create/index.tsx" /* webpackChunkName: "component---src-pages-users-create-index-tsx" */),
  "component---src-pages-users-index-tsx": () => import("./../../../src/pages/users/index.tsx" /* webpackChunkName: "component---src-pages-users-index-tsx" */),
  "component---src-pages-users-user-details-index-tsx": () => import("./../../../src/pages/users/user-details/index.tsx" /* webpackChunkName: "component---src-pages-users-user-details-index-tsx" */),
  "component---src-pages-vehicles-create-index-tsx": () => import("./../../../src/pages/vehicles/create/index.tsx" /* webpackChunkName: "component---src-pages-vehicles-create-index-tsx" */),
  "component---src-pages-vehicles-index-tsx": () => import("./../../../src/pages/vehicles/index.tsx" /* webpackChunkName: "component---src-pages-vehicles-index-tsx" */),
  "component---src-pages-vehicles-vehicle-details-index-tsx": () => import("./../../../src/pages/vehicles/vehicle-details/index.tsx" /* webpackChunkName: "component---src-pages-vehicles-vehicle-details-index-tsx" */),
  "component---src-pages-warehouses-create-index-tsx": () => import("./../../../src/pages/warehouses/create/index.tsx" /* webpackChunkName: "component---src-pages-warehouses-create-index-tsx" */),
  "component---src-pages-warehouses-index-tsx": () => import("./../../../src/pages/warehouses/index.tsx" /* webpackChunkName: "component---src-pages-warehouses-index-tsx" */),
  "component---src-pages-warehouses-warehouse-details-index-tsx": () => import("./../../../src/pages/warehouses/warehouse-details/index.tsx" /* webpackChunkName: "component---src-pages-warehouses-warehouse-details-index-tsx" */)
}

