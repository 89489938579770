import { of } from 'rxjs';
import { catchError, filter, switchMap } from 'rxjs/operators';

import { cleanUpSession, initClient } from '../../../clients/bts';
import {
  cognitoSignIn,
  cognitoSignOut,
  cognitoChangePassword,
  cognitoForgotPassword,
  cognitoConfirmPassword,
} from '../../../clients/cognito';
import {
  ChangePassword,
  ConfirmPassword,
  LoginAction,
  LoginActionTypes,
  LoginRequest,
  RequestVerificationCode,
  loginFailed,
  logoutSucceeded,
  logoutFailed,
  initClientFailed,
} from './actions';

export function initClientEpic(action$) {
  return action$.pipe(
    filter(
      (action: LoginAction) => action.type === LoginActionTypes.INIT_CLIENT,
    ),
    switchMap(initClient),
    catchError(() => of(initClientFailed()))
  );
}

export function loginEpic(action$) {
  return action$.pipe(
    filter(
      (action: LoginAction) => action.type === LoginActionTypes.LOGIN_REQUEST,
    ),
    switchMap((action: LoginRequest) => cognitoSignIn(action).catch(loginFailed)),
  );
}

export function changePasswordEpic(action$, state$) {
  return action$.pipe(
    filter(
      (action: LoginAction) =>
        action.type === LoginActionTypes.SET_NEW_PASSWORD,
    ),
    switchMap((action: ChangePassword) => {
      const { sessionKey, userAttributes } = state$.value.login;

      const data = {
        ...action,
        sessionKey,
        userAttributes,
      };

      return cognitoChangePassword(data).catch(loginFailed);
    }),
  );
}

export function forgotPasswordEpic(action$) {
  return action$.pipe(
    filter(
      (action: LoginAction) =>
        action.type === LoginActionTypes.REQUEST_VERIFICATION_CODE,
    ),
    switchMap((action: RequestVerificationCode) => {
      return cognitoForgotPassword(action);
    }),
    catchError((error) => of(loginFailed(error))),
  );
}

export function confirmPasswordEpic(action$, state$) {
  return action$.pipe(
    filter(
      (action: LoginAction) =>
        action.type === LoginActionTypes.CONFIRM_PASSWORD,
    ),
    switchMap((action: ConfirmPassword) => {
      const { verificationCode } = state$.value.login;

      const data = {
        ...action,
        verificationCode,
      };

      return cognitoConfirmPassword(data);
    }),
    catchError((error) => of(loginFailed(error))),
  );
}

export function logoutEpic(action$): LoginAction {
  return action$.pipe(
    filter(
      (action: LoginAction) => action.type === LoginActionTypes.LOGOUT_REQUEST,
    ),
    switchMap(() =>
      cognitoSignOut()
        .then(cleanUpSession)
        .then(logoutSucceeded)
        .catch(logoutFailed),
    ),
  );
}
