/* External dependencies */
import { filter, switchMap } from 'rxjs/operators';

/* Local dependencies */
import { getClient } from '../../../../clients/bts';
import {
  ConsigneeContractAction,
  Contract,
  GetConsigneeContractActionTypes,
  GetConsigneeContractRequest,
  MakePaymentRequest,
  Payment,
  getConsigneeContractFailed,
  getConsigneeContractSucceeded,
  makePaymentFailed,
  makePaymentSucceeded,
} from './actions';
import { getConsigneeContractQuery } from './queries';
import { makePaymentMutation } from './mutations';

export function consigneeContractEpic(action$) {
  return action$.pipe(
    filter(
      (action: ConsigneeContractAction) =>
        action.type ===
        GetConsigneeContractActionTypes.GET_CONSIGNEE_CONTRACT_REQUEST,
    ),
    switchMap((action: GetConsigneeContractRequest) =>
      getConsigneeContract(action)
        .then(getConsigneeContractSucceeded)
        .catch(getConsigneeContractFailed),
    ),
  );
}

export async function getConsigneeContract(
  action: GetConsigneeContractRequest,
): Promise<Contract> {
  const { departureCountry, id } = action.consignee;
  const graphQLClient = await getClient();

  const {
    data: { getConsigneeContract },
  } = await graphQLClient.query({
    query: getConsigneeContractQuery,
    variables: {
      input: {
        departureCountry,
        id,
      },
    },
  });

  return getConsigneeContract;
}

export function makePaymentEpic(action$) {
  return action$.pipe(
    filter(
      (action: MakePaymentRequest) =>
        action.type === GetConsigneeContractActionTypes.MAKE_PAYMENT_REQUEST,
    ),
    switchMap((action: MakePaymentRequest) =>
      makePayment(action).then(makePaymentSucceeded).catch(makePaymentFailed),
    ),
  );
}

export async function makePayment(action): Promise<Payment> {
  const graphQLClient = await getClient();
  const { payment } = action;

  //Checking which fields have values
  const input = Object.keys(payment).reduce((input, key) => {
    if (payment.hasOwnProperty(key) && typeof payment[key] !== 'undefined') {
      input[key] = payment[key];
    }

    return input;
  }, {});

  const {
    data: { makePayment },
  } = await graphQLClient.mutate({
    mutation: makePaymentMutation,
    variables: {
      input,
    },
  });

  return makePayment as Payment;
}
