/* External dependencies */
import { debounceTime, filter, switchMap } from 'rxjs/operators';

/* Local dependencies */
import { getClient } from '../../../clients/bts';
import {
  ListInvoices,
  ListInvoicesAction,
  ListInvoicesActionTypes,
  ListInvoicesSuccess,
  listInvoicesFailed,
  listInvoicesSucceeded,
  Invoice,
} from './actions';
import { listInvoicesQuery } from './queries';

export default function listInvoicesEpic(action$, state$) {
  return action$.pipe(
    filter(
      (action: ListInvoicesAction) =>
        action.type === ListInvoicesActionTypes.LIST_INVOICES_REQUEST,
    ),
    debounceTime(500),
    switchMap((action: ListInvoices) =>
      listInvoices(action, state$.value.invoices.size).catch((error) =>
        listInvoicesFailed(error),
      ),
    ),
  );
}

export async function listInvoices(
  { currentPage, filter, searchString }: ListInvoices,
  size: number,
): Promise<ListInvoicesSuccess> {
  const graphQLClient = await getClient();

  const {
    data: {
      listInvoices: { invoices, total },
    },
  } = await graphQLClient.query({
    query: listInvoicesQuery,
    variables: {
      input: {
        filter,
        from: (currentPage - 1) * size,
        query: searchString,
        size,
      },
    },
  });

  return listInvoicesSucceeded(total, invoices);
}
