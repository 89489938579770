// Local dependencies
import {
  Contract,
  ContractStatus,
  DEFAULT_LIST_CONTRACTS_FILTER,
} from '../../types';

export enum ListContractsActionTypes {
  LIST_CONTRACTS_REQUEST = 'LIST_CONTRACTS_REQUEST',
  LIST_CONTRACTS_SUCCESS = 'LIST_CONTRACTS_SUCCESS',
  LIST_CONTRACTS_ERROR = 'LIST_CONTRACTS_ERROR',
  RESET_LIST_CONTRACTS = 'RESET_LIST_CONTRACTS',
  RESET_ERROR_LIST_CONTRACTS = 'RESET_ERROR_LIST_CONTRACTS',
}

export interface ListContracts {
  type: ListContractsActionTypes.LIST_CONTRACTS_REQUEST;
  currentPage?: number;
  searchString: string;
  filter?: ListContractsFilter;
}

export interface ListContractsFilter {
  status?: ContractStatus;
}

export interface ListContractsSuccess {
  type: ListContractsActionTypes.LIST_CONTRACTS_SUCCESS;
  contracts: Contract[];
  total: number;
}

export interface ListContractsError {
  type: ListContractsActionTypes.LIST_CONTRACTS_ERROR;
  error: Error;
}

export interface ResetListContracts {
  type: ListContractsActionTypes.RESET_LIST_CONTRACTS;
}

export interface ResetErrorListContracts {
  type: ListContractsActionTypes.RESET_ERROR_LIST_CONTRACTS;
}

export type ListContractsAction =
  | ListContracts
  | ListContractsSuccess
  | ListContractsError
  | ResetListContracts
  | ResetErrorListContracts;

export function listContracts(
  searchString: string = '',
  currentPage: number = 1,
  filter: ListContractsFilter = DEFAULT_LIST_CONTRACTS_FILTER,
): ListContracts {
  return {
    type: ListContractsActionTypes.LIST_CONTRACTS_REQUEST,
    searchString,
    currentPage,
    filter,
  };
}

export function listContractsSucceeded(
  total: number,
  contracts,
): ListContractsSuccess {
  return {
    type: ListContractsActionTypes.LIST_CONTRACTS_SUCCESS,
    contracts,
    total,
  };
}

export function listContractsFailed(error: Error): ListContractsError {
  return {
    type: ListContractsActionTypes.LIST_CONTRACTS_ERROR,
    error,
  };
}

export function resetListContracts() {
  return {
    type: ListContractsActionTypes.RESET_LIST_CONTRACTS,
  };
}

export function resetError(): ResetErrorListContracts {
  return {
    type: ListContractsActionTypes.RESET_ERROR_LIST_CONTRACTS,
  };
}
