/* External dependencies */
import { of } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';

/* Local dependencies */
import { getClient } from '../../../clients/bts';
import { cleanPayload, isAdmin, isWeighman } from '../../common/helpers';
import { getUserEditableFields } from '../user-fields/common';
import {
  Vehicle,
  VehicleAction,
  VehicleActionTypes,
  getVehicleFailed,
  GetVehicleRequest,
  updateVehicleSucceeded,
  updateVehicleFailed,
  getVehicleSucceeded,
  GetDeviceRequest,
  getDeviceFailure,
  GetDeviceSuccess,
  getDeviceSucceeded,
} from './actions';
import { updateVehicleMutation } from './mutations';
import { getDeviceQuery, vehicleDetailQuery } from './queries';

export function vehicleEpic(action$, state$) {
  return action$.pipe(
    filter(
      (action: VehicleAction) =>
        action.type === VehicleActionTypes.GET_VEHICLE_REQUEST,
    ),
    switchMap((action: GetVehicleRequest) =>
      getVehicle(action)
        .then((vehicle: Vehicle) => {
          const { currentUser } = state$.value.login;
          // Editable fields unless there are values in those fields.
          let editableFields = getUserEditableFields(currentUser);

          // Admin must be able to edit any field with no restrictions.
          if (!isAdmin(currentUser)) {
            // Other users should not be able to edit fields which
            // already have values, so filter only those fields which
            // have no values yet.
            editableFields = editableFields.filter((field) => {
              if (field === 'driver') {
                return !vehicle.driver.name;
              }

              if (field === 'driverPhone') {
                return !vehicle.driver.phone;
              }

              if (isWeighman(currentUser) && field === 'grossWeight') {
                return vehicle.grossWeight === 0;
              }

              return !vehicle[field];
            });
          }

          return getVehicleSucceeded(vehicle, editableFields);
        })
        .catch((error) => of(getVehicleFailed(error))),
    ),
  );
}

export async function getVehicle({ id }: GetVehicleRequest): Promise<Vehicle> {
  const graphQLClient = await getClient();

  const {
    data: { getVehicleById },
  } = await graphQLClient.query({
    query: vehicleDetailQuery,
    variables: {
      input: {
        id,
      },
    },
  });

  return getVehicleById;
}

export function updateVehicleEpic(action$) {
  return action$.pipe(
    filter(
      (action: VehicleAction) =>
        action.type === VehicleActionTypes.UPDATE_VEHICLE_REQUEST,
    ),
    switchMap((action: VehicleAction) =>
      updateVehicle(action)
        .then(updateVehicleSucceeded)
        .catch((error) => updateVehicleFailed(error)),
    ),
  );
}

export async function updateVehicle(action): Promise<Vehicle> {
  const graphQLClient = await getClient();
  const { vehicle } = action;
  delete vehicle?.vehicleProfile;
  delete vehicle?.images;

  const {
    data: { updateVehicle },
  } = await graphQLClient.mutate({
    mutation: updateVehicleMutation,
    variables: {
      input: cleanPayload(vehicle),
    },
  });

  return updateVehicle as Vehicle;
}

export function deviceEpic(action$) {
  return action$.pipe(
    filter(
      (action: VehicleAction) =>
        action.type === VehicleActionTypes.GET_DEVICE_REQUEST,
    ),
    switchMap((action: GetDeviceRequest) =>
      getDevice(action).catch((error: Error) => getDeviceFailure(error)),
    ),
  );
}

//prettier-ignore
export async function getDevice({ id }: GetDeviceRequest): Promise<GetDeviceSuccess> {
 const graphQLClient = await getClient();
  
 const {data: {getDevice} } =  await graphQLClient.query({
    query: getDeviceQuery,
    variables: {
      input: {
        id,
      }
    }
  });

  return getDeviceSucceeded(getDevice);
}
