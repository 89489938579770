export enum VehicleArrivalPurpose {
  ARREST = 'ARREST',
  CARGO_EXTRACTION = 'CARGO_EXTRACTION',
  CARGO_IMPORT = 'CARGO_IMPORT',
  PARKING = 'PARKING',
  PARKING_WITH_CARGO = 'PARKING_WITH_CARGO',
  WEIGHIN = 'WEIGHIN',
}

export enum VehicleStatus {
  ARRIVED = 'ARRIVED',
  DEPARTED = 'DEPARTED',
}

export enum WeightType {
  GROSS = 'GROSS',
  TARE = 'TARE',
}

export interface VehicleImages {
  back: string;
  front: string;
}

export interface Vehicle {
  arrivalDate: number;
  vehiclePlateNumber: string;
  trailerPlateNumber: string;
  arrivalPurpose: string;
  grossWeight: number;
  netWeight: number;
  images?: VehicleImages;
  driver: {
    name: string;
    phone?: string;
  };
  actFormNumber?: string;
  departureCountry: string;
  borderCheckpoint?: string;
  operator: {
    id?: string;
    firstName: string;
    lastName: string;
  };
  status: VehicleStatus;
  weightType: string;
}
