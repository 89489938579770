// Local dependencies
import { Consignee } from '../create-cargo/redux/actions';

export enum ContractStatus {
  ACTIVE_CONTRACTS = 'ACTIVE_CONTRACTS',
  EXPIRING_CONTRACTS = 'EXPIRING_CONTRACTS',
  ARCHIVED_CONTRACTS = 'ARCHIVED_CONTRACTS',
}

export interface Sort {
  field: string;
  direction: SortDirection;
}

export enum SortDirection {
  ASC = 'ASC',
  DESC = 'DESC',
}

export interface ListContractsFilter {
  status: ContractStatus;
}

export const DEFAULT_LIST_CONTRACTS_FILTER = {
  status: ContractStatus.ACTIVE_CONTRACTS,
};

export const DEFAULT_LIST_CONTRACTS_SORT = {
  field: 'startDate',
  direction: SortDirection.ASC,
};

export enum ContractTypePerson {
  ENTITY = 'ENTITY',
  NATURALPERSON = 'NATURALPERSON',
}

export enum ContractStatusTabs { // названия табов
  VALID_CONTRACTS = 'VALID_CONTRACTS', // you need to check how it should be in AppSync
  EXPIRING_CONTRACTS = 'EXPIRING_CONTRACTS',
  ARCHIVE = 'ARCHIVE',
}

export interface Price {
  value: string;
  currency: string;
}

enum PriceUnit {
  SOM = 'SOM',
  SOM_DAY = 'SOM_DAY',
  SOM_FIRST_OPERATION = 'SOM_FIRST_OPERATION',
  SOM_HOUR = 'SOM_HOUR',
  SOM_KG = 'SOM_KG',
  SOM_KG_DAY = 'SOM_KG_DAY',
  SOM_MONTH = 'SOM_MONTH',
  SOM_NEXT_OPERATION = 'SOM_NEXT_OPERATION',
  SOM_PROCEDURE = 'SOM_PROCEDURE',
  SOM_SET = 'SOM_SET',
  SOM_THREE_DAYS = 'SOM_THREE_DAYS',
  SOM_WEEK = 'SOM_WEEK',
  SOM_UNIT = 'SOM_UNIT',
  SOM_YEAR = 'SOM_YEAR',
}

export const units = [
  PriceUnit.SOM,
  PriceUnit.SOM_DAY,
  PriceUnit.SOM_HOUR,
  PriceUnit.SOM_KG,
  PriceUnit.SOM_KG_DAY,
  PriceUnit.SOM_MONTH,
  PriceUnit.SOM_FIRST_OPERATION,
  PriceUnit.SOM_NEXT_OPERATION,
  PriceUnit.SOM_PROCEDURE,
  PriceUnit.SOM_SET,
  PriceUnit.SOM_THREE_DAYS,
  PriceUnit.SOM_WEEK,
  PriceUnit.SOM_UNIT,
  PriceUnit.SOM_YEAR,
];

export enum ContractType {
  TYPICAL_CONTRACT = 'TYPICAL_CONTRACT',
  CUSTOMS_DECLARATION = 'CUSTOMS_DECLARATION',
  CUSTOMS_WAREHOUSE = 'CUSTOMS_WAREHOUSE',
  TEMPORARY_STORAGE = 'TEMPORARY_STORAGE',
}

export enum ConsigneeType {
  COMPANY = 'COMPANY',
  INDIVIDUAL = 'INDIVIDUAL',
}

export enum EventType {
  VEHICLE_ARRIVAL = 'VEHICLE_ARRIVAL',
  VEHICLE_WEIGHING = 'VEHICLE_WEIGHING',
  VEHICLE_WEIGHING_WITH_CARGO = 'VEHICLE_WEIGHING_WITH_CARGO',
  CARGO_STORAGE = 'CARGO_STORAGE',
  LOAD_TO_VEHICLE = 'LOAD_TO_VEHICLE',
  UNLOAD_TO_WAREHOUSE = 'UNLOAD_TO_WAREHOUSE',
}

export const eventTypes = [
  EventType.CARGO_STORAGE,
  EventType.VEHICLE_ARRIVAL,
  EventType.VEHICLE_WEIGHING,
  EventType.VEHICLE_WEIGHING_WITH_CARGO,
  EventType.LOAD_TO_VEHICLE,
  EventType.UNLOAD_TO_WAREHOUSE,
];

export interface ContractFieldFilter {
  name: string;
  fromValue: number;
  toValue: number;
  value: String;
}

export interface ContractField {
  id?: string;
  eventType: EventType;
  from?: number;
  to?: number;
  name: string;
  order?: number;
  unit: PriceUnit;
  unitCount?: number;
  value: string;
}

export interface Tariff {
  id?: string;
  name: string;
  fields: [ContractField];
}

export interface ConsigneeHeadTrustee {
  fullName: string;
  trusteeDocument: string;
}

export interface ConsigneeRepresentative {
  country: string;
  city: string;
  street: string;
  INN: string;
  OKPO: string;
  bankName: string;
  BIK: string;
  bankAccount: string;
  consigneeHeadFullName: string;
  consigneeHeadPosition: string;
  consigneeHeadTrustee?: ConsigneeHeadTrustee;
  consigneeType: ConsigneeType;
}

export interface Contract {
  additionalAgreements: [Contract];
  consignee: Consignee;
  consigneeRepresentative: ConsigneeRepresentative;
  contractEndReason: string;
  createdDate: number;
  departureCountry: string;
  deactivationReason?: string;
  differences: [string];
  endDate: number;
  id: string;
  note: string;
  parentContractId: string;
  startDate: number;
  tariffs: Tariff[];
  title: string;
  type: ContractType;
  // fields: [ContractField];
}

export interface ContractFieldFilter {
  name: string;
  fromValue: number;
  toValue: number;
  value: String;
}

interface ExistingConsigneeProfile {
  id: string;
  name: string;
}

interface ConsigneeProfileInput {
  name: string;
}

type ContractConsigneeProfileInput =
  | ConsigneeProfileInput
  | ExistingConsigneeProfile;

interface ConsigneeRepresentativeInput {
  country: string;
  city: string;
  street: string;
  INN?: string;
  OKPO?: string;
  bankName?: string;
  BIK?: string;
  bankAccount?: string;
  consigneeHeadFullName: string;
  consigneeHeadPosition?: string;
  consigneeHeadTrustee?: ConsigneeHeadTrustee;
  consigneeType: ConsigneeType;
}

export interface CreateContractInput {
  additionalAgreements?: Contract[];
  addressesAndDetails?: string;
  consigneeProfile?: ContractConsigneeProfileInput;
  consigneeRepresentative?: ConsigneeRepresentativeInput;
  contractEndReason?: string;
  departureCountry?: string;
  differences?: string[];
  endDate?: number;
  note?: string;
  parentContractId?: string;
  startDate: number;
  tariffs: Tariff[];
  title?: string;
  type: ContractType;
}

export interface TypicalContractInputFields {
  [key: string]: any;
}
