// External dependencies
import { filter, Observable, switchMap } from 'rxjs';

// Local dependenceis
import { getClient } from '../../../../clients/bts';
import { RootState } from '../../../../redux/store';
import {
    ListConsigneeGoodsSpecificationsActions,
    ListConsigneeGoodsSpecificationsActionTypes,
    listConsigneeGoodsSpecificationsFailed,
    listConsigneeGoodsSpecificationsSucceeded,
    ListConsigneeGoodsSpecificationsSuccess,
} from './actions';
import { listConsigneeGoodsSpecificationsQuery } from './query';

export default function listConsigneeGoodsSpecificationsEpic(
  actions$: Observable<ListConsigneeGoodsSpecificationsActions>,
  state$: { value: RootState },
) {
  return actions$.pipe(
    filter(
      (actions: ListConsigneeGoodsSpecificationsActions) =>
        actions.type ===
        ListConsigneeGoodsSpecificationsActionTypes.LIST_CONSIGNEE_GOODS_SPECIFICATIONS_REQUEST,
    ),
    switchMap(() => {
      const { currentPage, searchString, size } =
        state$.value.consigneeGoodsSpecifications;

      return listConsigneeGoodsSpecifications(
        currentPage,
        searchString,
        size,
      ).catch(listConsigneeGoodsSpecificationsFailed);
    }),
  );
}

export async function listConsigneeGoodsSpecifications(
  currentPage: number,
  searchString: string,
  size: number,
): Promise<ListConsigneeGoodsSpecificationsSuccess> {
  const graphQLClient = await getClient();

  let variables: any = {
    input: {
      from: (currentPage - 1) * size,
      size,
      query: searchString,
    },
  };

  const {
    data: {
      listConsigneeGoodsSpecifications: { total, consigneeGoodsSpecifications },
    },
  } = await graphQLClient.query({
    query: listConsigneeGoodsSpecificationsQuery,
    variables,
  });

  return listConsigneeGoodsSpecificationsSucceeded(
    consigneeGoodsSpecifications,
    total,
  );
}
