/* External dependencies */
import update from 'immutability-helper';
import parsePhoneNumber from 'libphonenumber-js';

/* Local dependencies */
import {
  PhoneNumberInvalidException,
  PhoneNumberNotKyrgyzstanException,
  PhoneNumberRequiredException,
} from '../../../redux/exceptions';
import { BORDER_CHECKPOINTS } from '../../countries/borderCheckpoints';
import { VehicleArrivalPurpose, WeightType } from '../vehicles_types';
import { UpdateVehicleField } from './actions';
import {
  ActFormNumberRequiredException,
  ArrivalPurposeRequiredException,
  BorderCheckpointRequiredException,
  DriverRequiredException,
  GrossWeightRequiredException,
  NetWeightRequiredException,
  RepresentativeRequiredException,
  TareWeightRequiredException,
  TrailerPlateNumberRequiredException,
  VehiclePlateNumberRequiredException,
  WeightTypeRequiredException,
} from './exceptions';

export function validateVehicle(state, updates: UpdateVehicleField) {
  const {
    actFormNumber,
    arrivalPurpose,
    borderCheckpoint,
    grossWeight,
    netWeight,
    tareWeight,
    trailerPlateNumber,
    vehiclePlateNumber,
    weightType,
  } = updates;
  const change: any = {
    isFormChanged: {
      $set: true,
    },
  };

  if (updates.hasOwnProperty('actFormNumber')) {
    if (!actFormNumber) {
      change.actFormNumberError = {
        $set: new ActFormNumberRequiredException(),
      };
    } else {
      change['$unset'] = ['actFormNumberError'];
    }

    return update(state, {
      ...change,
      vehicle: {
        $set: update(state.vehicle, {
          actFormNumber: { $set: actFormNumber },
        }),
      },
    });
  }

  if (updates.hasOwnProperty('arrivalPurpose')) {
    if (
      !arrivalPurpose ||
      !VehicleArrivalPurpose.hasOwnProperty(arrivalPurpose)
    ) {
      change.arrivalPurposeError = {
        $set: new ArrivalPurposeRequiredException(),
      };
    } else {
      change['$unset'] = ['arrivalPurposeError'];
    }

    return update(state, {
      ...change,
      vehicle: {
        $set: update(state.vehicle, {
          arrivalPurpose: { $set: arrivalPurpose },
        }),
      },
    });
  }

  if (updates.hasOwnProperty('borderCheckpoint')) {
    if (!borderCheckpoint || !BORDER_CHECKPOINTS.includes(borderCheckpoint)) {
      change.borderCheckpointError = {
        $set: new BorderCheckpointRequiredException(),
      };
    } else {
      change['$unset'] = ['borderCheckpointError'];
    }

    return update(state, {
      ...change,
      vehicle: {
        $set: update(state.vehicle, {
          borderCheckpoint: { $set: borderCheckpoint },
        }),
      },
    });
  }

  if (updates.hasOwnProperty('driverName')) {
    if (!updates['driverName']) {
      change.driverNameError = { $set: new DriverRequiredException() };
    } else {
      change['$unset'] = ['driverNameError'];
    }

    return update(state, {
      ...change,
      vehicle: {
        $set: update(state.vehicle, {
          driver: {
            $set: update(state.vehicle.driver, {
              name: { $set: updates['driverName'] },
            }),
          },
        }),
      },
    });
  }

  if (updates.hasOwnProperty('comment')) {
    return update(state, {
      ...change,
      vehicle: {
        $set: update(state.vehicle, {
          comment: { $set: updates['comment'] },
        }),
      },
    });
  }

  if (updates.hasOwnProperty('images')) {
    return update(state, {
      ...change,
      vehicle: {
        $set: update(state.vehicle, {
          images: { $set: updates['images'] },
        }),
      },
    });
  }

  if (updates.hasOwnProperty('driverPhone')) {
    try {
      const phoneNumber = parsePhoneNumber(updates['driverPhone'], 'KG');

      if (updates['driverPhone'].length < 1) {
        change.phoneError = { $set: new PhoneNumberRequiredException() };
      } else if (updates['driverPhone'].length !== 13) {
        change.driverPhoneError = { $set: new PhoneNumberInvalidException() };
      } else if (phoneNumber.country !== 'KG' && !phoneNumber.isValid()) {
        change.driverPhoneError = {
          $set: new PhoneNumberNotKyrgyzstanException(),
        };
      } else {
        change['$unset'] = ['driverPhoneError'];
      }
    } catch (err) {
      change.driverPhoneError = { $set: new PhoneNumberInvalidException() };
    }

    return update(state, {
      ...change,
      vehicle: {
        $set: update(state.vehicle, {
          driver: {
            $set: update(state.vehicle.driver, {
              phone: { $set: updates['driverPhone'] },
            }),
          },
        }),
      },
    });
  }

  if (updates.hasOwnProperty('driverRole')) {
    if (!updates['driverRole']) {
      change.driverRoleError = { $set: new DriverRequiredException() };
    } else {
      change['$unset'] = ['driverRoleError'];
    }

    return update(state, {
      ...change,
      vehicle: {
        $set: update(state.vehicle, {
          driver: {
            $set: update(state.vehicle.driver, {
              role: { $set: updates['driverRole'] },
            }),
          },
        }),
      },
    });
  }

  if (updates.hasOwnProperty('grossWeight')) {
    if (!grossWeight) {
      change.grossWeightError = { $set: new GrossWeightRequiredException() };
    } else {
      change['$unset'] = ['grossWeightError'];
    }

    return update(state, {
      ...change,

      vehicle: {
        $set: update(state.vehicle, {
          grossWeight: { $set: grossWeight },
          netWeight: { $set: grossWeight - state.vehicle.tareWeight },
        }),
      },
    });
  }

  if (updates.hasOwnProperty('netWeight')) {
    if (typeof netWeight !== 'number' || netWeight < 0) {
      change.netWeightError = { $set: new NetWeightRequiredException() };
    } else {
      change['$unset'] = ['netWeightError'];
    }

    return update(state, {
      ...change,
      vehicle: {
        $set: update(state.vehicle, {
          netWeight: { $set: netWeight },
        }),
      },
    });
  }

  if (updates.hasOwnProperty('representativeName')) {
    if (!updates['representativeName']) {
      change.representativeNameError = {
        $set: new RepresentativeRequiredException(),
      };
    } else {
      change['$unset'] = ['representativeNameError'];
    }

    return update(state, {
      ...change,
      vehicle: {
        $set: update(state.vehicle, {
          representative: {
            $set: update(state.vehicle.representative || {}, {
              name: { $set: updates['representativeName'] },
            }),
          },
        }),
      },
    });
  }

  if (updates.hasOwnProperty('representativePhone')) {
    try {
      const phoneNumber = parsePhoneNumber(
        updates['representativePhone'],
        'KG',
      );

      if (updates['representativePhone'].length < 1) {
        change.phoneError = { $set: new PhoneNumberRequiredException() };
      } else if (updates['representativePhone'].length !== 13) {
        change.representativePhoneError = {
          $set: new PhoneNumberInvalidException(),
        };
      } else if (phoneNumber.country !== 'KG' && !phoneNumber.isValid()) {
        change.representativePhoneError = {
          $set: new PhoneNumberNotKyrgyzstanException(),
        };
      } else {
        change['$unset'] = ['representativePhoneError'];
      }
    } catch (err) {
      change.representativePhoneError = {
        $set: new PhoneNumberInvalidException(),
      };
    }

    return update(state, {
      ...change,
      vehicle: {
        $set: update(state.vehicle, {
          representative: {
            $set: update(state.vehicle.representative || {}, {
              phone: { $set: updates['representativePhone'] },
            }),
          },
        }),
      },
    });
  }

  if (updates.hasOwnProperty('representativeRole')) {
    if (!updates['representativeRole']) {
      change.representativeRoleError = {
        $set: new RepresentativeRequiredException(),
      };
    } else {
      change['$unset'] = ['representativeRoleError'];
    }

    return update(state, {
      ...change,
      vehicle: {
        $set: update(state.vehicle, {
          representative: {
            $set: update(state.vehicle.representative, {
              role: { $set: updates['representativeRole'] },
            }),
          },
        }),
      },
    });
  }

  if (updates.hasOwnProperty('tareWeight')) {
    if (!tareWeight || tareWeight < 0) {
      change.tareWeightError = { $set: new TareWeightRequiredException() };
    } else {
      change['$unset'] = ['tareWeightError'];
    }

    return update(state, {
      ...change,
      vehicle: {
        $set: update(state.vehicle, {
          tareWeight: { $set: +tareWeight },
          netWeight: {
            $set: state.vehicle.grossWeight - (+tareWeight || 0),
          },
        }),
      },
    });
  }

  if (updates.hasOwnProperty('trailerPlateNumber')) {
    if (!trailerPlateNumber) {
      change.trailerPlateNumberError = {
        $set: new TrailerPlateNumberRequiredException(),
      };
    } else {
      change['$unset'] = ['trailerPlateNumberError'];
    }

    return update(state, {
      ...change,
      vehicle: {
        $set: update(state.vehicle, {
          trailerPlateNumber: { $set: trailerPlateNumber?.toUpperCase() },
        }),
      },
    });
  }

  if (updates.hasOwnProperty('vehiclePlateNumber')) {
    if (!vehiclePlateNumber) {
      change.vehiclePlateNumberError = {
        $set: new VehiclePlateNumberRequiredException(),
      };
    } else {
      change['$unset'] = ['vehiclePlateNumberError'];
    }

    return update(state, {
      ...change,
      vehicle: {
        $set: update(state.vehicle, {
          vehiclePlateNumber: { $set: vehiclePlateNumber?.toUpperCase() },
        }),
      },
    });
  }

  if (updates.hasOwnProperty('weightType')) {
    if (!weightType || !WeightType.hasOwnProperty(weightType)) {
      change.weightTypeError = { $set: new WeightTypeRequiredException() };
    } else {
      change['$unset'] = ['weightTypeError'];
    }

    return update(state, {
      ...change,
      vehicle: {
        $set: update(state.vehicle, {
          weightType: { $set: weightType },
        }),
      },
    });
  }

  return state;
}
