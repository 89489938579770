// Local dependencies
import { UIException } from '../../../../redux/exceptions';

export class StartDateException extends UIException {
  code = 'startDateException';
}

export class DeactivationReasonException extends UIException {
  code = 'deactivationReasonException';
}

export class EndDateException extends UIException {
  code = 'endDateException';
}

export class EndDateRequiredException extends UIException {
  code = 'endDateRequiredException';
}

export class CountryException extends UIException {
  code = 'countryException';
}

export class CityException extends UIException {
  code = 'cityException';
}

export class StreetException extends UIException {
  code = 'streetException';
}

export class ConsigneeHeadFullNameException extends UIException {
  code = 'consigneeHeadFullNameException';
}

export class ContractFieldNameException extends UIException {
  code = 'contractFieldNameException';
}

export class ContractFieldValueException extends UIException {
  code = 'сontractFieldValueException';
}
export class ContractFieldEventTypeException extends UIException {
  code = 'contractFieldEventTypeException';
}

export class ConsigneeProfileNameOrganizationException extends UIException {
  code = 'consigneeProfileNameOrganizationException';
}

export class ConsigneeProfileNameIndividualException extends UIException {
  code = 'consigneeProfileNameIndividualException';
}
