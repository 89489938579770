/* External dependencies */
import update from 'immutability-helper';

/* Local dependencies */
import { DEFAULT_PAGE_SIZE } from '../../common/helpers';
import { Consignee } from '../../create-cargo/redux/actions';
import { Vehicle } from '../../create-vehicle/redux/actions';
import { Warehouse } from '../../warehouses/types';
import {
  DEFAULT_CONSIGNEES_STATUS,
  ListConsigneesAction,
  ListConsigneesActionTypes,
  ListConsigneesFilter,
} from './actions';

export interface ConsigneesState {
  consignees: Array<Consignee>;
  currentPage: number;
  filter: ListConsigneesFilter;
  error?: Error;
  loading?: boolean;
  searchString: string;
  selectedItems: Array<any>;
  vehicleForExtraction?: Vehicle;
  warehouseForExtraction?: Warehouse;
  size: number;
  total: number;
  cargoMovingTo?: string;
}

export const initialConsigneesState: ConsigneesState = {
  consignees: [],
  currentPage: 1,
  filter: DEFAULT_CONSIGNEES_STATUS,
  loading: true,
  searchString: '',
  selectedItems: [],
  size: DEFAULT_PAGE_SIZE,
  total: 0,
};

export default function consigneesReducer(
  state = initialConsigneesState,
  action: ListConsigneesAction,
) {
  switch (action.type) {
    case ListConsigneesActionTypes.LIST_CONSIGNEES_REQUEST:
      const { currentPage, filter, searchString } = action;

      return update(state, {
        $unset: ['error'],
        loading: { $set: true },
        currentPage: { $set: currentPage },
        searchString: { $set: searchString },
        filter: { $set: filter },
      });

    case ListConsigneesActionTypes.LIST_CONSIGNEES_SUCCESS:
      return update(state, {
        loading: { $set: false },
        consignees: { $set: action.consignees },
        total: { $set: action.total },
      });

    case ListConsigneesActionTypes.LIST_CONSIGNEES_ERROR:
      return update(state, {
        error: { $set: action.error },
        loading: { $set: false },
      });

    case ListConsigneesActionTypes.SELECT_CONSIGNEE:
      return update(state, {
        selectedItems: { $set: [action.consignee] },
      });

    case ListConsigneesActionTypes.SELECT_VEHICLE_FOR_EXTRACTION:
      return update(state, {
        vehicleForExtraction: { $set: action.vehicleForExtraction },
      });
    case ListConsigneesActionTypes.SELECT_WAREHOUSE_FOR_EXTRACTION:
      return update(state, {
        warehouseForExtraction: { $set: action.warehouseForExtraction },
      });

    case ListConsigneesActionTypes.RESET_SELECTED_CONSIGNEES:
      return update(state, {
        selectedItems: { $set: initialConsigneesState.selectedItems },
      });

    case ListConsigneesActionTypes.SELECT_PLACE_FOR_MOVING_CARGO:
      return update(state, {
        cargoMovingTo: { $set: action.cargoMovingTo },
      });

    default:
      return state;
  }
}
