/* External dependencies */
import gql from 'graphql-tag';

export const listConsigneeProfilesQuery = gql`
  query listConsigneeProfiles($input: ListConsigneeProfilesInput!) {
    listConsigneeProfiles(input: $input) {
      total
      consigneeProfiles {
        id
        name
      }
    }
  }
`;
