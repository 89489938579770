// External dependencies
import { switchMap, filter } from 'rxjs';

// Local dependencies
import { getClient } from '../../../clients/bts';
import {
  ListDevicesActions,
  ListDevicesActionsTypes,
  listDevicesFailed,
  ListDevicesRequest,
  listDevicesSucceeded,
  ListDevicesSuccess,
} from './action';
import { listDevicesQuery } from './query';

export default function listDevicesEpic(actions$) {
  return actions$.pipe(
    filter(
      (action: ListDevicesActions) =>
        action.type === ListDevicesActionsTypes.LIST_DEVICES_REQUEST,
    ),
    switchMap((action: ListDevicesRequest) =>
      listDevices(action).catch(listDevicesFailed),
    ),
  );
}

async function listDevices(
  action: ListDevicesRequest,
): Promise<ListDevicesSuccess> {
  const { from, searchString, size } = action;
  const grahpQlClient = await getClient();

  const {
    data: {
      listDevices: { devices, total },
    },
  } = await grahpQlClient.query({
    query: listDevicesQuery,
    variables: {
      input: {
        from,
        query: searchString,
        size,
      },
    },
  });

  return listDevicesSucceeded(devices, total);
}
