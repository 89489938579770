// External dependencies
import { gql } from 'graphql-tag';

export const listCountriesQuery = gql`
  query listCountries($input: ListCountriesInput!) {
    listCountries(input: $input) {
      total
      countries {
        typename: __typename
        ... on Currency {
          id
        }
      }
    }
  }
`;
