/* External dependencies */
import gql from 'graphql-tag';

export const vehicleDetailQuery = gql`
  query getVehicleById($input: GetVehicleInput!) {
    getVehicleById(input: $input) {
      actFormNumber
      arrivalDate
      arrivalPurpose
      borderCheckpoint
      departureDate
      departureOperator {
        id
      }
      driver {
        name
        phone
      }
      grossWeight
      tareWeight
      id
      images {
        url
        type
      }
      netWeight
      operator {
        firstName
        id
        lastName
      }
      representative {
        name
        phone
        role
      }
      vehicleProfile {
        id
      }
      status
      comment
      trailerPlateNumber
      vehiclePlateNumber
      weightType
      paymentStatus
    }
  }
`;

export const getDeviceQuery = gql`
  query getDevice($input: GetDeviceInput!) {
    getDevice(input: $input) {
      data
      id
      type
      updatedDate
    }
  }
`;
