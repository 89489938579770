/* External dependencies */
import update from 'immutability-helper';

/* Local dependencies */
import {
  GetInvoiceActionTypes,
  PaymentDocumentType,
  PaymentType,
} from '../common/invoice-types';
import { InvoiceAction, InvoiceDetails, MakePaymentInput } from './actions';
import { getInvoiceFields, validateGetInvoice } from './validation';

export interface InvoiceState {
  applicationNumber: string;
  cancelPayment: boolean;
  documentType: string;
  documentIdError?: Error;
  documentTypeError?: Error;
  declarationMask: string;
  declarationNumber?: string;
  error?: Error;
  invoice?: InvoiceDetails;
  isSuccess?: boolean;
  isSuccessCancelPayment?: boolean;
  loading?: boolean;
  payment?: MakePaymentInput;
  paymentType?: PaymentType;
  paymentTypeError?: Error;
  shouldShowConfirmationPopup?: boolean;
  file: string;
}

export const initialInvoiceState: InvoiceState = {
  applicationNumber: '',
  cancelPayment: false,
  declarationMask: '________/______/_______',
  declarationNumber: '',
  documentType: PaymentDocumentType.DECLARATION,
  isSuccess: false,
  isSuccessCancelPayment: false,
  loading: false,
  shouldShowConfirmationPopup: false,
  paymentType: PaymentType.CASH,
  file: '',
};

export default function invoiceReducer(
  state = initialInvoiceState,
  action: InvoiceAction,
) {
  switch (action.type) {
    case GetInvoiceActionTypes.CANCEL_PAYMENT_POPUP:
      return update(state, {
        cancelPayment: { $set: action.value },
      });

    case GetInvoiceActionTypes.GET_INVOICE_REQUEST:
      return update(state, {
        $unset: ['error'],
        loading: { $set: true },
      });

    case GetInvoiceActionTypes.GET_INVOICE_SUCCESS:
      return getInvoiceFields(state, action.invoice, {
        loading: { $set: false },
      });

    case GetInvoiceActionTypes.GET_INVOICE_ERROR:
      return update(state, {
        error: { $set: action.error },
        loading: { $set: false },
      });

    case GetInvoiceActionTypes.HIDE_CONFIRMATION_POPUP:
      return update(state, {
        $unset: ['shouldShowConfirmationPopup'],
      });

    case GetInvoiceActionTypes.IS_SUCCESS_POPUP:
      return update(state, {
        isSuccess: { $set: false },
        isSuccessCancelPayment: { $set: false },
      });

    case GetInvoiceActionTypes.MAKE_PAYMENT_INVOICE_REQUEST:
      return update(state, {
        $unset: ['error'],
        loading: { $set: true },
      });

    case GetInvoiceActionTypes.MAKE_PAYMENT_INVOICE_SUCCESS:
      return getInvoiceFields(state, action.payment, {
        isSuccess: { $set: true },
        loading: { $set: false },
        shouldShowConfirmationPopup: { $set: false },
      });

    case GetInvoiceActionTypes.MAKE_PAYMENT_INVOICE_ERROR:
      return update(state, {
        error: { $set: action.error },
        loading: { $set: false },
        shouldShowConfirmationPopup: { $set: true },
      });

    case GetInvoiceActionTypes.MAKE_PAYMENT_RESET_ERROR:
      return update(state, {
        $unset: ['error'],
        shouldShowConfirmationPopup: { $set: false },
        cancelPayment: { $set: false },
      });

    case GetInvoiceActionTypes.MAKE_PAYMENT_RESET:
      return update(state, {
        $set: initialInvoiceState,
      });

    case GetInvoiceActionTypes.REVOKE_PAYMENT_REQUEST:
      return update(state, {
        $unset: ['error'],
        loading: { $set: true },
      });

    case GetInvoiceActionTypes.REVOKE_PAYMENT_SUCCESS:
      return getInvoiceFields(state, action.invoice, {
        loading: { $set: false },
        cancelPayment: { $set: false },
        isSuccessCancelPayment: { $set: true },
      });

    case GetInvoiceActionTypes.REVOKE_PAYMENT_ERROR:
      return update(state, {
        error: { $set: action.error },
        loading: { $set: false },
        shouldShowConfirmationPopup: { $set: true },
      });

    case GetInvoiceActionTypes.SHOW_CONFIRMATION_POPUP:
      return update(state, {
        shouldShowConfirmationPopup: { $set: action.value },
      });

    case GetInvoiceActionTypes.SET_PAYMENT_FIELDS:
      return update(state, {
        payment: { $set: action.payment },
      });

    case GetInvoiceActionTypes.UPDATE_INVOICE_FIELDS:
      return validateGetInvoice(state, action.updates);

    default:
      return state;
  }
}
