// External dependencies
import update from 'immutability-helper';

// Local dependencies
import { ListDevicesActions, ListDevicesActionsTypes } from './action';

export interface ListDevciesState {
  devices: any;
  error?: Error;
  isSuccess: boolean;
  loading: boolean;
  total: number;
}

export const listDevicesInitialState: ListDevciesState = {
  loading: false,
  isSuccess: false,
  devices: [],
  total: 0,
};

export default function listDevicesReducer(
  state = listDevicesInitialState,
  action: ListDevicesActions,
) {
  switch (action.type) {
    case ListDevicesActionsTypes.LIST_DEVICES_REQUEST: {
      return update(state, {
        loading: { $set: true },
      });
    }

    case ListDevicesActionsTypes.LIST_DEVICES_SUCCESS: {
      return update(state, {
        loading: { $set: false },
        isSuccess: { $set: true },
        devices: { $set: action.devices },
        total: { $set: action.total },
      });
    }

    case ListDevicesActionsTypes.LIST_DEVICES_FAILURE: {
      return {
        ...state,
        loading: false,
        isSuccess: false,
        error: action.error,
      };
    }

    default:
      return state;
  }
}
