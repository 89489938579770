// External dependencies
import ggl from 'graphql-tag';

export const createContractMutation = ggl`
    mutation createContract($input: CreateContractInput!) {
        createContract(input: $input) {
            id
            startDate
            type
            tariffs {
                id
                name
                fields {
                    id
                    name
                    value
                }
            }
        }
    }
`;
