// Local dependencies
import { WeightType } from '../create-vehicle/vehicles_types';
import { Device } from '../devices/types';

export enum onUpdateDeviceActionTypes {
  ON_UPDATE_DEVICE_SUBSCRIBE = 'ON_UPDATE_DEVICE_SUBSCRIBE',
  ON_UPDATE_DEVICE_SUBSCRIBE_SUCCESS = 'ON_UPDATE_DEVICE_SUBSCRIBE_SUCCESS',
  ON_UPDATE_DEVICE_SUBSCRIBE_FAILURE = 'ON_UPDATE_DEVICE_SUBSCRIBE_FAILURE',
  ON_UPDATE_DEVICE_UNSUBSCRIBE = 'ON_UPDATE_DEVICE_UNSUBSCRIBE',
  SAVE_SUBSCRIPTION = 'SAVE_SUBSCRIPTION',
  SCALING_STOPPED = 'SCALING_STOPPED',
}

export type onUpdateDeviceActions =
  | OnUpdateDeviceSubscribe
  | OnUpdateDeviceSubscribeSuccess
  | OnUpdateDeviceSubscribeFailure
  | OnUpdateDeviceUnsubscribe
  | SaveSubsciptionToDevice
  | ScalingStopped;

export interface OnUpdateDeviceSubscribe {
  type: onUpdateDeviceActionTypes.ON_UPDATE_DEVICE_SUBSCRIBE;
}

export interface SaveSubsciptionToDevice {
  type: onUpdateDeviceActionTypes.SAVE_SUBSCRIPTION;
  subscriptionToDevice: any;
}

export interface ScalingStopped {
  type: onUpdateDeviceActionTypes.SCALING_STOPPED;
}

export interface OnUpdateDeviceSubscribeSuccess {
  type: onUpdateDeviceActionTypes.ON_UPDATE_DEVICE_SUBSCRIBE_SUCCESS;
  device: Device;
  weightType: WeightType;
  createVehicle?: boolean;
}

export interface OnUpdateDeviceSubscribeFailure {
  type: onUpdateDeviceActionTypes.ON_UPDATE_DEVICE_SUBSCRIBE_FAILURE;
  error: Error;
}

export interface OnUpdateDeviceUnsubscribe {
  type: onUpdateDeviceActionTypes.ON_UPDATE_DEVICE_UNSUBSCRIBE;
}

export function onUpdateDeviceSubscribe(): OnUpdateDeviceSubscribe {
  return {
    type: onUpdateDeviceActionTypes.ON_UPDATE_DEVICE_SUBSCRIBE,
  };
}

export function onUpdateDeviceSubscribedSuccessfully(
  device: Device,
  weightType: WeightType,
  createVehicle?: boolean,
): OnUpdateDeviceSubscribeSuccess {
  return {
    type: onUpdateDeviceActionTypes.ON_UPDATE_DEVICE_SUBSCRIBE_SUCCESS,
    device,
    weightType,
    createVehicle,
  };
}

export function onUpdateDeviceSubscribeFailed(
  error: Error,
): OnUpdateDeviceSubscribeFailure {
  return {
    type: onUpdateDeviceActionTypes.ON_UPDATE_DEVICE_SUBSCRIBE_FAILURE,
    error,
  };
}

export function onUpdateDeviceUnsubscribe(): OnUpdateDeviceUnsubscribe {
  return {
    type: onUpdateDeviceActionTypes.ON_UPDATE_DEVICE_UNSUBSCRIBE,
  };
}

export function saveSubscriptionToDevice(
  subscriptionToDevice: any,
): SaveSubsciptionToDevice {
  return {
    type: onUpdateDeviceActionTypes.SAVE_SUBSCRIPTION,
    subscriptionToDevice,
  };
}

export function stopScaling(): ScalingStopped {
  return {
    type: onUpdateDeviceActionTypes.SCALING_STOPPED,
  };
}
