/* Loacl dependencies */
import { Consignee } from '../../create-cargo/redux/actions';
import { Vehicle } from '../../vehicle-details/redux/actions';

export enum CargoActionTypes {
  CARGO_REQUEST = 'CARGO_REQUEST',
  CARGO_SUCCESS = 'CARGO_SUCCESS',
  CARGO_FAILURE = 'CARGO_FAILURE',
  RESET_CARGO_DETAILS = 'RESET_CARGO_DETAILS',
}

export interface Cargo {
  cargoEscort: string;
  cargoNetWeight: number;
  cargoSlots: number;
  consignees: Consignee[];
  registrationDate?: number;
  vehicle: Vehicle;
}

export interface GetCargoRequest {
  type: CargoActionTypes.CARGO_REQUEST;
  id: string;
}

export interface GetCargoSuccess {
  type: CargoActionTypes.CARGO_SUCCESS;
  cargo: Cargo;
}

export interface CargoFailure {
  type: CargoActionTypes.CARGO_FAILURE;
  error: Error;
}

export interface ResetCargoDetails {
  type: CargoActionTypes.RESET_CARGO_DETAILS;
}

export type CargoAction =
  | GetCargoRequest
  | GetCargoSuccess
  | CargoFailure
  | ResetCargoDetails;

export function getCargo(id: string): GetCargoRequest {
  return {
    type: CargoActionTypes.CARGO_REQUEST,
    id,
  };
}

export function cargoSuccessed(cargo): GetCargoSuccess {
  return {
    type: CargoActionTypes.CARGO_SUCCESS,
    cargo,
  };
}

export function cargoFailed(error: Error): CargoFailure {
  return {
    type: CargoActionTypes.CARGO_FAILURE,
    error,
  };
}

export function resetCargoDetails(): ResetCargoDetails {
  return {
    type: CargoActionTypes.RESET_CARGO_DETAILS,
  };
}
