/* External dependencies */
import update from 'immutability-helper';

/* Local dependencies */
import { UnloadCargoAction, UnloadCargoActionTypes } from './actions';

export interface UnloadCargoState {
  error?: Error;
  isSuccess: boolean;
  loadingOfUnloadCargo?: boolean;
  unloadedConsignee: Array<any>;
  isCargoUnloadByClient: boolean;
  cargoProductsName: string;
}

export const initialUnloadCargoState: UnloadCargoState = {
  unloadedConsignee: [],
  isSuccess: false,
  isCargoUnloadByClient: false,
  cargoProductsName: '',
};

export default function cargoUnloadReducer(
  state = initialUnloadCargoState,
  action: UnloadCargoAction,
) {
  switch (action.type) {
    case UnloadCargoActionTypes.CARGO_UNLOADING_REQUEST:
      return update(state, {
        $unset: ['error'],
        loadingOfUnloadCargo: { $set: true },
      });

    case UnloadCargoActionTypes.CARGO_UNLOADING_SUCCESS:
      return update(state, {
        isSuccess: { $set: true },
        loadingOfUnloadCargo: { $set: false },
        unloadedConsignee: { $set: [action.unloadedConsignee] },
      });

    case UnloadCargoActionTypes.CARGO_UNLOADING_ERROR:
      return update(state, {
        error: { $set: action.error },
        loadingOfUnloadCargo: { $set: false },
      });

    case UnloadCargoActionTypes.CARGO_UNLOADING_BY_CLIENT:
      return update(state, {
        isCargoUnloadByClient: { $set: action.isCargoUnloadingByClient },
      });
    case UnloadCargoActionTypes.CARGO_PRODUCTS_NAME:
      return update(state, {
        cargoProductsName: { $set: action.cargoProductsName },
      });

    case UnloadCargoActionTypes.CARGO_UNLOADING_RESET_ERROR:
      return update(state, {
        $unset: ['error'],
      });

    case UnloadCargoActionTypes.CARGO_UNLOADING_RESET:
      return update(state, {
        $set: initialUnloadCargoState,
      });

    default:
      return state;
  }
}
