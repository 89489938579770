/* External dependencies */
import {
  applyMiddleware,
  combineReducers,
  createStore as createReduxStore,
} from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/logOnlyInProduction';
import { combineEpics, createEpicMiddleware } from 'redux-observable';

/* Local dependencies */
import cargoEpic from '../components/cargo-detail/redux/epics';
import cargo from '../components/cargo-detail/redux/reducer';
import consigneeEpic from '../components/consignee-details/redux/epics';
import consignee from '../components/consignee-details/redux/reducer';
import { listConsigneeProfilesEpic } from '../components/consignee-profiles/redux/epics';
import consigneeProfiles from '../components/consignee-profiles/redux/reducer';
import { consigneeContractEpic } from '../components/consignees/contract/redux/epics';
import consigneeContract from '../components/consignees/contract/redux/reducer';
import cargoUnloadEpic from '../components/consignees/redux/cargo-unloading/epics';
import cargoUnload from '../components/consignees/redux/cargo-unloading/reducer';
import listConsigneesEpic from '../components/consignees/redux/epics';
import consignees from '../components/consignees/redux/reducer';
import vehiclesForExtractionEpic from '../components/consignees/redux/vehicles-for-extraction/epics';
import vehiclesForExtraction from '../components/consignees/redux/vehicles-for-extraction/reducer';
import warehousesForUnloadingEpic from '../components/consignees/redux/warehouse-for-unload/epics';
import warehousesForUnloading from '../components/consignees/redux/warehouse-for-unload/reducer';
import createContractEpic from '../components/contracts/createContracts/redux/epic';
import creacteContract from '../components/contracts/createContracts/redux/reducer';
import {
  getContractEpic,
  updateContractEpic,
} from '../components/contracts/getContract/redux/epic';
import getContract from '../components/contracts/getContract/redux/reducer';
import listContractsEpic from '../components/contracts/listContracts/redux/epic';
import listContracts from '../components/contracts/listContracts/redux/reducer';
import { listCountriesEpic } from '../components/countries/redux/epic';
import countries from '../components/countries/redux/reducer';
import createCargoEpic from '../components/create-cargo/redux/epics';
import newCargo from '../components/create-cargo/redux/reducer';
import createUserEpic from '../components/create-user/redux/epics';
import newUser from '../components/create-user/redux/reducer';
import createVehicleEpic from '../components/create-vehicle/redux/epics';
import newVehicle from '../components/create-vehicle/redux/reducer';
import createWarehouseEpic from '../components/create-warehouse/redux/epics';
import createWarehouse from '../components/create-warehouse/redux/reducer';
import devicesEpic from '../components/devices/redux/epic';
import devices from '../components/devices/redux/reducer';
import { createConsigneeGoodsSpecificationEpic } from '../components/goods-specifications/createConsigneeGoodsSpecifications/redux/epic';
import consigneeGoodsSpecification from '../components/goods-specifications/createConsigneeGoodsSpecifications/redux/reducer';
import listConsigneeGoodsSpecificationsEpic from '../components/goods-specifications/listConsigneeGoodsSpecifications/redux/epic';
import consigneeGoodsSpecifications from '../components/goods-specifications/listConsigneeGoodsSpecifications/redux/reducer';
import {
  getInvoiceEpic,
  makePaymentEpic,
  revokePaymentEpic,
} from '../components/invoices/invoice/redux/epics';
import invoice from '../components/invoices/invoice/redux/reducer';
import listInvoicesEpic from '../components/invoices/redux/epics';
import invoices from '../components/invoices/redux/reducer';
import {
  changePasswordEpic,
  confirmPasswordEpic,
  forgotPasswordEpic,
  initClientEpic,
  loginEpic,
  logoutEpic,
} from '../components/login/redux/epics';
import login, { LoginState } from '../components/login/redux/reducer';
import connectOnUpdateDevice from '../components/on-update-device/epic';
import onUpdateDevice from '../components/on-update-device/reducer';
import {
  getUserEpic,
  updateUserEpic,
} from '../components/user-details/redux/epics';

import user from '../components/user-details/redux/reducer';
import listUsersEpic from '../components/users/epics';
import users from '../components/users/reducer';
import {
  updateVehicleEpic,
  vehicleEpic,
} from '../components/vehicle-details/redux/epics';
import vehicle from '../components/vehicle-details/redux/reducer';
import vehicleProfilesEpic, {
  changeVehicleTareWeight,
  resetVehicleTareWeight,
} from '../components/vehicle-profiles/redux/epic';
import vehicleProfiles from '../components/vehicle-profiles/redux/reducer';
import updateVehicleProfileEpic from '../components/vehicle-profiles/update-vehicle-profile/redux/epic';
import updateVehicleProfile from '../components/vehicle-profiles/update-vehicle-profile/redux/reducer';
import listVehiclesEpic from '../components/vehicles/redux/epics';
import vehicles from '../components/vehicles/redux/reducer';
import warehouseReducer from '../components/warehouse-details/redux/reducer';
import updateWarehouseEpic from '../components/warehouse-details/redux/epics';

export type ApplicationState = {
  login: LoginState;
};

const rootEpic = combineEpics(
  cargoEpic,
  cargoUnloadEpic,
  changePasswordEpic,
  changeVehicleTareWeight,
  connectOnUpdateDevice,
  confirmPasswordEpic,
  consigneeEpic,
  consigneeContractEpic,
  createCargoEpic,
  createUserEpic,
  createVehicleEpic,
  createConsigneeGoodsSpecificationEpic,
  createWarehouseEpic,
  devicesEpic,
  getInvoiceEpic,
  forgotPasswordEpic,
  initClientEpic,
  listConsigneesEpic,
  listConsigneeProfilesEpic,
  listInvoicesEpic,
  listUsersEpic,
  listConsigneeGoodsSpecificationsEpic,
  listVehiclesEpic,
  listCountriesEpic,
  loginEpic,
  logoutEpic,
  makePaymentEpic,
  getUserEpic,
  resetVehicleTareWeight,
  revokePaymentEpic,
  updateUserEpic,
  updateVehicleEpic,
  vehicleEpic,
  vehiclesForExtractionEpic,
  vehicleProfilesEpic,
  updateVehicleProfileEpic,
  updateWarehouseEpic,
  listContractsEpic,
  getContractEpic,
  createContractEpic,
  updateContractEpic,
  warehousesForUnloadingEpic,
);

const rootReducer = combineReducers({
  cargo,
  consigneeContract,
  cargoUnload,
  consignee,
  consignees,
  countries,
  consigneeProfiles,
  consigneeGoodsSpecification,
  createWarehouse,
  devices,
  invoices,
  invoice,
  login,
  newCargo,
  newUser,
  newVehicle,
  onUpdateDevice,
  user,
  users,
  vehicle,
  vehicles,
  vehiclesForExtraction,
  vehicleProfiles,
  updateVehicleProfile,
  listContracts,
  consigneeGoodsSpecifications,
  getContract,
  creacteContract,
  warehouseReducer,
  warehousesForUnloading,
});

let store;

export function createStore() {
  const epicMiddleware = createEpicMiddleware();
  store = createReduxStore(
    rootReducer,
    composeWithDevTools(applyMiddleware(epicMiddleware)),
  );

  epicMiddleware.run(rootEpic);

  return store;
}

export type RootState = ReturnType<typeof store.getState>;
