// Local dependencies
import {
  ListWarehousesFilter,
  Warehouse,
  WarehouseStatus,
} from '../../../warehouses/types';

export enum ListWarehousesForUnloadingActionTypes {
  LIST_WAREHOUSES_FOR_UNLOADING_REQUEST = 'LIST_WAREHOUSES_FOR_UNLOADING_REQUEST',
  LIST_WAREHOUSES_FOR_UNLOADING_SUCCESS = 'LIST_WAREHOUSES_FOR_UNLOADING_SUCCESS',
  LIST_WAREHOUSES_FOR_UNLOADING_ERROR = 'LIST_WAREHOUSES_FOR_UNLOADING_ERROR',
  RESET_LIST_WAREHOUSES = 'RESET_LIST_WAREHOUSES',
  RESET_ERROR_LIST_WAREHOUSES = 'RESET_ERROR_LIST_WAREHOUSES',
}

export const DEFAULT_WAREHOUSES_FILTER = {
  status: WarehouseStatus.ENABLED,
};

export interface ListWarehousesForUnloading {
  type: ListWarehousesForUnloadingActionTypes.LIST_WAREHOUSES_FOR_UNLOADING_REQUEST;
  currentPage: number;
  searchString: string;
  filter: ListWarehousesFilter;
}

export interface ListWarehousesForUnloadingSuccess {
  type: ListWarehousesForUnloadingActionTypes.LIST_WAREHOUSES_FOR_UNLOADING_SUCCESS;
  warehouses: Warehouse[];
  total: number;
}

export interface ListWarehousesForUnloadingError {
  type: ListWarehousesForUnloadingActionTypes.LIST_WAREHOUSES_FOR_UNLOADING_ERROR;
  error: Error;
}

export interface ResetListWarehouses {
  type: ListWarehousesForUnloadingActionTypes.RESET_LIST_WAREHOUSES;
}

export interface ResetErrorListWarehouses {
  type: ListWarehousesForUnloadingActionTypes.RESET_ERROR_LIST_WAREHOUSES;
}

export type ListWarehousesForUnloadingAction =
  | ListWarehousesForUnloading
  | ListWarehousesForUnloadingSuccess
  | ListWarehousesForUnloadingError
  | ResetListWarehouses
  | ResetErrorListWarehouses;

export function listWarehousesForUnloading(
  searchString: string = '',
  currentPage: number = 1,
  filter: ListWarehousesFilter = DEFAULT_WAREHOUSES_FILTER,
): ListWarehousesForUnloading {
  return {
    type: ListWarehousesForUnloadingActionTypes.LIST_WAREHOUSES_FOR_UNLOADING_REQUEST,
    searchString,
    currentPage,
    filter,
  };
}

export function listWarehousesForUnloadingSucceeded({
  warehouses,
  total,
}): ListWarehousesForUnloadingSuccess {
  return {
    type: ListWarehousesForUnloadingActionTypes.LIST_WAREHOUSES_FOR_UNLOADING_SUCCESS,
    warehouses,
    total,
  };
}

export function listWarehousesForUnloadingFailed(
  error: Error,
): ListWarehousesForUnloadingError {
  return {
    type: ListWarehousesForUnloadingActionTypes.LIST_WAREHOUSES_FOR_UNLOADING_ERROR,
    error,
  };
}

export function resetListWarehouses(): ResetListWarehouses {
  return {
    type: ListWarehousesForUnloadingActionTypes.RESET_LIST_WAREHOUSES,
  };
}

export function resetErrorListWarehouses(): ResetErrorListWarehouses {
  return {
    type: ListWarehousesForUnloadingActionTypes.RESET_ERROR_LIST_WAREHOUSES,
  };
}
