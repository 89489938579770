export enum ListUsersActionTypes {
  LIST_USERS_REQUEST = 'LIST_USERS_REQUEST',
  LIST_USERS_SUCCESS = 'LIST_USERS_SUCCESS',
  LIST_USERS_ERROR = 'LIST_USERS_ERROR',
}

export interface ListUsers {
  type: ListUsersActionTypes.LIST_USERS_REQUEST;
  currentPage?: number;
  searchString?: string;
}

export interface ListUsersSuccess {
  type: ListUsersActionTypes.LIST_USERS_SUCCESS;
  total: number;
  users: [];
}

export interface ListUsersError {
  type: ListUsersActionTypes.LIST_USERS_ERROR;
  error: Error;
}

export type ListUsersAction = ListUsers | ListUsersSuccess | ListUsersError;

export function listUsers(
  searchString: string = '',
  currentPage: number = 1,
): ListUsers {
  return {
    type: ListUsersActionTypes.LIST_USERS_REQUEST,
    searchString,
    currentPage,
  };
}

export function listUsersSucceeded(total: number, users): ListUsersSuccess {
  return {
    type: ListUsersActionTypes.LIST_USERS_SUCCESS,
    total,
    users,
  };
}

export function listUsersFailedAction(error: Error): ListUsersError {
  return {
    type: ListUsersActionTypes.LIST_USERS_ERROR,
    error,
  };
}
