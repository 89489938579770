/* External dependencies */
import gql from 'graphql-tag';

export const listWarehousesForUnloadingQuery = gql`
  query listWarehouses($input: ListWarehousesInput!) {
    listWarehouses(input: $input) {
      total
      warehouses {
        id
        name
        status
      }
    }
  }
`;
