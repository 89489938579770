/* External dependencies */
import { filter, switchMap } from 'rxjs/operators';

/* Local dependencies */
import { getClient } from '../../../../clients/bts';
import {
  ListWarehousesForUnloading,
  ListWarehousesForUnloadingAction,
  ListWarehousesForUnloadingActionTypes,
  listWarehousesForUnloadingFailed,
  listWarehousesForUnloadingSucceeded,
  ListWarehousesForUnloadingSuccess,
} from './actions';
import { listWarehousesForUnloadingQuery } from './queries';

export default function warehousesForUnloadingEpic(action$, state$) {
  return action$.pipe(
    filter(
      (action: ListWarehousesForUnloadingAction) =>
        action.type ===
        ListWarehousesForUnloadingActionTypes.LIST_WAREHOUSES_FOR_UNLOADING_REQUEST,
    ),
    switchMap((action: ListWarehousesForUnloading) =>
      listWarehousesForUnloading(
        action,
        state$?.value?.warehousesForUnloading?.size,
      )
        .then(listWarehousesForUnloadingSucceeded)
        .catch(listWarehousesForUnloadingFailed),
    ),
  );
}

export async function listWarehousesForUnloading(
  { currentPage, searchString, filter }: ListWarehousesForUnloading,
  size: number,
): Promise<ListWarehousesForUnloadingSuccess> {
  const graphQLClient = await getClient();

  const {
    data: { listWarehouses },
  } = await graphQLClient.query({
    query: listWarehousesForUnloadingQuery,
    variables: {
      input: {
        from: (currentPage - 1) * size,
        query: searchString,
        size,
        filter,
      },
    },
  });

  return listWarehouses;
}
