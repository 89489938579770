/* External dependencies */
import update from 'immutability-helper';

/* Local dependencies */
import { DEFAULT_PAGE_SIZE } from '../../common/helpers';
import {
  DEFAULT_VEHICLES_FILTER,
  DEFAULT_VEHICLES_SORT,
  ListVehiclesAction,
  ListVehiclesActionTypes,
  ListVehiclesFilter,
  ListVehiclesSort,
} from './actions';

export interface VehiclesState {
  currentPage: number;
  error?: Error;
  filter: ListVehiclesFilter;
  loading?: boolean;
  searchString: string;
  size: number;
  sort?: ListVehiclesSort;
  total: number;
  vehicles: Array<any>;
}

export const initialVehiclesState: VehiclesState = {
  currentPage: 1,
  filter: DEFAULT_VEHICLES_FILTER,
  loading: true,
  searchString: '',
  size: DEFAULT_PAGE_SIZE,
  sort: DEFAULT_VEHICLES_SORT,
  total: 0,
  vehicles: [],
};

export default function vehiclesReducer(
  state = initialVehiclesState,
  action: ListVehiclesAction,
) {
  switch (action.type) {
    case ListVehiclesActionTypes.LIST_VEHICLES_REQUEST:
      const { currentPage, searchString, filter, sort } = action;

      return update(state, {
        $unset: ['error'],
        currentPage: { $set: currentPage },
        filter: { $set: filter },
        loading: { $set: true },
        searchString: { $set: searchString },
        sort: { $set: sort },
      });

    case ListVehiclesActionTypes.LIST_VEHICLES_SUCCESS:
      return update(state, {
        loading: { $set: false },
        total: { $set: action.total },
        vehicles: { $set: action.vehicles },
      });

    case ListVehiclesActionTypes.LIST_VEHICLES_ERROR:
      return update(state, {
        error: { $set: action.error },
        loading: { $set: false },
      });

    default:
      return state;
  }
}
