/* External dependencies */
import gql from 'graphql-tag';

export const getContractQuery = gql`
  query getContract($input: GetContractInput!) {
    getContract(input: $input) {
      id
      type
      consigneeProfile {
        id
        name
      }
      endDate
      startDate
      organization {
        id
      }
      consigneeRepresentative {
        country
        city
        street
        INN
        OKPO
        bankName
        BIK
        bankAccount
        consigneeHeadFullName
        consigneeHeadPosition
        consigneeType
        consigneeHeadTrustee {
          fullName
          trusteeDocument
        }
      }
      tariffs {
        id
        name
        fields {
          id
          name
          value
          unit
          eventType
        }
      }
    }
  }
`;
