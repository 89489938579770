// Local dependencies
import { ContractType } from './createContracts/redux/types';

export function transformTariffs(contract, isCreation: boolean) {
  const newFields: any = [];

  contract?.tariffs?.map((tariff) => {
    return tariff?.fields?.map((field) => {
      return field?.values?.map((value) => {
        return isCreation
          ? newFields.push({
              data: {
                name: field.name,
                eventType: field.eventType,
                value: value.value,
                unit: value.unit,
              },
              name: tariff.name,
            })
          : newFields.push({
              data: {
                id: field.id,
                name: field.name,
                eventType: field.eventType,
                value: value.value,
                unit: value.unit,
              },
              name: tariff.name,
            });
      });
    });
  });

  const newTariffs = contract?.tariffs?.map((el) => {
    const arrrs = [];
    newFields.map((item) =>
      item.name === el.name ? arrrs.push(item.data) : null,
    );

    return { id: el.id, name: el.name, fields: arrrs };
  });

  return newTariffs;
}

export const createContractMenu = [
  {
    label: 'contractTemporaryStorage',
    link: '/contracts/create-contract/svh/',
    type: ContractType.TEMPORARY_STORAGE,
  },
  {
    label: 'contractCargoWareHouse',
    link: '/contracts/create-contract/customs-warehouse',
    type: ContractType.CUSTOMS_WAREHOUSE,
  },
  {
    label: 'contractCargoStorage',
    link: '/contracts/create-contract/customs-declaration',
    type: ContractType.CUSTOMS_DECLARATION,
  },
  {
    label: 'modelContract',
    link: '/contracts/create-contract/customs-declaration',
    type: ContractType.TYPICAL_CONTRACT,
  },
];
