// Local dependencies
import {
  VehicleProfile,
  VehicleProfileUpdate,
} from '../../../vehicles/vehicle-types';

export enum UpdateVehicleProfileActionTypes {
  UPDATE_VEHICLE_PROFILE_REQUEST = 'UPDATE_VEHICLE_PROFILE_REQUEST',
  UPDATE_VEHICLE_PROFILE_SUCCESS = 'UPDATE_VEHICLE_PROFILE_SUCCESS',
  UPDATE_VEHICLE_PROFILE_FAILURE = 'UPDATE_VEHICLE_PFOFILE_FAILURE',
  RESET_UPDATE_VEHICLE_PROFILE = 'RESET_UPDATE_VEHICLE_PROFILE',
}

export type UpdateVehicleProfileActions =
  | UpdateVehicleProfileRequest
  | UpdateVehicleProfileSuccess
  | UpdateVehicleProfileFailure
  | ResetUpdateVehicleProfile;

export interface UpdateVehicleProfileRequest {
  type: UpdateVehicleProfileActionTypes.UPDATE_VEHICLE_PROFILE_REQUEST;
  vehicleProfile: VehicleProfileUpdate;
}

export interface ResetUpdateVehicleProfile {
  type: UpdateVehicleProfileActionTypes.RESET_UPDATE_VEHICLE_PROFILE;
}

export interface UpdateVehicleProfileSuccess {
  type: UpdateVehicleProfileActionTypes.UPDATE_VEHICLE_PROFILE_SUCCESS;
  vehicleProfile: VehicleProfile;
}

export interface UpdateVehicleProfileFailure {
  type: UpdateVehicleProfileActionTypes.UPDATE_VEHICLE_PROFILE_FAILURE;
  error: Error;
}

export function updateVehicleProfileRequest(
  vehicleProfile: VehicleProfileUpdate,
): UpdateVehicleProfileRequest {
  return {
    type: UpdateVehicleProfileActionTypes.UPDATE_VEHICLE_PROFILE_REQUEST,
    vehicleProfile,
  };
}

export function updateVehicleProfileSucceeded(
  vehicleProfile: VehicleProfile,
): UpdateVehicleProfileSuccess {
  return {
    type: UpdateVehicleProfileActionTypes.UPDATE_VEHICLE_PROFILE_SUCCESS,
    vehicleProfile,
  };
}

export function updateVehicleProfileFailed(
  error: Error,
): UpdateVehicleProfileFailure {
  return {
    type: UpdateVehicleProfileActionTypes.UPDATE_VEHICLE_PROFILE_FAILURE,
    error,
  };
}

export function resetUpdateVehicleProfile() {
  return {
    type: UpdateVehicleProfileActionTypes.RESET_UPDATE_VEHICLE_PROFILE,
  };
}
