// External dependencies
import update from 'immutability-helper';

// Local dependencies
import { CreateContractTypes, CreateContractAction } from './actions';
import { Contract, ConsigneeType, CreateContractInput } from './types';
import { validateContractsfields } from './validation';

export interface CreateContractState {
  loading?: boolean;
  contract?: Contract | CreateContractInput;
  isFormChanged?: boolean;
  isSuccess?: boolean;
  isShowConfirmationPopup?: boolean;
  error?: Error;
  startDateError?: Error;
  endDateError?: Error;
  countryError?: Error;
  cityError?: Error;
  streetError?: Error;
  consigneeHeadFullNameError?: Error;
  fieldNameError?: Error;
  fieldValueError?: Error;
  eventTypeError?: Error;
  organizationError?: Error;
  individualError: Error;
}

export const initialCreateContractState: CreateContractState = {
  loading: false,
  isShowConfirmationPopup: false,
  contract: {
    consigneeProfile: null,
    consigneeRepresentative: {
      consigneeHeadFullName: '',
      consigneeHeadPosition: '',
      country: '',
      city: '',
      street: '',
      INN: '',
      OKPO: '',
      bankName: '',
      BIK: '',
      bankAccount: '',
      consigneeHeadTrustee: {
        fullName: '',
        trusteeDocument: '',
      },
      consigneeType: ConsigneeType.COMPANY,
    },
  },
};

export default function creacteContract(
  state = initialCreateContractState,
  action: CreateContractAction,
) {
  switch (action.type) {
    case CreateContractTypes.CREATE_CONTRACT_REQUEST:
      return update(state, {
        loading: { $set: true },
        isShowConfirmationPopup: { $set: false },
      });

    case CreateContractTypes.CREATE_CONTRACT_SUCCESS:
      return update(state, {
        loading: { $set: false },
        isSuccess: { $set: true },
      });

    case CreateContractTypes.CREATE_CONTRACT_ERROR:
      return update(state, {
        loading: { $set: false },
        isSuccess: { $set: false },
        error: { $set: action.error },
      });

    case CreateContractTypes.UPDATE_CREATE_CONTRACT_FIELDS:
      return validateContractsfields(state, action.updates);

    case CreateContractTypes.RESET_CREATE_CONTRACT:
      return initialCreateContractState;

    case CreateContractTypes.OPEN_SHOW_POPUP:
      return update(state, {
        isShowConfirmationPopup: { $set: action.value },
      });

    case CreateContractTypes.RESET_CREATE_CONTRACT_ERROR:
      return update(state, {
        $unset: ['error'],
      });

    default:
      return state;
  }
}
