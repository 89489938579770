/* External dependencies */
import gql from 'graphql-tag';

export const getUserQuery = gql`
  query getUser($input: GetUserInput!) {
    getUser(input: $input) {
      id
      firstName
      lastName
      phone
      role
      status
      organization {
        name
      }
    }
  }
`;
