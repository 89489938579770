/* External dependencies */
import gql from 'graphql-tag';

export const listInvoicesQuery = gql`
  query listInvoices($input: ListInvoicesInput) {
    listInvoices(input: $input) {
      total
      invoices {
        id
        consignee {
          id
          arrivalDate
          cargoWeight
          name
          vehiclePlateNumber
        }
        vehicle {
          id
          arrivalDate
          driver {
            name
          }
          vehiclePlateNumber
        }
        paymentStatus
      }
    }
  }
`;
