import {
  VehicleArrivalPurpose,
  VehicleStatus,
  WeightType,
} from '../vehicles_types';

export enum CreateVehicleActionTypes {
  CREATE_VEHICLE_ERROR = 'CREATE_VEHICLE_ERROR',
  CREATE_VEHICLE_REQUEST = 'CREATE_VEHICLE_REQUEST',
  CREATE_VEHICLE_RESET = 'CREATE_VEHICLE_RESET',
  CREATE_VEHICLE_RESET_ERROR = 'CREATE_VEHICLE_RESET_ERROR',
  CREATE_VEHICLE_SUCCESS = 'CREATE_VEHICLE_SUCCESS',
  HIDE_CONFIRMATION_POPUP = 'HIDE_CONFIRMATION_POPUP',
  SHOW_CONFIRMATION_POPUP = 'SHOW_CONFIRMATION_POPUP',
  UPDATE_VEHICLE_FIELDS = 'UPDATE_VEHICLE_FIELDS',
  TAKE_SNAPSHOT = 'TAKE_SNAPSHOT',
}

export type CameraSnapshotInput = {
  url: string;
  metadata?: string;
  type: string;
};

export interface CreateVehicleInput {
  actFormNumber?: string;
  arrivalPurpose?: VehicleArrivalPurpose;
  borderCheckpoint?: string;
  driver: ExternalUser;
  comment?: string;
  grossWeight: number;
  netWeight?: number;
  representative?: ExternalUser;
  status?: VehicleStatus;
  tareWeight?: number;
  trailerPlateNumber?: string;
  vehiclePlateNumber: string;
  weightType?: WeightType;
  images?: CameraSnapshotInput[];
}

export interface UpdateVehicleField {
  actFormNumber?: string;
  arrivalPurpose?: VehicleArrivalPurpose;
  borderCheckpoint?: string;
  comment?: string;
  driver?: ExternalUser;
  grossWeight?: number;
  images?: CameraSnapshotInput[];
  netWeight?: number;
  representative?: ExternalUser;
  status?: VehicleStatus;
  tareWeight?: number;
  trailerPlateNumber?: string;
  vehiclePlateNumber?: string;
  weightType?: WeightType;
}

type ExternalUser = {
  name: string;
  phone?: string;
  role?: string;
};

export interface Vehicle extends CreateVehicleInput {
  id?: string;
}

export interface CreateVehicleRequest {
  type: CreateVehicleActionTypes.CREATE_VEHICLE_REQUEST;
  vehicle: CreateVehicleInput;
}

export interface CreateVehicleSuccess {
  type: CreateVehicleActionTypes.CREATE_VEHICLE_SUCCESS;
  vehicle: Vehicle;
}

export interface CreateVehicleError {
  type: CreateVehicleActionTypes.CREATE_VEHICLE_ERROR;
  error: Error;
}

export interface CreateVehicleResetError {
  type: CreateVehicleActionTypes.CREATE_VEHICLE_RESET_ERROR;
}

export interface CreateVehicleReset {
  type: CreateVehicleActionTypes.CREATE_VEHICLE_RESET;
}

export interface UpdateVehicleFieldsRequest {
  type: CreateVehicleActionTypes.UPDATE_VEHICLE_FIELDS;
  updates: UpdateVehicleField;
}

export interface ShowConfirmationPopup {
  type: CreateVehicleActionTypes.SHOW_CONFIRMATION_POPUP;
}

export interface HideConfirmationPopup {
  type: CreateVehicleActionTypes.HIDE_CONFIRMATION_POPUP;
}

export interface TakeSnapshots {
  takeSnapshots: boolean;
  type: CreateVehicleActionTypes.TAKE_SNAPSHOT;
}

export type CreateVehicleAction =
  | CreateVehicleRequest
  | CreateVehicleReset
  | CreateVehicleSuccess
  | CreateVehicleError
  | CreateVehicleResetError
  | HideConfirmationPopup
  | ShowConfirmationPopup
  | UpdateVehicleFieldsRequest
  | TakeSnapshots;

export function createVehicleRequest(
  vehicle: CreateVehicleInput,
): CreateVehicleRequest {
  return {
    type: CreateVehicleActionTypes.CREATE_VEHICLE_REQUEST,
    vehicle,
  };
}

export function createVehicleSucceeded(vehicle: Vehicle): CreateVehicleSuccess {
  return {
    type: CreateVehicleActionTypes.CREATE_VEHICLE_SUCCESS,
    vehicle,
  };
}

export function createVehicleFailed(error: Error): CreateVehicleError {
  return {
    type: CreateVehicleActionTypes.CREATE_VEHICLE_ERROR,
    error,
  };
}

export function resetError(): CreateVehicleResetError {
  return {
    type: CreateVehicleActionTypes.CREATE_VEHICLE_RESET_ERROR,
  };
}

export function resetCreateVehicle(): CreateVehicleReset {
  return {
    type: CreateVehicleActionTypes.CREATE_VEHICLE_RESET,
  };
}

export function updateVehicleFields(
  updates: UpdateVehicleField,
): UpdateVehicleFieldsRequest {
  return {
    type: CreateVehicleActionTypes.UPDATE_VEHICLE_FIELDS,
    updates,
  };
}

export function showConfirmationPopup(): ShowConfirmationPopup {
  return {
    type: CreateVehicleActionTypes.SHOW_CONFIRMATION_POPUP,
  };
}

export function hideConfirmationPopup(): HideConfirmationPopup {
  return {
    type: CreateVehicleActionTypes.HIDE_CONFIRMATION_POPUP,
  };
}

export function handleTakeSnapshots(takeSnapshots: boolean): TakeSnapshots {
  return {
    takeSnapshots,
    type: CreateVehicleActionTypes.TAKE_SNAPSHOT,
  };
}
