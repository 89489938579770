/* Local Dependencies */
import { Vehicle } from '../../create-vehicle/redux/actions';
import { Warehouse } from '../../warehouses/types';

export enum CreateCargoActionTypes {
  CREATE_CARGO_REQUEST = 'CREATE_CARGO_REQUEST',
  CREATE_CARGO_RESET = 'CREATE_CARGO_RESET',
  CREATE_CARGO_SUCCESS = 'CREATE_CARGO_SUCCESS',
  CREATE_CARGO_ERROR = 'CREATE_CARGO_ERROR',
  CREATE_CARGO_RESET_ERROR = 'CREATE_CARGO_RESET_ERROR',
  UPDATE_CARGO_FIELDS = 'UPDATE_CARGO_FIELDS',
}

export enum CargoEscort {
  BANK_GUARANTEE = 'BANK_GUARANTEE',
  CUSTOMS_ESCORT = 'CUSTOMS_ESCORT',
  DEPOSIT_ACCOUNT = 'DEPOSIT_ACCOUNT',
  TIR = 'TIR',
}

export interface Organization {
  name: string;
}

export interface Consignee {
  arrivalDate: number;
  cargoWeight: number;
  departureCountry: string;
  id: string;
  organization: Organization;
  name: string;
  paymentStatus?: string;
  profileId?: string;
  registrationDate: number;
  slots: number;
  trailerPlateNumber: string;
  transit: boolean;
  vehiclePlateNumber: string;
  products?: string;
  warehouse?: Warehouse;
}

export interface CreateCargoInput {
  cargoEscort: CargoEscort;
  cargoNetWeight: number;
  cargoSlots: number;
  consignees: Consignee[];
  vehicle: CreateCargoVehicleInput;
}

export interface CreateCargoVehicleInput {
  arrivalDate: number;
  id: string;
  vehiclePlateNumber: string;
}

export interface UpdateCargoFields {
  cargoNetWeight?: any;
  cargoSlots?: any;
  cargoEscort?: CargoEscort;
  consignees?: Consignee[];
  departureCountry?: string;
  products?: string;
}

export interface Cargo extends CreateCargoInput {
  id?: string;
}

export interface CreateCargoRequest {
  type: CreateCargoActionTypes.CREATE_CARGO_REQUEST;
  cargo: CreateCargoInput;
}

export interface CreateCargoSuccess {
  type: CreateCargoActionTypes.CREATE_CARGO_SUCCESS;
  cargo: Cargo;
}

export interface CreateCargoError {
  type: CreateCargoActionTypes.CREATE_CARGO_ERROR;
  error: Error;
}

export interface CreateCargoResetError {
  type: CreateCargoActionTypes.CREATE_CARGO_RESET_ERROR;
}

export interface CreateCargoReset {
  type: CreateCargoActionTypes.CREATE_CARGO_RESET;
}

export interface UpdateCargoFieldsRequest {
  type: CreateCargoActionTypes.UPDATE_CARGO_FIELDS;
  updates: UpdateCargoFields;
}

export type CreateCargoAction =
  | CreateCargoRequest
  | CreateCargoSuccess
  | CreateCargoReset
  | CreateCargoError
  | UpdateCargoFieldsRequest
  | CreateCargoResetError;

export function createCargoRequest(
  cargo: CreateCargoInput,
): CreateCargoRequest {
  return {
    type: CreateCargoActionTypes.CREATE_CARGO_REQUEST,
    cargo,
  };
}

export function createCargoSucceeded(cargo: Cargo): CreateCargoSuccess {
  return {
    type: CreateCargoActionTypes.CREATE_CARGO_SUCCESS,
    cargo,
  };
}

export function createCargoFailed(error: Error): CreateCargoError {
  return {
    type: CreateCargoActionTypes.CREATE_CARGO_ERROR,
    error,
  };
}

export function resetError(): CreateCargoResetError {
  return {
    type: CreateCargoActionTypes.CREATE_CARGO_RESET_ERROR,
  };
}

export function resetCreateCargo(): CreateCargoReset {
  return {
    type: CreateCargoActionTypes.CREATE_CARGO_RESET,
  };
}

export function updateCargoFields(
  updates: UpdateCargoFields,
): UpdateCargoFieldsRequest {
  return {
    type: CreateCargoActionTypes.UPDATE_CARGO_FIELDS,
    updates,
  };
}
