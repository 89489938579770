/* External dependencies */
import gql from 'graphql-tag';

export const makePaymentMutation = gql`
  mutation makePayment($input: MakePaymentInput!) {
    makePayment(input: $input) {
      applicationNumber
      consignee {
        id
        arrivalDate
        cargoWeight
        name
      }
      declarationNumber
      documentType
      file
      id
      events {
        consignee {
          id
        }
        eventDate
        eventType
        sum
        unit
        vehicle {
          id
        }
      }
      paymentCurrency
      paymentDate
      paymentStatus
      paymentAmount
      paymentType
      vehicle {
        id
        arrivalDate
        driver {
          name
        }
      }
    }
  }
`;

export const revokePaymentMutation = gql`
  mutation revokePayment($input: RevokePaymentInput!) {
    revokePayment(input: $input) {
      applicationNumber
      consignee {
        id
        arrivalDate
        cargoWeight
        name
      }
      declarationNumber
      documentType
      file
      id
      events {
        consignee {
          id
        }
        eventDate
        eventType
        sum
        unit
        vehicle {
          id
        }
      }
      paymentCurrency
      paymentDate
      paymentStatus
      paymentAmount
      paymentType
      vehicle {
        id
        arrivalDate
        driver {
          name
        }
      }
    }
  }
`;
