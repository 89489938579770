/* External dependencies */
import { from, of } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';

/* Local dependencies */
import { getClient } from '../../../../clients/bts';
import {
  VehicleArrivalPurpose,
  VehicleStatus,
} from '../../../create-vehicle/vehicles_types';
import {
  ListVehiclesForExtraction,
  ListVehiclesForExtractionAction,
  ListVehiclesForExtractionActionTypes,
  listVehiclesForExtractionFailed,
  listVehiclesForExtractionSucceeded,
  ListVehiclesForExtractionSuccess,
} from './actions';
import { listVehiclesForExtractionQuery } from './queries';

export default function vehiclesForExtractionEpic(action$, state$) {
  return action$.pipe(
    filter(
      (action: ListVehiclesForExtractionAction) =>
        action.type ===
        ListVehiclesForExtractionActionTypes.LIST_VEHICLES_FOR_EXTRACTION_REQUEST,
    ),
    switchMap(() =>
      listVehiclesForExtraction().catch((error) =>
        listVehiclesForExtractionFailed(error),
      ),
    ),
  );
}

export async function listVehiclesForExtraction(): Promise<ListVehiclesForExtractionSuccess> {
  const graphQLClient = await getClient();

  const {
    data: {
      listVehicles: { vehicles },
    },
  } = await graphQLClient.query({
    query: listVehiclesForExtractionQuery,
    variables: {
      filter: {
        arrivalPurpose: VehicleArrivalPurpose.CARGO_EXTRACTION,
        status: VehicleStatus.ARRIVED,
      },
      from: 0,
      query: '',
      size: 1000,
      sort: {
        field: 'arrivalDate',
        direction: 'DESC',
      },
    },
  });

  return listVehiclesForExtractionSucceeded(vehicles);
}
