// External dependencies
import gql from 'graphql-tag';

export const listDevicesQuery = gql`
  query listDevices($input: ListDevicesInput!) {
    listDevices(input: $input) {
      devices {
        id
        data
        type
        updatedDate
      }
      total
    }
  }
`;
