// Local dependencies
import {
  ConsigneeGoodSpecifications,
  ConsigneeGoodsSpecificationsFields,
} from '../../types';
import {
  CreateConsigneeGoodsSpecificationAction,
  CreateConsigneeGoodsSpecificationActionTypes,
} from './actions';

interface ConsigneeGoodsSpecState {
  consigneeGoodsSpecification:
    | ConsigneeGoodSpecifications
    | ConsigneeGoodsSpecificationsFields
    | null;
  loading: boolean;
  error: Error | null;
  isSuccess: boolean;
}

const consigneeGoodsSpecificationInitialState: ConsigneeGoodsSpecState = {
  consigneeGoodsSpecification: null,
  loading: false,
  error: null,
  isSuccess: false,
};

const consigneeGoodsSpecificationReducer = (
  state: ConsigneeGoodsSpecState = consigneeGoodsSpecificationInitialState,
  action: CreateConsigneeGoodsSpecificationAction,
): ConsigneeGoodsSpecState => {
  switch (action.type) {
    case CreateConsigneeGoodsSpecificationActionTypes.CREATE_CONSIGNEE_GOODS_SPEC_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case CreateConsigneeGoodsSpecificationActionTypes.CREATE_CONSIGNEE_GOODS_SPEC_SUCCESS:
      return {
        ...state,
        consigneeGoodsSpecification: action.specification,
        loading: false,
        error: null,
        isSuccess: true,
      };

    case CreateConsigneeGoodsSpecificationActionTypes.CREATE_CONSIGNEE_GOODS_SPEC_FIELDS:
      return {
        ...state,
        consigneeGoodsSpecification: {
          ...state.consigneeGoodsSpecification,
          ...action.field,
        },
      };

    case CreateConsigneeGoodsSpecificationActionTypes.CREATE_CONSIGNEE_GOODS_SPEC_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case CreateConsigneeGoodsSpecificationActionTypes.CREATE_CONSIGNEE_GOODS_SPEC_RESET:
      return {
        ...consigneeGoodsSpecificationInitialState,
        error: null,
      };

    default:
      return state;
  }
};

export default consigneeGoodsSpecificationReducer;
