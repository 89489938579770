/* External dependencies */
import update from 'immutability-helper';

/* Local dependencies */
import { DEFAULT_PAGE_SIZE } from '../common/helpers';
import { ListUsersAction, ListUsersActionTypes } from './actions';

export interface UsersState {
  currentPage: number;
  error?: Error;
  loading?: boolean;
  searchString: string;
  size: number;
  total: number;
  users: [];
}

export const initialUsersState: UsersState = {
  currentPage: 1,
  searchString: '',
  size: DEFAULT_PAGE_SIZE,
  total: 0,
  users: [],
};

export default function usersReducer(
  state = initialUsersState,
  action: ListUsersAction,
) {
  switch (action.type) {
    case ListUsersActionTypes.LIST_USERS_REQUEST:
      const { currentPage, searchString } = action;

      return update(state, {
        $unset: ['error'],
        loading: { $set: true },
        currentPage: { $set: currentPage },
        searchString: { $set: searchString },
      });

    case ListUsersActionTypes.LIST_USERS_SUCCESS:
      return update(state, {
        loading: { $set: false },
        total: { $set: action.total },
        users: { $set: action.users },
      });
    case ListUsersActionTypes.LIST_USERS_ERROR:
      return update(state, {
        error: { $set: action.error },
        loading: { $set: false },
      });

    default:
      return state;
  }
}
