/* Local dependencies */
import { Consignee } from '../../create-cargo/redux/actions';

export enum ConsigneeActionTypes {
  GET_CONSIGNEE_REQUEST = 'GET_CONSIGNEE_REQUEST',
  GET_CONSIGNEE_SUCCESS = 'GET_CONSIGNEE_SUCCESS',
  GET_CONSIGNEE_ERROR = 'GET_CONSIGNEE_ERROR',
  GET_CONSIGNEE_RESET_ERROR = 'GET_CONSIGNEE_RESET_ERROR',
  RESET_CONSIGNEE_DETAILS = 'RESET_CONSIGNEE_DETAILS',
}

export interface ResetConsigneeDetails {
  type: ConsigneeActionTypes.RESET_CONSIGNEE_DETAILS;
}

export interface GetConsigneeRequest {
  type: ConsigneeActionTypes.GET_CONSIGNEE_REQUEST;
  id: string;
}

export interface GetConsigneeSuccess {
  type: ConsigneeActionTypes.GET_CONSIGNEE_SUCCESS;
  consignee: Consignee;
}

export interface GetConsigneeError {
  type: ConsigneeActionTypes.GET_CONSIGNEE_ERROR;
  error: Error;
}

export interface GetConsigneeResetError {
  type: ConsigneeActionTypes.GET_CONSIGNEE_RESET_ERROR;
}

export type ConsigneeAction =
  | GetConsigneeRequest
  | GetConsigneeSuccess
  | GetConsigneeError
  | GetConsigneeResetError
  | ResetConsigneeDetails;

export function resetConsigneeDetails(): ResetConsigneeDetails {
  return {
    type: ConsigneeActionTypes.RESET_CONSIGNEE_DETAILS,
  };
}

export function getConsignee(id: string): GetConsigneeRequest {
  return {
    type: ConsigneeActionTypes.GET_CONSIGNEE_REQUEST,
    id,
  };
}

export function getConsigneeSucceeded(
  consignee: Consignee,
): GetConsigneeSuccess {
  return {
    type: ConsigneeActionTypes.GET_CONSIGNEE_SUCCESS,
    consignee,
  };
}

export function getConsigneeFailed(error: Error): GetConsigneeError {
  return {
    type: ConsigneeActionTypes.GET_CONSIGNEE_ERROR,
    error,
  };
}

export function resetError(): GetConsigneeResetError {
  return {
    type: ConsigneeActionTypes.GET_CONSIGNEE_RESET_ERROR,
  };
}
