export const BORDER_CHECKPOINTS = [
  'Torugart',
  'Irkeshtam',
  'Ak-Tilek',
  'Ak-Jol',
  'Altyn-Kol',
  'Chaldovar',
  'Dostuk',
  'Ken-Bulun',
  'Kyzyl-Bel',
  'Madaniyat',
  'Bor Dobo',
  'Kulundu',
  'Chon-Kapka',
  'Karkyra',
  'Osh airport',
  'Manas airport',
];
