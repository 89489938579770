/* External dependencies */
import update from 'immutability-helper';

/* Local dependencies */
import { Consignee } from '../../create-cargo/redux/actions';
import { ConsigneeAction, ConsigneeActionTypes } from './actions';

export interface ConsigneeState {
  error?: Error;
  loading?: boolean;
  consignee?: Consignee;
}

export const initialConsigneeState: ConsigneeState = {
  loading: true,
};

export default function consigneeReducer(
  state = initialConsigneeState,
  action: ConsigneeAction,
) {
  switch (action.type) {
    case ConsigneeActionTypes.RESET_CONSIGNEE_DETAILS:
      return update(state, {
        $set: initialConsigneeState,
      });

    case ConsigneeActionTypes.GET_CONSIGNEE_REQUEST:
      return update(state, {
        $unset: ['error'],
        loading: { $set: true },
      });

    case ConsigneeActionTypes.GET_CONSIGNEE_SUCCESS:
      return update(state, {
        loading: { $set: false },
        consignee: { $set: action.consignee },
      });

    case ConsigneeActionTypes.GET_CONSIGNEE_ERROR:
      return update(state, {
        error: { $set: action.error },
        loading: { $set: false },
      });

    case ConsigneeActionTypes.GET_CONSIGNEE_RESET_ERROR:
      return update(state, {
        $unset: ['error'],
      });

    default:
      return state;
  }
}
