// Local dependencies
import { DEFAULT_PAGE_SIZE } from '../../common/helpers';
import { WorldUnion } from '../types';

export enum ListCountriesActionTypes {
  LIST_COUNTRIES_REQUEST = 'LIST_COUNTRIES_REQUEST',
  LIST_COUNTRIES_SUCCESS = 'LIST_COUNTRIES_SUCCESS',
  LIST_COUNTRIES_ERROR = 'LIST_COUNTRIES_ERROR',
}

export enum WorldType {
  CURRENCY = 'CURRENCY',
  COUNTRY = 'COUNTRY',
}

export type ListCountriesActions =
  | ListCountriesRequest
  | ListCountriesSuccess
  | ListCountriesFailure;

export interface ListCountriesFilter {
  typename?: WorldType;
}

export interface ListCountriesRequest {
  type: ListCountriesActionTypes.LIST_COUNTRIES_REQUEST;
  currentPage?: number;
  filter: ListCountriesFilter;
  size?: number;
  searchString?: string;
}

export interface ListCountriesSuccess {
  type: ListCountriesActionTypes.LIST_COUNTRIES_SUCCESS;
  countries: WorldUnion[];
  total: number;
}

export interface ListCountriesFailure {
  type: ListCountriesActionTypes.LIST_COUNTRIES_ERROR;
  error: Error;
}

export function listCountriesRequest(
  filter: ListCountriesFilter = {},
  searchString: string = '',
  currentPage: number = 1,
  size: number = DEFAULT_PAGE_SIZE,
): ListCountriesRequest {
  return {
    type: ListCountriesActionTypes.LIST_COUNTRIES_REQUEST,
    filter,
    searchString,
    currentPage,
    size,
  };
}

export function listCountriesSucceeded(
  countries: WorldUnion[],
  total: number,
): ListCountriesSuccess {
  return {
    total,
    type: ListCountriesActionTypes.LIST_COUNTRIES_SUCCESS,
    countries,
  };
}

export function listCountriesFailed(error: Error): ListCountriesFailure {
  return {
    type: ListCountriesActionTypes.LIST_COUNTRIES_ERROR,
    error,
  };
}
