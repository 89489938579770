import gql from 'graphql-tag';

export const adminCreateUserMutation = gql`
  mutation adminCreateUser(
    $firstName: String!
    $lastName: String!
    $phone: AWSPhone!
    $role: String!
  ) {
    adminCreateUser(
      input: {
        firstName: $firstName
        lastName: $lastName
        phone: $phone
        role: $role
      }
    ) {
      id
      firstName
      lastName
      phone
      role
    }
  }
`;
