// External dependencies
import update from 'immutability-helper';

// Local dependencies
import { DeviceType } from '../devices/types';
import { onUpdateDeviceActions, onUpdateDeviceActionTypes } from './action';

export enum SubscriptionStatus {
  FAILED = 'FAILED',
  SUBSCRIBED = 'SUBSCRIBED',
  UNSUBSCRIBED = 'UNSUBSCRIBED',
}

export interface onUpdateDeviceState {
  device: {
    data: string;
    id: string;
    status: string;
    type: DeviceType;
  };
  updatedDate: SubscriptionStatus;
  subscription: any;
  scalingStopped: boolean;
}

export const onUpdateDeviceInitialState = {
  status: SubscriptionStatus.UNSUBSCRIBED,
  device: {},
  subscription: undefined,
  scalingStopped: false,
};

export default function onUpdateDeviceReducer(
  state = onUpdateDeviceInitialState,
  action: onUpdateDeviceActions,
) {
  switch (action.type) {
    case onUpdateDeviceActionTypes.ON_UPDATE_DEVICE_SUBSCRIBE_SUCCESS: {
      return update(state, {
        device: { $set: action.device },
        status: { $set: SubscriptionStatus.SUBSCRIBED },
      });
    }

    case onUpdateDeviceActionTypes.ON_UPDATE_DEVICE_SUBSCRIBE_FAILURE: {
      return update(state, {
        status: { $set: SubscriptionStatus.FAILED },
      });
    }

    case onUpdateDeviceActionTypes.ON_UPDATE_DEVICE_UNSUBSCRIBE: {
      return update(state, {
        status: { $set: SubscriptionStatus.UNSUBSCRIBED },
        device: { $set: {} },
      });
    }

    case onUpdateDeviceActionTypes.SAVE_SUBSCRIPTION: {
      return update(state, {
        subscription: { $set: action.subscriptionToDevice },
      });
    }

    case onUpdateDeviceActionTypes.SCALING_STOPPED: {
      return update(state, {
        scalingStopped: { $set: true },
      });
    }

    default:
      return state;
  }
}
