/* Local dependencies */
import { SessionUser } from '../../login/redux/actions';
import { getUserRole } from '../../common/helpers';
import { UserRoles } from '../../common/roles';
import { getAdminEditableFields, getAdminFields } from './getAdminFields';
import {
  getManagerOperatorEditableFields,
  getManagerOperatorFields,
} from './getManagerOperatorFields';
import {
  getOperatorEditableFields,
  getOperatorFields,
} from './getOperatorFields';
import {
  getWeighmanEditableFields,
  getWeighmanFields,
} from './getWeighmanFields';

export function getUserFields(currentUser: SessionUser) {
  const userRole = getUserRole(currentUser);

  switch (userRole) {
    case UserRoles.BTS_ADMIN:
      return getAdminFields();
    case UserRoles.BTS_MANAGER_OPERATOR:
      return getManagerOperatorFields();
    // Weighman should have the same view as the operator.
    case UserRoles.BTS_OPERATOR:
      return getOperatorFields();
    case UserRoles.BTS_OPERATOR_WEIGHMAN:
      return getOperatorFields();
    case UserRoles.BTS_WEIGHMAN:
      return getWeighmanFields();
    default:
      throw new Error(`Unrecognized Cognito user group role '${userRole}'.`);
  }
}

export function getUserEditableFields(currentUser: SessionUser) {
  const userRole = getUserRole(currentUser);

  switch (userRole) {
    case UserRoles.BTS_ADMIN:
      return [
        ...getAdminEditableFields(),
        ...getManagerOperatorEditableFields(),
        ...getOperatorEditableFields(),
        ...getWeighmanEditableFields(),
      ];
    case UserRoles.BTS_MANAGER_OPERATOR:
      return getManagerOperatorEditableFields();
    case UserRoles.BTS_OPERATOR:
      return getOperatorEditableFields();
    case UserRoles.BTS_OPERATOR_WEIGHMAN:
      return getOperatorEditableFields();
    case UserRoles.BTS_WEIGHMAN:
      return getWeighmanEditableFields();
    default:
      throw new Error(`Unrecognized Cognito user group role '${userRole}'.`);
  }
}

export function isFieldRequired(field: string) {
  return [
    'arrivalDate',
    'vehiclePlateNumber',
    'arrivalPurpose',
    'weightType',
    'grossWeight',
    'driverName',
  ].includes(field);
}
