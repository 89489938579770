export enum WarehouseStatus {
  DISABLED = 'DISABLED',
  ENABLED = 'ENABLED',
}

export interface Warehouse {
  id: string;
  name: string;
  status: WarehouseStatus;
}

export interface ListWarehousesFilter {
  status: WarehouseStatus;
}
