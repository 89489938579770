/* Local dependencies */
import { ConsigneeProfile } from './reducer';

export enum ListConsigneeProfilesActionTypes {
  LIST_CONSIGNEE_PROFILES_REQUEST = 'LIST_CONSIGNEE_PROFILES_REQUEST',
  LIST_CONSIGNEE_PROFILES_SUCCESS = 'LIST_CONSIGNEE_PROFILES_SUCCESS',
  LIST_CONSIGNEE_PROFILES_ERROR = 'LIST_CONSIGNEE_PROFILES_ERROR',
}

export interface ListConsigneeProfiles {
  type: ListConsigneeProfilesActionTypes.LIST_CONSIGNEE_PROFILES_REQUEST;
  currentPage?: number;
  searchString?: string;
}

export interface ListConsigneeProfilesSuccess {
  type: ListConsigneeProfilesActionTypes.LIST_CONSIGNEE_PROFILES_SUCCESS;
  total: number;
  consigneeProfiles: ConsigneeProfile[];
}

export interface ListConsigneeProfilesError {
  type: ListConsigneeProfilesActionTypes.LIST_CONSIGNEE_PROFILES_ERROR;
  error: Error;
}

export type ListConsigneeProfilesAction =
  | ListConsigneeProfiles
  | ListConsigneeProfilesSuccess
  | ListConsigneeProfilesError;

export function listConsigneeProfiles(
  searchString: string = '',
  currentPage: number = 1,
): ListConsigneeProfiles {
  return {
    type: ListConsigneeProfilesActionTypes.LIST_CONSIGNEE_PROFILES_REQUEST,
    searchString,
    currentPage,
  };
}

export function listConsigneeProfilesSucceeded(
  total: number,
  consigneeProfiles: ConsigneeProfile[],
): ListConsigneeProfilesSuccess {
  return {
    type: ListConsigneeProfilesActionTypes.LIST_CONSIGNEE_PROFILES_SUCCESS,
    total,
    consigneeProfiles,
  };
}

export function listConsigneeProfilesFailed(
  error: Error,
): ListConsigneeProfilesError {
  return {
    type: ListConsigneeProfilesActionTypes.LIST_CONSIGNEE_PROFILES_ERROR,
    error,
  };
}
