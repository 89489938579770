// Local dependencies
import {
  ConsigneeGoodSpecifications,
  ConsigneeGoodsSpecificationsFields,
} from '../../types';

export enum CreateConsigneeGoodsSpecificationActionTypes {
  CREATE_CONSIGNEE_GOODS_SPEC_REQUEST = 'CREATE_CONSIGNEE_GOODS_SPEC_REQUEST',
  CREATE_CONSIGNEE_GOODS_SPEC_SUCCESS = 'CREATE_CONSIGNEE_GOODS_SPEC_SUCCESS',
  CREATE_CONSIGNEE_GOODS_SPEC_FIELDS = 'CREATE_CONSIGNEE_GOODS_SPEC_FIELDS',
  CREATE_CONSIGNEE_GOODS_SPEC_ERROR = 'CREATE_CONSIGNEE_GOODS_SPEC_ERROR',
  CREATE_CONSIGNEE_GOODS_SPEC_RESET = 'CREATE_CONSIGNEE_GOODS_SPEC_RESET',
}

export interface CreateConsigneeGoodsSpecRequest {
  type: CreateConsigneeGoodsSpecificationActionTypes.CREATE_CONSIGNEE_GOODS_SPEC_REQUEST;
}

export interface CreateConsigneeGoodsSpecSuccess {
  type: CreateConsigneeGoodsSpecificationActionTypes.CREATE_CONSIGNEE_GOODS_SPEC_SUCCESS;
  specification: ConsigneeGoodSpecifications;
}

export interface CreateConsigneeGoodsSpecField {
  type: CreateConsigneeGoodsSpecificationActionTypes.CREATE_CONSIGNEE_GOODS_SPEC_FIELDS;
  field: ConsigneeGoodsSpecificationsFields;
}

export interface CreateConsigneeGoodsSpecError {
  type: CreateConsigneeGoodsSpecificationActionTypes.CREATE_CONSIGNEE_GOODS_SPEC_ERROR;
  error: Error;
}

export interface CreateConsigneeGoodsSpecResetError {
  type: CreateConsigneeGoodsSpecificationActionTypes.CREATE_CONSIGNEE_GOODS_SPEC_RESET;
}

export type CreateConsigneeGoodsSpecificationAction =
  | CreateConsigneeGoodsSpecRequest
  | CreateConsigneeGoodsSpecSuccess
  | CreateConsigneeGoodsSpecField
  | CreateConsigneeGoodsSpecError
  | CreateConsigneeGoodsSpecResetError;

export function createConsigneeGoodsSpecRequest(): CreateConsigneeGoodsSpecRequest {
  return {
    type: CreateConsigneeGoodsSpecificationActionTypes.CREATE_CONSIGNEE_GOODS_SPEC_REQUEST,
  };
}

export function createConsigneeGoodsSpecSucceeded(
  specification: ConsigneeGoodSpecifications,
): CreateConsigneeGoodsSpecSuccess {
  return {
    type: CreateConsigneeGoodsSpecificationActionTypes.CREATE_CONSIGNEE_GOODS_SPEC_SUCCESS,
    specification,
  };
}

export function createConsigneeGoodsSpecFields(
  field: ConsigneeGoodsSpecificationsFields,
): CreateConsigneeGoodsSpecField {
  return {
    type: CreateConsigneeGoodsSpecificationActionTypes.CREATE_CONSIGNEE_GOODS_SPEC_FIELDS,
    field,
  };
}

export function createConsigneeGoodsSpecFailed(
  error: Error,
): CreateConsigneeGoodsSpecError {
  return {
    type: CreateConsigneeGoodsSpecificationActionTypes.CREATE_CONSIGNEE_GOODS_SPEC_ERROR,
    error,
  };
}

export function resetCreateConsigneeGoodsSpec(): CreateConsigneeGoodsSpecResetError {
  return {
    type: CreateConsigneeGoodsSpecificationActionTypes.CREATE_CONSIGNEE_GOODS_SPEC_RESET,
  };
}
