// External dependencies
import { map, filter } from 'rxjs';

// Local dependencies
import { updateVehicleFields } from '../create-vehicle/redux/actions';
import { WeightType } from '../create-vehicle/vehicles_types';
import { DeviceType } from '../devices/types';
import { updateVehicleAddInfo } from '../vehicle-details/redux/actions';
import {
  onUpdateDeviceActions,
  onUpdateDeviceActionTypes,
  OnUpdateDeviceSubscribe,
  OnUpdateDeviceSubscribeSuccess,
} from './action';

export default function connectOnUpdateDevice(actions$) {
  return actions$.pipe(
    filter(
      (action: onUpdateDeviceActions) =>
        action.type ===
        onUpdateDeviceActionTypes.ON_UPDATE_DEVICE_SUBSCRIBE_SUCCESS,
    ),
    map((action: OnUpdateDeviceSubscribeSuccess) => {
      switch (action.device.type) {
        case DeviceType.SCALE: {
          switch (action.weightType) {
            case WeightType.GROSS: {
              if (action.createVehicle) {
                return updateVehicleFields({
                  grossWeight: Number(action.device.data),
                });
              }

              return updateVehicleAddInfo({
                grossWeight: Number(action.device.data),
              });
            }

            case WeightType.TARE: {
              if (action.createVehicle) {
                return updateVehicleFields({
                  tareWeight: Number(action.device.data),
                });
              }

              return updateVehicleAddInfo({
                tareWeight: Number(action.device.data),
              });
            }
          }
        }
      }
    }),
  );
}
