// External dependencies
import gql from 'graphql-tag';

export const listVehilceProfilesQuery = gql`
  query listVehicleProfiles($input: ListVehicleProfilesInput!) {
    listVehicleProfiles(input: $input) {
      total
      vehicleProfiles {
        id
        tareWeight
        tareWeightDate
        trailerPlateNumber
        vehiclePlateNumber
        tareStatus
      }
    }
  }
`;
