/* External dependencies */
import { useIntl } from 'gatsby-plugin-react-intl';

export default function formatMessage(message: string, values?: any) {
  const intl = useIntl();

  return intl.formatMessage(
    {
      id: message,
    },
    values,
  );
}
