/* External dependencies */
import { filter, switchMap } from 'rxjs/operators';

/* Local dependencies */
import { getClient } from '../../../clients/bts';
import { Warehouse } from '../../warehouses/types';
import {
  CreateWarehouseAction,
  CreateWarehouseActionTypes,
  createWarehouseFailed,
  createWarehouseSucceeded,
} from './actions';
import { createWarehouseMutation } from './mutations';

export default function createWarehouseEpic(action$) {
  return action$.pipe(
    filter(
      (action: CreateWarehouseAction) =>
        action.type === CreateWarehouseActionTypes.CREATE_WAREHOUSE_REQUEST,
    ),
    switchMap((action: CreateWarehouseAction) =>
      createWarehouse(action)
        .then(createWarehouseSucceeded)
        .catch((error) => createWarehouseFailed(error)),
    ),
  );
}

export async function createWarehouse(action): Promise<Warehouse> {
  const graphQLClient = await getClient();

  const {
    data: { createWarehouse },
  } = await graphQLClient.mutate({
    mutation: createWarehouseMutation,
    variables: {
      input: {
        name: action.name,
      },
    },
  });

  return createWarehouse;
}
