/* External dependencies */
import update from 'immutability-helper';

/* Local dependencies */
import { Cargo, CargoAction, CargoActionTypes } from './actions';

export interface CargoState {
  error?: Error;
  loading?: boolean;
  cargo?: Cargo;
}

export const initialCargoState: CargoState = {
  loading: false,
};

export default function cargoReducer(
  state = initialCargoState,
  action: CargoAction,
) {
  switch (action.type) {
    case CargoActionTypes.CARGO_REQUEST:
      return update(state, {
        $unset: ['cargo', 'error'],
        loading: { $set: true },
      });

    case CargoActionTypes.CARGO_SUCCESS:
      return update(state, {
        loading: { $set: false },
        cargo: { $set: action.cargo },
      });

    case CargoActionTypes.CARGO_FAILURE:
      return update(state, {
        error: { $set: action.error },
        loading: { $set: false },
      });

    case CargoActionTypes.RESET_CARGO_DETAILS:
      return update(state, {
        $set: initialCargoState,
      });

    default:
      return state;
  }
}
