// External dependencies
import { gql } from 'graphql-tag';

export const listConsigneeGoodsSpecificationsQuery = gql`
  query listConsigneeGoodsSpecifications(
    $input: ListConsigneeGoodsSpecificationsInput!
  ) {
    listConsigneeGoodsSpecifications(input: $input) {
      total
      consigneeGoodsSpecifications {
        id
        sourceFile {
          url
          linesCount
        }
        exportFile {
          url
          linesCount
        }
        createdDate
        consigneeProfile {
          id
          name
        } 
      }
    }
  }
`;
