/* External dependencies */
import gql from 'graphql-tag';

export const makePaymentMutation = gql`
  mutation makePayment($input: MakePaymentInput!) {
    makePayment(input: $input) {
      arrivalDate
      cargoWeight
      documentId
      documentType
      fields {
        id
        name
        unit
        value
      }
      sum
      consignee {
        id
      }
      contract {
        id
      }
    }
  }
`;
